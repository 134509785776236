import React from 'react'
import { Tab, Nav } from "react-bootstrap";
import  './kpirecruiters.scss'


const KpiRecruiters = () => {
  return (
    <>
     <div className="dkg-kpi-recruiter-mainCon container-fluid">
        {/* <div className="row dkg-kpi-recruiter-mainRow">
            <div className="col-12 dkg-kpi-recruiter-mainCol">
            <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="contacts"
                >
                    <div className="row dkg-kpi-tabcon-Row mr-0 ml-0">
                    <div
                        className="col-md-2 dkg-kpi-tabcon-Col col-sm-12 dk-ClieDetViewSideMenu pl-0 pr-0"
                        id="dk-cliDetailsV"
                    >
                        <Nav variant="pills dkg-kpi-recruites-pills" className="flex-column">
                            <Nav.Item className='dkg-kpi-recruites-navitem'>
                                <Nav.Link eventKey="recruiter-summary" className='dkg-kpi-recruites-navlink'>Summary</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className='dkg-kpi-recruites-navitem'>
                                <Nav.Link eventKey="recruiter-targets" className='dkg-kpi-recruites-navlink'>Targets</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className='dkg-kpi-recruites-navitem'>
                                <Nav.Link eventKey="recruiter-jobs" className='dkg-kpi-recruites-navlink'>Jobs</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className='dkg-kpi-recruites-navitem'>
                                <Nav.Link eventKey="recruiter-candidates" className='dkg-kpi-recruites-navlink'>Candidates</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className='dkg-kpi-recruites-navitem'>
                                <Nav.Link eventKey="recruiter-results" className='dkg-kpi-recruites-navlink'>Results</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className='dkg-kpi-recruites-navitem'>
                                <Nav.Link eventKey="recruiter-bonus" className='dkg-kpi-recruites-navlink'>Bonus</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    <div className="col-md-10 col-sm-12 dk-ClieDetViewMainCont ttt">
                        <Tab.Content>  
                            <Tab.Pane eventKey="recruiter-summary">
                            </Tab.Pane>
                            <Tab.Pane eventKey="recruiter-targets">
                            </Tab.Pane>
                            <Tab.Pane eventKey="recruiter-jobs">
                            </Tab.Pane>
                            <Tab.Pane eventKey="recruiter-candidates">
                            </Tab.Pane>
                            <Tab.Pane eventKey="recruiter-results">
                            </Tab.Pane>
                            <Tab.Pane eventKey="recruiter-bonus">
                            </Tab.Pane>
                        </Tab.Content>
                    </div>
                    </div>
                </Tab.Container>
            </div>
        </div> */}
     </div>
    </>
  )
}

export default KpiRecruiters