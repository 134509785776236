import React, { useEffect, useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import "./login-faq.scss";
import FaqAccordion from "./Accordion";
import { useDispatch, useSelector } from "react-redux";

const LoginFaq = ({ onHide }) => {
  return (
    <>
      <Modal show={true} onHide={onHide} className="dkg-login-faq-modal-21">
        <Modal.Header closeButton className="dkg-login-faq-modalheader-21">
          <Modal.Title>USERS & LOGINS - FAQ’s</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dkg-login-faq-modalbody-21">
          <FaqAccordion />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LoginFaq;
