import React from 'react';
import Header from "../../elements/header";
import Navbar from "../../elements/header/navbar/mainMenu/itteammenu/DesignerTasksMainMenu";

const ItTeamDesignerTasksLayout = ({ children }) => {
    return (
        <React.Fragment>
            <Header dashboardheaderLink="Dashboard -" dashboardLink="/dashboard/plateform" mainheaderLink="IT Teams -" moduleLink="/dkglobaladmin/it-teams/module" linkcolor="#3c4c62" mainheaderTitle=" Designer Task" textcolor="#b55355" />
            <Navbar />
            {children}
        </React.Fragment>
    )
}
export default ItTeamDesignerTasksLayout;