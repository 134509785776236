import React from 'react';
import { Link } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Modal from 'react-bootstrap/Modal';
import { FaUserAlt, FaSpinner, FaRegCalendarAlt, FaHandshake, FaLocationArrow, FaCog } from 'react-icons/fa';

const SearchModal = ({ onHide }) => {
    return (
        <>
            <Modal className="dk-sendoutsSearch-modal dk-dkgStafSearchModal right" show={true} onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>SEARCH TABLE</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="dk-sendoutFilterTabs-main dk-dkgStaff-modalBody">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="tabs_1">
                            <div className="dk-sendoutTabs-nav">
                                <Nav variant="">
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_1"><FaSpinner className='mr-2' />  Status</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_2"><FaRegCalendarAlt className='mr-2' />Start Date</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_3"><FaRegCalendarAlt className='mr-2' />Duration</Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_4"><FaHandshake className='mr-2' /> Contract Type</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_5"><FaUserAlt className='mr-2' />Name of Client</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_6"><FaLocationArrow className='mr-2' />Location of Client</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_7"><FaCog className='mr-2' />Services Type</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                            <div className="dk-sendoutTabs-filterPanel">
                                <Tab.Content>
                                    <Tab.Pane eventKey="tabs_1">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-satusListFilter">
                                                <ul>
                                                    <li><Link to="#" style={{ background: '#3a9469', color: '#fff' }}>Active<span className='ml-1'>0</span></Link></li>
                                                    <li><Link to="#" style={{ background: 'red', color: '#fff' }}>Inactive <span className='ml-1'>0</span></Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_2">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-CategoryList">
                                                <ul>
                                                    <li><Link to="#">10 July 2022 <span>0</span></Link></li>
                                                    <li><Link to="#">12 July 2022 <span>0</span></Link></li>
                                                    <li><Link to="#">15 July 2022 <span>0</span></Link></li>
                                                    <li><Link to="#">10 August 2022 <span>0</span></Link></li>
                                                    <li><Link to="#">20 August 2022 <span>0</span></Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_3">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-CategoryList">
                                                <ul>
                                                    <li><Link to="#">10 July 2022 <span>0</span></Link></li>
                                                    <li><Link to="#">12 July 2022 <span>0</span></Link></li>
                                                    <li><Link to="#">15 July 2022 <span>0</span></Link></li>
                                                    <li><Link to="#">10 August 2022 <span>0</span></Link></li>
                                                    <li><Link to="#">20 August 2022 <span>0</span></Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_4">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-CategoryList">
                                                <ul>
                                                    <li><Link to="#">Contract Type 1 <span>30</span></Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_5">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-CategoryList">
                                                <ul>
                                                    <li><Link to="#">Rossella Casciello<span>30</span></Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_6">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-CategoryList">
                                                <ul>
                                                    <li><Link to="#">Ireland<span>0</span></Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_7">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-CategoryList">
                                                <ul>
                                                    <li><Link to="#">Recruitment<span>0</span></Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default SearchModal;
