import React, { useLayoutEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";

import RecruitementLayout from "../layout/RecruitementLayout";
import RecruitmenttipsLayout from '../layout/RecruitmenttipsLayout';
import ClienstInfoLayout from "../layout/ClienstInfoLayout";
import LoginsLayout from '../layout/LoginsLayout'
import VisainfoLayout from '../layout/VisainfoLayout'
import SalestipsLayout from '../layout/SalestipsLayout'
//import RecrulesLayout from '../layout/RecrulesLayout'
import MarketingLayout from "../layout/MarketingLayout";
import AdsLayout from "../layout/AdsLayout";
import AccountingLayout from '../layout/AccountingLayout';
import ResourceLayout from "../layout/ResourceLayout";
import ProjectionsLayout from "../layout/ProjectionLayout";
import HolidaysLayout from "../layout/HolidaysLayout";
import PaymentsLayout from "../layout/PaymentsLayout";
import TrainingLayout from "../layout/TrainingLayout";
import KnowleadgeLayout from '../layout/KnowleadgeLayout';
import DatabaseTableLayout from '../layout/DatabaseTableLayout'
import FullDatabaseLayout from '../layout/FullDatabaseLayout';
import MyfilesLayout from '../layout/MyfilesLayout';
import JobspecsLayout from '../layout/JobspecsLayout'
import ProjectsLayout from "../layout/ProjectsLayout";
import KPILayout from '../layout/KPILayout'
import DatabaseLayout from '../layout/DatabaseLayout'
import DKGlobalLayout from '../layout/DKGlobalLayout'
import DKGGlobalLayout from '../layout/dkglobaladmin/DKGlobalLayout'
import DKGCloudLayout from '../layout/DKGCloudLayout'
import ModuleLayout from '../layout/ModuleLayout';
import FinanceAccountingLayout from '../layout/dkglobalfinance/FinanceAccountingLayout';
import FinanceClientsLayout from '../layout/dkglobalfinance/FinanceClientsLayout';
import FinanceTextreturnLayout from '../layout/dkglobalfinance/FinanceTextreturnLayout';
import FinanceContractLayout from '../layout/dkglobalfinance/FinanceContractLayout';
import FinanceVatFilesLayout from '../layout/dkglobalfinance/FinanceVatFilesLayout';
import FinanceBankingLayout from '../layout/dkglobalfinance/FinanceBankingLayout';
import FinanceCashFlowLayout from '../layout/dkglobalfinance/FinanceCashFlowLayout';
import FinanceBusinessOverviewLayout from '../layout/dkglobalfinance/FinanceBusinessOverviewLayout';
import BusinessOverviewLayout from '../layout/businessoverview/BusinessOverviewLayout';
import DkGlobalCrmLayout from "../layout/itteams/DkGlobalCrmLayout";
import FinanceIncomeLayout from '../layout/dkglobalfinance/FinanceIncomeLayout';
import FinanceExpenseLayout from '../layout/dkglobalfinance/FinanceExpenseLayout';
import FinanceProfitLossLayout from '../layout/dkglobalfinance/FinanceProfitLossLayout';
import FinanceContractsLayout from '../layout/dkglobalfinance/FinanceContractsLayout';
import FinanceKnowledgeBaseLayout from '../layout/dkglobalfinance/FinanceKnowledgeBaseLayout';
import FinanceLegalLayout from '../layout/dkglobalfinance/FinanceLegalLayout';
import LegalDebtLayout from '../layout/dkglobalfinance/LegalDebtLayout';
import LegalDirectorLayout from '../layout/dkglobalfinance/LegalDirectorLayout';

import AdminResourceLayout from '../layout/dkglobaladmin/AdminResourceLayout';
import AdminPrLayout from '../layout/dkglobaladmin/AdminPrLayout';
import AdminMarketingLayout from '../layout/dkglobaladmin/AdminMarketingLayout';
import AdminClientsLayout from '../layout/dkglobaladmin/AdminClientsLayout';
import ClientsIncomeTaxLayout from '../layout/ClientsIncomeTaxLayout';
import AdminSupplierLayout from '../layout/dkglobaladmin/AdminSupplierLayout';
import AdminBuisnessDevelopLayout from '../layout/dkglobaladmin/AdminBuisnessDevelopLayout';
import NewleadsLayout from '../layout/newbusiness/NewleadsLayout';
// import AdminHrLayout from '../layout/dkglobaladmin/AdminHrLayout';
import OnboardingLayout from '../layout/dkglobaladmin/OnboardingLayout';
import AdminMyFilesLayout from '../layout/dkglobaladmin/AdminMyFilesLayout';
import AdminManagementLayout from '../layout/dkglobaladmin/AdminManagementLayout';
import AdminKnowledgeBaseLayout from '../layout/dkglobaladmin/AdminKnowledgeBaseLayout';
import AdminTrainingLayout from '../layout/dkglobaladmin/AdminTrainingLayout';
import RecruitmentTrainingLayout from '../layout/trainingmodule/RecruitmentTrainingLayout';
import CommonTrainingLayout from '../layout/trainingmodule/CommonTrainingLayout';
import StatusTrainingLayout from '../layout/trainingmodule/StatusTrainingLayout';
import ManagementTrainingLayout from '../layout/trainingmodule/ManagementTrainingLayout';
import OnboardingTrainingLayout from '../layout/trainingmodule/OnboardingTrainingLayout';
import RecruitmentAdminTrainingLayout from '../layout/trainingmodule/RecruitmentAdminTrainingLayout';
import AccountManagerTrainingLayout from '../layout/trainingmodule/AccountManagerTrainingLayout';
import InterviewsManagerTrainingLayout from '../layout/trainingmodule/InterviewsManagerTrainingLayout';
import RecruitmentConsultantsLayout from '../layout/trainingmodule/RecruitmentConsultantsLayout';
import ProjectManagerTrainingLayout from '../layout/trainingmodule/ProjectManagerTrainingLayout';
import RecruitmentDatabaseAdminLayout from '../layout/trainingmodule/RecruitmentDatabaseAdminLayout';
import RecruitmentAdsPostAdminLayout from '../layout/trainingmodule/RecruitmentAdsPostAdminLayout';
import TrainingFaqTrainingLayout from '../layout/trainingmodule/TrainingFaqTrainingLayout';
import FinancePaymentsLayout from '../layout/dkglobalfinance/FinancePaymentsLayout';
import TrainingCommonTabsLayout from '../layout/trainingmodule/TrainingCommonTabsLayout';
import MainFaqLayout from '../layout/MainFaqLayout';
import ManagementsLayout from '../layout/ManagementsLayout';
import BudgetLayout from '../layout/marketing/BudgetLayout';
import AdsTrackerLayout from '../layout/marketing/AdsTrackerLayout';
import BannerLayout from '../layout/marketing/BannerLayout';
import CampaignsLayout from '../layout/marketing/CampaignsLayout';
import JobboardsLayout from '../layout/marketing/JobboardsLayout';
import KnowleadgebaseLayout from '../layout/marketing/KnowleadgeLayout';
import AdsPlanLayout from '../layout/marketing/AdsPlanLayout';
import LiveAdsLayout from '../layout/marketing/LiveAdsLayout';
import SavedAdsLayout from '../layout/marketing/SavedAdsLayout';
import SimpleAdsLayout from '../layout/marketing/SimpleAdsLayout';
import LegalModuleLayout from '../layout/LegalModuleLayout';
import ResourceHrLayout from '../layout/resourcedepartments/ResourceHrLayout';
import ItTeamGuidelinesLayout from '../layout/itteams/ItTeamGuidelinesLayout';
import ItTeamDeveloperTaskLayout from '../layout/itteams/ItTeamDeveloperTaskLayout';
import ItTeamDesignerTasksLayout from '../layout/itteams/ItTeamDesignerTasksLayout';
import RecruitmentFaqLayout from '../layout/RecruitmentFaqLayout';
import HrLayout from '../layout/hr/HrLayout'

import { clearMessage } from "../../slice/utils/message/messageSlice";
import { logoutUser } from "../../slice/auth/authSlice";
import { userslist } from "../../slice/userList/userSlice";

const PrivateRoute = ({ component: Component, ...rest }) => {
    const dispatch = useDispatch();
    const { isAuthenticate } = useSelector(state => state.auth)
    const { myModule } = useSelector(state => state.module)
    // const companyId = process.env.REACT_APP_DEFAULT_COMPANY_ID;

    useLayoutEffect(() => {
        if (JSON.parse(localStorage.getItem("dkmasterBoxClient"))) {

        } else {
            dispatch(clearMessage())
            dispatch(logoutUser())
        }
        const fetchUserList = async () => {
            try {
                await dispatch(userslist({}))
            } catch (err) {
                console.log(err)
            }
        }

        fetchUserList()
    }, [])

    return (
        <Route {...rest} render={props => {
            if (isAuthenticate) {

                switch (myModule) {
                    case 'Dashboard':
                        return <ModuleLayout><Component {...props} /></ModuleLayout>

                    case 'Recruitment':
                        return <RecruitementLayout><Component {...props} /></RecruitementLayout>

                    case 'Recruitmenttips':
                        return <RecruitmenttipsLayout><Component {...props} /></RecruitmenttipsLayout>

                    case 'it-teams':
                        return <DkGlobalCrmLayout><Component {...props} /></DkGlobalCrmLayout>

                    case 'Clients Info':
                        return <ClienstInfoLayout><Component {...props} /></ClienstInfoLayout>

                    case 'Marketing':
                        return <MarketingLayout><Component {...props} /></MarketingLayout>

                    case 'ADS':
                        return <AdsLayout><Component {...props} /></AdsLayout>

                    case 'Resource':
                        return <ResourceLayout><Component {...props} /></ResourceLayout>

                    case 'Accounting':
                        return <AccountingLayout><Component {...props} /></AccountingLayout>

                    case 'Knowleadge':
                        return <KnowleadgeLayout><Component {...props} /></KnowleadgeLayout>

                    case 'DatabaseTable':
                        return <DatabaseTableLayout><Component {...props} /></DatabaseTableLayout>

                    case 'FullDatabase':
                        return <FullDatabaseLayout><Component {...props} /></FullDatabaseLayout>

                    case 'Projections':
                        return <ProjectionsLayout><Component {...props} /></ProjectionsLayout>

                    case 'Holidays':
                        return <HolidaysLayout><Component {...props} /></HolidaysLayout>

                    case 'Payments':
                        return <PaymentsLayout><Component {...props} /></PaymentsLayout>

                    case 'Training':
                        return <TrainingLayout><Component {...props} /></TrainingLayout>

                    case 'Myfiles':
                        return <MyfilesLayout><Component {...props} /></MyfilesLayout>

                    case "projects":
                        return <ProjectsLayout><Component {...props} /></ProjectsLayout>;

                    case 'Jobspecs':
                        return <JobspecsLayout><Component {...props} /></JobspecsLayout>

                    case 'KPI':
                        return <KPILayout><Component {...props} /></KPILayout>

                    case 'Database':
                        return <DatabaseLayout><Component {...props} /></DatabaseLayout>

                    case 'Logins':
                        return <LoginsLayout><Component {...props} /></LoginsLayout>

                    case 'Visainfo':
                        return <VisainfoLayout><Component {...props} /></VisainfoLayout>

                    case 'SalesTips':
                        return <SalestipsLayout><Component {...props} /></SalestipsLayout>

                    // case 'Recruitmentrules':
                    //     return <MyaccLayout><Component {...props} /></MyaccLayout>
                    // case 'dkglobal':
                    //     return <DKGlobalLayout><Component {...props} /></DKGlobalLayout>

                    // case 'dk-global':
                    //     return <DKGGlobalLayout><Component {...props} /></DKGGlobalLayout>

                    case 'dkg-global':
                        return <DKGCloudLayout><Component {...props} /></DKGCloudLayout>

                    case 'accounting':
                        return <FinanceAccountingLayout><Component {...props} /></FinanceAccountingLayout>

                    case 'clients-invoices':
                        return <FinanceClientsLayout><Component {...props} /></FinanceClientsLayout>

                    case 'tax-returns':
                        return <FinanceTextreturnLayout><Component {...props} /></FinanceTextreturnLayout>

                    case 'acc-contracts':
                        return <FinanceContractLayout><Component {...props} /></FinanceContractLayout>

                    case 'vatfiles':
                        return <FinanceVatFilesLayout><Component {...props} /></FinanceVatFilesLayout>

                    case 'banking':
                        return <FinanceBankingLayout><Component {...props} /></FinanceBankingLayout>

                    case 'cashflow':
                        return <FinanceCashFlowLayout><Component {...props} /></FinanceCashFlowLayout>

                    case 'businessoverview':
                        return <FinanceBusinessOverviewLayout><Component {...props} /></FinanceBusinessOverviewLayout>

                    case 'business-overview':
                        return <BusinessOverviewLayout><Component {...props} /></BusinessOverviewLayout>

                    case 'payments':
                        return <FinanceIncomeLayout><Component {...props} /></FinanceIncomeLayout>

                    case 'expense':
                        return <FinanceExpenseLayout><Component {...props} /></FinanceExpenseLayout>

                    case 'profitloss':
                        return <FinanceProfitLossLayout><Component {...props} /></FinanceProfitLossLayout>

                    case 'contracts':
                        return <FinanceContractsLayout><Component {...props} /></FinanceContractsLayout>

                    case 'knowledgebase':
                        return <FinanceKnowledgeBaseLayout><Component {...props} /></FinanceKnowledgeBaseLayout>

                    case 'legal':
                        return <FinanceLegalLayout><Component {...props} /></FinanceLegalLayout>

                    case 'resource':
                        return <AdminResourceLayout><Component {...props} /></AdminResourceLayout>

                    case 'pr':
                        return <AdminPrLayout><Component {...props} /></AdminPrLayout>

                    case 'marketing':
                        return <AdminMarketingLayout><Component {...props} /></AdminMarketingLayout>

                    case 'budget':
                        return <BudgetLayout><Component {...props} /></BudgetLayout>

                    case 'ads-tracker':
                        return <AdsTrackerLayout><Component {...props} /></AdsTrackerLayout>

                    case 'banners':
                        return <BannerLayout><Component {...props} /></BannerLayout>

                    case 'campagins':
                        return <CampaignsLayout><Component {...props} /></CampaignsLayout>

                    case 'job-boards':
                        return <JobboardsLayout><Component {...props} /></JobboardsLayout>

                    case 'knowleadge-base':
                        return <KnowleadgebaseLayout><Component {...props} /></KnowleadgebaseLayout>

                    case 'live-ads':
                        return <LiveAdsLayout><Component {...props} /></LiveAdsLayout>

                    case 'ads-plan':
                        return <AdsPlanLayout><Component {...props} /></AdsPlanLayout>

                    case 'saved-ads':
                        return <SavedAdsLayout><Component {...props} /></SavedAdsLayout>

                    case 'sample-ads':
                        return <SimpleAdsLayout><Component {...props} /></SimpleAdsLayout>

                    case 'clients':
                        return <AdminClientsLayout><Component {...props} /></AdminClientsLayout>

                    case 'income-tax':
                        return <ClientsIncomeTaxLayout><Component {...props} /></ClientsIncomeTaxLayout>

                    case 'supplier':
                        return <AdminSupplierLayout><Component {...props} /></AdminSupplierLayout>

                    case 'bd':
                        return <AdminBuisnessDevelopLayout><Component {...props} /></AdminBuisnessDevelopLayout>

                    case 'newleads':
                        return <NewleadsLayout><Component {...props} /></NewleadsLayout>

                    // case 'hr':
                    //     return <AdminHrLayout><Component {...props} /></AdminHrLayout>

                    case 'onboarding':
                        return <OnboardingLayout><Component {...props} /></OnboardingLayout>

                    case 'myfiles':
                        return <AdminMyFilesLayout><Component {...props} /></AdminMyFilesLayout>

                    case 'adminmanagement':
                        return <AdminManagementLayout><Component {...props} /></AdminManagementLayout>

                    case 'admin-knowledgebase':
                        return <AdminKnowledgeBaseLayout><Component {...props} /></AdminKnowledgeBaseLayout>

                    // case 'my-account':
                    //     return <MyaccLayout><Component {...props} /></MyaccLayout>

                    case 'admintraining':
                        return <AdminTrainingLayout><Component {...props} /></AdminTrainingLayout>

                    case 'onboardingadmintraining':
                        return <OnboardingTrainingLayout><Component {...props} /></OnboardingTrainingLayout>

                    case 'recruitment-manager':
                        return <RecruitmentTrainingLayout><Component {...props} /></RecruitmentTrainingLayout>

                    case 'admins':
                        return <RecruitmentAdminTrainingLayout><Component {...props} /></RecruitmentAdminTrainingLayout>

                    case 'account-manager':
                        return <AccountManagerTrainingLayout><Component {...props} /></AccountManagerTrainingLayout>

                    case 'managers':
                        return <InterviewsManagerTrainingLayout><Component {...props} /></InterviewsManagerTrainingLayout>

                    case 'recruitment-consultant':
                        return <RecruitmentConsultantsLayout><Component {...props} /></RecruitmentConsultantsLayout>

                    case 'project-manager':
                        return <ProjectManagerTrainingLayout><Component {...props} /></ProjectManagerTrainingLayout>

                    case 'database-admin':
                        return <RecruitmentDatabaseAdminLayout><Component {...props} /></RecruitmentDatabaseAdminLayout>

                    case 'ads-post-admin':
                        return <RecruitmentAdsPostAdminLayout><Component {...props} /></RecruitmentAdsPostAdminLayout>

                    case 'commonadmintraining':
                        return <CommonTrainingLayout><Component {...props} /></CommonTrainingLayout>

                    case 'statusadmintraining':
                        return <StatusTrainingLayout><Component {...props} /></StatusTrainingLayout>

                    case 'managementadmintraining':
                        return <ManagementTrainingLayout><Component {...props} /></ManagementTrainingLayout>

                    case 'manage-ment':
                        return <ManagementsLayout><Component {...props} /></ManagementsLayout>

                    case 'legalmodule':
                        return <LegalModuleLayout><Component {...props} /></LegalModuleLayout>

                    case 'training-faq':
                        return <TrainingFaqTrainingLayout><Component {...props} /></TrainingFaqTrainingLayout>

                    case 'main-faq':
                        return <MainFaqLayout><Component {...props} /></MainFaqLayout>

                    case 'finance-payments':
                        return <FinancePaymentsLayout><Component {...props} /></FinancePaymentsLayout>
                        
                    case 'resource-hr':
                        return <ResourceHrLayout><Component {...props} /></ResourceHrLayout>

                    case 'hr':
                        return <HrLayout><Component {...props} /></HrLayout>   

                    case 'guidelines':
                        return <ItTeamGuidelinesLayout><Component {...props} /></ItTeamGuidelinesLayout>

                    case 'developertask':
                        return <ItTeamDeveloperTaskLayout><Component {...props} /></ItTeamDeveloperTaskLayout>

                    case 'designertasks':
                        return <ItTeamDesignerTasksLayout><Component {...props} /></ItTeamDesignerTasksLayout>

                    case 'recruitment-faq':
                        return <RecruitmentFaqLayout><Component {...props} /></RecruitmentFaqLayout>

                    case 'common-training':
                        return <TrainingCommonTabsLayout><Component {...props} /></TrainingCommonTabsLayout>

                    case 'debt':
                        return <LegalDebtLayout><Component {...props} /></LegalDebtLayout>

                    case 'legal-director':
                        return <LegalDirectorLayout><Component {...props} /></LegalDirectorLayout>


                }
            } else {
                // return <Component {...props} />
                return <Redirect
                    to={{
                        pathname: "/"
                        , state: { from: props.location }

                    }}
                />
            }
        }} />
    )
};
export default PrivateRoute;