import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import "./candidateprofie.scss";
import { Modal, Tab, Nav } from "react-bootstrap";
import SelectRecruiterDropDwn from "./RecruiterDropDwn";
import SelectAccountManagerDropDwn from "./AccountManagerDropDwn";
import SelectRecruiterAdminDropDwn from "./RecruiterAdminDropDwn";
import CandTabContentSummary from "./summary/Summary";
import Dropdown from "react-bootstrap/Dropdown";
import EmailLogs from './emaillog/EmailLogs'
import VerifyEmail from './verify-email/VerifyEmail'
import CandTabContentOverview from "./overview/Overview";
import CandExperience from "./experience/Experience";
import CandQualification from "./qualification/Qualification";
import CandOtherInfo from "./otherinfo/OtherInfo";
import Documents from "./documents/Documents";
import MatchingJobs from "./matchingjobs/TableLiveJobs";
//import CaseStatus from "./casestatus/CaseStatus";
import ProfileSkillsModal from './skills/ProfileSkills'
import TechnicalSkillsModal from './skills/TechnicalSkills'
import FirstScreening from './screening/FirstScreening'
import SecondScreening from './screening/SecondScreening'
import CandidateMailbox from './emailbox/MyaccMailbox'
import { GetData, PatchData } from "../../../../../../services";
import { getDefaultMail } from '../../../../../helpers/storage-helper';
import ComposeInternalMail from "./emailbox/modal/ComposeMail";
// import ComposeEmail from './mailbox/CzmposeEmail'
//import AddSkilsModal from './summary/AddSkils';
//import AddTechSkilsModal from './summary/AddTechSkils';
//import SkypeImg from "../../../../../../assets/images/skype-icon.png";
//import TextSearch from "./textsearch/TextSearch";
// import MatchingJobs from "./matchingjobs/MatchingJobs";
//import Communication from './communication/Communication'
//import TechSkills from "./techskills/TechSkills";
//import ProfileSkills from "./profileskills/ProfileSkills";
//import { FaRocketchat } from "react-icons/fa";
//import CandTabContentWorkflow from "./workflow/Workflow";
//import FormControl from "react-bootstrap/FormControl";
//import DeleteModal from "../../../../../ui/delete";
//import DatePicker from "./datepicker";
// import PhoneIcon from "../../../../../../assets/images/phone-icon.png";
// import EmailIcon from "../../../../../../assets/images/email-icon.png";
// import SmsIcon from "../../../../../../assets/images/sms-icon.png";
//import ChatIcon from "../../../../../../assets/images/chats_icon.png";
//import WhatsIcon from "../../../../../../assets/images/whatsapp-icon.png";
//import UserIcon from "../../../../../../assets/images/userrec.png";
// import CheckIcon from "../../../../../../assets/images/check.png";
// import QuestionIcon from "../../../../../../assets/images/question-mark.png";
// import CloseIcon from "../../../../../../assets/images/cross.png";
import {
  candidateProfileDetails,
  candidateProfileRecruiterDetails,
  candidateProfileJobDetails,
  updateTable,
  updateCandidateProfile,
  clearState,
  updateProfileDetails,
  yearCount,
  monthsCount,
  getEmailLogs,
  getEmailVerificationLogsForEmail1,
  getEmailVerificationLogsForEmail2,
  mailBoxEmailCategory,
  getApplications
} from "../../../../../../slice/candidates/list/listSlice";
import { fetchById } from "../../../../../../slice/candidatePrecreeningForm/candidatePrecreeningSlice";
import {
  candidateAllTechSkillsTag,
  candidateAllJobProfileSkillsTag
} from "../../../../../../slice/candidates/candidatesSlice";


import {
  companyNumbersCounts,
  languageNumbersCounts,
  titleNumbersCounts,
} from "../../../../../..//slice/jobs/summary/summarySlice";
import BubbleChat from '../../../../../../assets/images/bubble-chat.png';

import { useDispatch, useSelector } from "react-redux";
import CommentModal from "../../../../../utils/comment/profileComment";
import CandidateDocumentModal from "../../modal/documents/profileDocument";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import AddIndustry from "./summary/AddIndustry";
import moment from "moment-timezone";
import "./candidateprofie.scss";
import SocialChatsModal from "./SocialChatsModal";
import { FaCheck, FaReplyAll } from "react-icons/fa";
import ComposeMail from "../../../../mainmenu/mailbox/ComposeMail";
import {
  indicatorColor,
  flagColor,
  profileStatus,
  profileMotivationStatus,
} from "../../../../../../helper/commonFunctions";
import leftBoxUserGirl from "../../../../../../assets/images/girl-defult.png";
import leftBoxUserMen from "../../../../../../assets/images/men-default.jpg";
import leftBoxUserDefault from "../../../../../../assets/images/user-defult.jpg";
import CustomDateFormat from "../../../../../ui/dateFormats/CustomDateFormat";
import { FaRegClock, FaSignature } from 'react-icons/fa';
import $ from "jquery";
import EventLog from "./event/EventLog";
import { getInternalMail, getInternalMailCount, internalMailBoxEmailCategory } from "../../../../../../slice/mailbox/mailboxSlice";
import Appication from "./appications/Appication";
import Referrals from "./referral/Referrals";
import DesiredTab from "./desired-job/DesiredTab";
import MyServices from "./my-services/MyServices";
export const useMountEffect = (fun) => useEffect(fun, []);

const useStyles = makeStyles((theme) => ({
  backdrop: {
    color: "#fff",
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const CandidateProfile = ({ canid, list, isProfileModal, handleCloseProfile, jobidd }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { companyCount, languageCount, titleCount } = useSelector(
    (state) => state.jobsSummray
  );

  const { userInfo } = useSelector(state => state.auth);
  const { internalMailsCount } = useSelector(state => state.mailbox);

  const {
    isProfileLoading,
    isProfileRecLoading,
    isProfileJobLoading,
    isProfileLanguageLoading,
    profileDetails,
    profileRecDetails,
    profileJobDetails,
    //profileLangaugeDetails,
    //tagsListTechSkills,
    iscommentUpdate,
    defaultYearList,
    defaultMonthList,
    isUpdate,
    isProfileUpdate,
    isLoading,
    isProfileProjectUpdate,
    isApplication,
  } = useSelector((state) => state.candidates);

  // const { isCommentLoading,  isCommentDelete } = useSelector(
  //   (state) => state.comments
  // );
  const { isCommentDelete } = useSelector(
    (state) => state.comments
  );

  const [filterTags, setFilterTags] = useState([]);
  const [tagLength, setTagLength] = useState(0);

  const [filter, setFilter] = useState({});
  const [recruiterFilter, setRecruiterFilter] = useState({});
  const [companyfilter, setCompanyfilter] = useState({});
  const [updatedFilter, setUpdatedFilter] = useState({});
  const [companyFilterText, setCompanyFilterText] = useState("");
  const [languageFilterText, setLanguageFilterText] = useState("");
  // const [iscategoryFilter, setIsCategoryFilter] = useState(false);
  // const [categoryFilterText, setCategoryFilterText] = useState("");
  // const [isupdatedFilter, setIsupdatedFilter] = useState(false);
  // const [updatedFilterText, setUpdatedFilterText] = useState("");
  // const [iscountryFilter, setIscountryFilter] = useState(false);
  // const [countryFilterText, setCountryFilterText] = useState("");
  // const [islocationFilter, setIsLocationFilter] = useState(false);
  // const [locationFilterText, setLocationFilterText] = useState("");
  //const [islanguageFilter, setIslanguageFilter] = useState(false);
  // const [isprojectFilter, setIsprojectFilter] = useState(false);
  // const [projectFilterText, setProjectFilterText] = useState("");
  // const [isroleFilter, setIsroleFilter] = useState(false);
  // const [roleFilterText, setRoleFilterText] = useState("");
  // const [isstatusFilter, setIsstatusFilter] = useState(false);
  // const [statusFilterText, setStatusFilterText] = useState("");
  // const [isjobProfileFilter, setIsjobProfileFilter] = useState(false);
  // const [jobProfileFilterText, setJobProfileFilterText] = useState("");
  // const [islocalityFilter, setIslocalityFilter] = useState(false);
  // const [localityFilterText, setLocalityFilterText] = useState("");
  // const [israFilter, setIsraFilter] = useState(false);
  // const [raFilterText, setRaFilterText] = useState("");
  // const [isrmFilter, setIsrmFilter] = useState(false);
  // const [rmFilterText, setRmFilterText] = useState("");
  // const [isrcFilter, setIsrcFilter] = useState(false);
  // const [rcFilterText, setRcFilterText] = useState("");
  // const [isamFilter, setIsamFilter] = useState(false);
  // const [amFilterText, setAmFilterText] = useState("");

  const [removeFilter, setRemoveFilter] = useState(0);
  const [eventKey, SetEventKey] = useState('details');
  const getRequestParams = (filter) => {
    let params = {};
    params["filter"] = filter;
    return params;
  };


  const [open, setOpen] = React.useState(false);
  const [show, setShow] = useState(isProfileModal);
  const [candidateId, setCandidateId] = useState(canid);
  //const [newScren, setNewScren] = useState([]);
  const [showingCountOf, setShowingCountOf] = useState(0);
  const [showingCount, setShowingCount] = useState(0);
  const [prevId, setPrevId] = useState(0);
  const [nextId, setNextId] = useState(0);
  const [prevIndex, setPrevIndex] = useState(false);
  const [nextIndex, setNextIndex] = useState(false);
  const [id, setId] = useState(0);
  const [createdAt, setCreatedAt] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const [picimg, setPicImg] = useState("");
  const [defaultYear, setDefaultYear] = useState("");
  const [defaultMonth, setDefaultMonth] = useState("");
  const [yearList, setDefaultList] = useState([]);
  const [monthList, setDefaultMonthList] = useState([]);
  const [name, setName] = useState("");
  const [last_Name, setLastName] = useState("");
  const [email1, setEmail1] = useState("");
  const [email2, setEmail2] = useState("");
  const [systemEmail, setSystemEmail] = useState("");
  const [phone1, setPhone1] = useState("");
  const [phone2, setPhone2] = useState("");
  const [skypeId, setSkypeId] = useState("");
  const [gender, setGender] = useState("");
  const [profileStatusValue, setProfileStatusValue] = useState("");
  const [motivationStatus, setMotivationStatus] = useState("");
  const [indicatorValue, setIndicatorValue] = useState("");
  const [flagTypeValue, setFlagTypeValue] = useState("");

  const [recAdminName, setRecAdminName] = useState("");
  const [recAdminImg, setRecAdminImg] = useState("");

  const [recName, setRecName] = useState("");
  const [recImg, setRecImg] = useState("");

  const [accountManagerName, setAccountManagerName] = useState("");
  const [accountManagerImg, setAccountManagerImg] = useState("");
  const [oldJobId, setOldJobId] = useState("");
  const [popupJobId, setPopupJobId] = useState("");
  const [jobId, setJobId] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [jobCompanyId, setJobCompanyId] = useState("");
  const [jobCompany, setJobCompany] = useState("");
  const [jobCountry, setJobCountry] = useState("");
  //const [jobCity, setJobCity] = useState("");
  //const [jobLocality, setJobLocality] = useState("");
  //const [jobFee, setJobFee] = useState("");
  const [jobNo, setJobNo] = useState("");
  const [jobProject, setJobProject] = useState("");
  const [jobLanguage, setJobLanguage] = useState("");
  const [jobRole, setJobRole] = useState("");
  const [jobStart, setJobStart] = useState("");
  const [isJobIDShow, setJobIDShow] = useState(false);
  const [commentPopup, setCommentPopup] = useState(false);
  const [newStatus, setNewStatus] = useState("")
  const [newProgess, setNewProgess] = useState("")
  const [newStage, setNewStage] = useState("")

  const handleClose = () => setJobIDShow(false);
  const handleCommentShow = () => {
    setCommentPopup(true)
  };
  const closedCommentPopup = () => {
    setCommentPopup(false)
  };

  const handleShow = () => {
    // setInput1Focus()
    setJobIDShow(true);
    setPopupJobId("");
  };

  const onfucuse = () => {
    $("#textboxID").focus();
  };

  const fetchDetails = async () => {
    await setCandidateId(canid);
  };
  const [countComment, setCountComment] = useState(0);

  useEffect(() => {
    if (isProfileProjectUpdate) setCountComment(profileDetails.commentCount);
  }, [isProfileProjectUpdate]);

  const [firstScrean, SetFirstScrean] = useState(false)
  const [secondScrean, SetSecondScrean] = useState(false)
  const [matchedJobs, SetMatchedJobs] = useState(false)


  useEffect(() => {
    if (eventKey) {
      switch (eventKey) {
        case 'first-screening':
          dispatch(fetchById({ id: candidateId }))
          SetFirstScrean(true)
          break;
        case 'second-screening':
          dispatch(fetchById({ id: candidateId }))
          SetSecondScrean(true)
          break;
        case 'matching_jobs':
          SetMatchedJobs(true)
          break;
        case 'emails-logs':
          dispatch(getEmailLogs({ can_id: candidateId }));
          break;

        case 'verify-email':
          dispatch(getEmailVerificationLogsForEmail1({ can_id: candidateId }));
          dispatch(getEmailVerificationLogsForEmail2({ can_id: candidateId }));
          break;

        case 'emails-eventkey':
          dispatch(mailBoxEmailCategory({ can_id: candidateId }));
          dispatch(internalMailBoxEmailCategory({ can_id: id, role: userInfo.role }));
          dispatch(getInternalMail({ can_id: id, role: userInfo.role, type: 'Inbox', cat_id: null }))
          dispatch(getInternalMailCount({ can_id: id, role: userInfo.role }))
          break;
        case 'profile-appications':
          //console.log("profile-appications--", candidateId)
          dispatch(getApplications({ can_id: candidateId }));
          break;
      }
    }
  }, [eventKey]);



  useEffect(() => {
    if (candidateId > 0) {
      findIndexOfProfile();
      const fetchCandidateRecods = async () => {
        dispatch(yearCount({}));
        dispatch(monthsCount({}));
        dispatch(candidateProfileDetails({ id: candidateId }));
        dispatch(candidateProfileRecruiterDetails({ id: candidateId }));
        dispatch(candidateProfileJobDetails({ id: candidateId }));
        dispatch(candidateAllTechSkillsTag({ id: candidateId }));
        dispatch(candidateAllJobProfileSkillsTag({ id: candidateId }));
        dispatch(getInternalMailCount({ can_id: id, role: userInfo.role }))
      };
      fetchCandidateRecods();
    }
  }, [candidateId]);

  useEffect(() => {
    async function fetchData() {
      try {
        const params = getRequestParams(filter);
        dispatch(companyNumbersCounts(params));
        dispatch(languageNumbersCounts(params));
        dispatch(titleNumbersCounts(params));
      } catch (err) {
        console.log(err);
      }
    }
    if (isEmpty(filter)) {
      fetchData();
    }
  }, [filter]);

  useEffect(() => {
    if (!isEmpty(filter)) {
      const params = getFilterRequestParams(
        recruiterFilter,
        updatedFilter,
        companyfilter
      );
      dispatch(companyNumbersCounts(params));
      dispatch(languageNumbersCounts(params));
      dispatch(titleNumbersCounts(params));
    }
  }, [filter, removeFilter]);

  const removeTag = async (e) => {
    const type = e.target.dataset.type;
    switch (type) {
      case "company":

        setCompanyFilterText("");
        delete companyfilter[type];
        delete filter[type];
        setCompanyfilter(companyfilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "lang":
        //setIslanguageFilter(false);
        setLanguageFilterText("");
        delete companyfilter[type];
        delete filter[type];
        setCompanyfilter(companyfilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      default:
        delete recruiterFilter[type];
        delete filter[type];
        setCompanyfilter(recruiterFilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;
    }
    setTagLength(tagLength - 1);
    setFilterTags(filterTags.filter((item) => item.type !== type));
  };

  useEffect(() => {
    if (removeFilter > 0 && isEmpty(filter)) {
      try {
        const params = getRequestParams(filter);
        dispatch(companyNumbersCounts(params));
        dispatch(languageNumbersCounts(params));
        dispatch(titleNumbersCounts(params));
      } catch (err) {
        console.log(err);
      }
    }
  }, [removeFilter]);

  const prevRecord = () => {
    setCandidateId(prevId);
  };

  const nextRecord = () => {
    setCandidateId(nextId);
  };

  const findIndexOfProfile = () => {
    var index;

    list.findIndex(function (entry, i) {
      if (entry.original.canId == candidateId) {
        index = i;
        return true;
      }
    });

    setShowingCountOf(index + 1);
    setShowingCount(list.length);

    if (!isEmpty(list[index - 1])) {
      setPrevId(list[index - 1].original.canId);
      setPrevIndex(true);
    } else {
      setPrevIndex(false);
    }
    if (!isEmpty(list[index + 1])) {
      setNextId(list[index + 1].original.canId);
      setNextIndex(true);
    } else {
      setNextIndex(false);
    }
  };

  function isEmpty(obj) {
    for (var i in obj) {
      return false;
    }
    return true;
  }

  const getFilterRequestParams = (
    recruiterFilter,
    updatedFilter,
    companyfilter
  ) => {
    let params = {};
    params["filter"] = {
      recruiterFilter: recruiterFilter,
      updatedFilter: updatedFilter,
      companyfilter: companyfilter,
    };
    return params;
  };

  const selectFilter = async (e) => {
    const type = e.target.dataset.type;
    const value = e.target.dataset.value;
    let i;
    switch (type) {
      case "company":

        setCompanyFilterText(value);
        setCompanyfilter({ ...companyfilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "lang":
        //setIslanguageFilter(true);
        setLanguageFilterText(value);
        setCompanyfilter({ ...companyfilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      default:
        i = filterTags.findIndex(function (x) {
          return x.type === type;
        });
        if (i === -1) {
          filterTags.push({ type, value });
        }
        setRecruiterFilter({ ...recruiterFilter, [type]: value });
        setUpdatedFilter({ ...updatedFilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;
    }
    setTagLength(tagLength + 1);
    setFilterTags(filterTags);
  };

  useEffect(() => {
    if (isProfileLoading) {
      setOpen(true);
    }
    if (!isProfileLoading && profileDetails) {
      setOpen(false);
      if (!isEmpty(profileDetails)) {
        setId(profileDetails.id);
        setCountComment(profileDetails.commentCount);
        setCreatedAt(profileDetails.createdAt);
        setUpdatedAt(profileDetails.updatedAt);
        setPicImg(profileDetails.profileImage);
        setDefaultYear(profileDetails.year);
        setDefaultMonth(profileDetails.month);
        setName(profileDetails.first_name);
        setLastName(profileDetails.last_name);
        setEmail1(profileDetails.email1);
        setEmail2(profileDetails.email2);
        setSystemEmail(profileDetails.system_email);
        setPhone1(profileDetails.phone1);
        setPhone2(profileDetails.phone2);
        setGender(profileDetails.gender);
        setSkypeId(profileDetails.skypeId);
        setProfileStatusValue(profileDetails.profileStatus);
        setIndicatorValue(profileDetails.profileIndicator);
        setFlagTypeValue(profileDetails.flag_type);
        setMotivationStatus(profileDetails.motivationStatus);
        //   setCommentCount(profileDetails.commentCount)
        setDefaultList(defaultYearList);
        setDefaultMonthList(defaultMonthList);
      }
    }

    if (!isProfileRecLoading && !isEmpty(profileRecDetails)) {
      setRecAdminName(
        profileRecDetails.recAdminInfo.firstName +
        " " +
        profileRecDetails.recAdminInfo.lastName
      );
      setRecAdminImg(profileRecDetails.recAdminInfo.profileImage);

      setRecName(
        profileRecDetails.recInfo.firstName +
        " " +
        profileRecDetails.recInfo.lastName
      );
      setRecImg(profileRecDetails.recInfo.profileImage);

      setAccountManagerName(profileRecDetails.amInfo.firstName + " " + profileRecDetails.amInfo.lastName);
      setAccountManagerImg(profileRecDetails.amInfo.profileImage);
    }

    if (!isProfileJobLoading && !isEmpty(profileJobDetails)) {
      setOldJobId(profileJobDetails.jobDetail.job_id);
      setJobId(profileJobDetails.jobDetail.job_id);
      setJobTitle(profileJobDetails.jobDetail.name);
      setJobCompanyId(
        profileJobDetails.jobDetail.com_id != 0
          ? profileJobDetails.jobDetail.com_id
          : ""
      );
      setJobCompany(profileJobDetails.jobDetail.company);
      setJobCountry(profileJobDetails.jobDetail.job_country);
      //setJobLocality(profileJobDetails.jobDetail.locality);
      setJobNo(
        profileJobDetails.jobDetail.no_of_jobs != 0
          ? profileJobDetails.jobDetail.no_of_jobs
          : ""
      );
      // setJobFee(
      //   profileJobDetails.jobDetail.fee != 0
      //     ? profileJobDetails.jobDetail.fee
      //     : ""
      // );
      setJobProject(profileJobDetails.jobDetail.project);
      setJobLanguage(profileJobDetails.jobDetail.lang);
      setJobRole(profileJobDetails.jobDetail.role);
      setJobStart(profileJobDetails.jobDetail.date1);
    }
    if (iscommentUpdate) {
      dispatch(candidateProfileDetails({ id: candidateId }));
      setCountComment(profileDetails.commentCount);
    }
    if (isApplication) {
      dispatch(clearState())
    }
  }, [
    isProfileLoading,
    isLoading,
    isProfileUpdate,
    isUpdate,
    iscommentUpdate,
    isCommentDelete,
    isProfileRecLoading,
    isProfileJobLoading,
    isProfileLanguageLoading,
    isApplication
  ]);

  const updateYears = async (idd, value) => {
    await dispatch(updateProfileDetails({ id, key: "year", value }));
    await dispatch(updateTable({ id, key: "year", value }));
    setDefaultYear(value);
  };
  const updateMonths = async (idd, value) => {
    await dispatch(updateProfileDetails({ id, key: "month", value }));
    await dispatch(updateTable({ id, key: "month", value }));
    setDefaultMonth(value);
  };

  // const updateGender = async (id, value) => {
  //   await dispatch(updateProfileDetails({ id, key: "gender", value }));
  //   await dispatch(updateTable({ id, key: "gender", value }));
  // };

  const updateJobCandidate = async (id) => {
    await dispatch(updateTable({ id: id, key: "jobId", value: jobId }));
    await dispatch(candidateProfileJobDetails({ id: candidateId }));
    await dispatch(
      updateProfileDetails({ id: id, key: "jobId", value: jobId })
    );
    setOpen(true);
    setJobIDShow(false);
    setOldJobId(jobId);
    setOpen(false);
  };

  const updateJobCandidate1 = async (id, jobIds) => {
    await dispatch(updateTable({ id: id, key: "jobId", value: jobIds }));
    await dispatch(candidateProfileJobDetails({ id: candidateId }));
    await dispatch(
      updateProfileDetails({ id: id, key: "jobId", value: jobIds })
    );

    setCompanyFilterText("");

    //setIslanguageFilter(false);
    setLanguageFilterText("");

    setCompanyfilter({});
    setFilter({});
    setRemoveFilter(0);
  };

  const updateProfileQuick = async (valueId, value, img, columnId) => {
    switch (columnId) {
      case "profileStatus":
        $(`#profileStatus${id} button .dk-cvClear-status svg`).css(
          "color",
          img
        );
        break;

      case "profileIndicator":
        $(`#profileIndicator${id} button .dk-cvClear-circle i`).css(
          "color",
          img
        );
        break;

      case "motivationStatus":
        $(`#motivationStatus${id} button .dk-cvClear-userIcon svg`).css(
          "color",
          img
        );
        break;

      case "flag_type":
        $(`#flag_type${id} button .dk-cvClear-flag i`).css("color", img);
        break;
    }
    await dispatch(updateProfileDetails({ id: valueId, key: columnId, value }));
    await dispatch(
      updateCandidateProfile({ id: valueId, key: columnId, value })
    );
  };

  const changeColorType = (id, colr, dataKey) => {

    if (id != undefined && colr != undefined && dataKey != undefined) {
      dispatch(updateCandidateProfile({ id, key: dataKey, value: colr }));
    }

  }
  const updateDefault = async (id, value, img, key) => {
    //console.log(id, value, img, key)
    if (key === "am_id") {
      setRecName('No User');
      setRecImg(img);
    }
    if (key === "rec_id") {
      setRecName('No User');
      setRecImg(img);
    }
    if (key === "rec_admin") {
      setRecName('No User');
      setRecImg(img);
    }
    await dispatch(updateProfileDetails({ id, key, value }));
    await dispatch(updateCandidateProfile({ id, key, value }));

  }
  const updateProfile = async (e) => {
    const id = e.target.dataset.id;
    const key = e.target.dataset.key;
    const value = e.target.dataset.value;

    if (key === "rec_id") {
      setRecName(e.target.dataset.name);
      setRecImg(e.target.dataset.img);
    }
    if (key === "am_id") {
      setAccountManagerName(e.target.dataset.name);
      setAccountManagerImg(e.target.dataset.img);
    }
    if (key === "rec_admin") {
      setRecAdminName(e.target.dataset.name);
      setRecAdminImg(e.target.dataset.img);
    }

    if (key === "first_name" || key === "last_name") {
      let fullname = name + ' ' + last_Name
      await dispatch(updateCandidateProfile({ id, key: 'name', value: fullname }));
    }
    await dispatch(updateProfileDetails({ id, key, value }));
    await dispatch(updateCandidateProfile({ id, key, value }));


    if (key === "jobId") {
      setOpen(true);
      dispatch(candidateProfileJobDetails({ id: candidateId }));
      isJobIDShow(false);
      setOldJobId(value);
      setOpen(false);
    }

    switch (key) {
      case "caseStatus":
        setNewStatus(value)
        setNewProgess("")
        setNewStage("")
        await dispatch(
          updateProfileDetails({ id, key: "case_progress", value: "" })
        );
        await dispatch(
          updateProfileDetails({ id, key: "case_stage", value: "" })
        );
        await dispatch(
          updateCandidateProfile({ id, key: "case_progress", value: "" })
        );
        await dispatch(
          updateCandidateProfile({ id, key: "case_stage", value: "" })
        );
        break;
      case "case_progress":
        setNewProgess(value)
        setNewStage("")
        await dispatch(
          updateProfileDetails({ id, key: "case_stage", value: "" })
        );
        await dispatch(
          updateCandidateProfile({ id, key: "case_stage", value: "" })
        );
        break;
      case "case_stage":
        setNewStage(value)
        break;
      default:
        break;
    }

  };

  const updateDates = async (e) => {
    const myArray = e.split("==");
    const id = myArray[0];
    const value = moment(myArray[1])
      .tz("Europe/Dublin")
      .format("YYYY-MM-DD HH:mm:ss");
    const key = myArray[2];
    await dispatch(updateTable({ id, key, value }));
  };

  const updateEditor = async (e) => {
    const myArray = e.split("==");
    const id = myArray[0];
    const value = myArray[1];
    const key = myArray[2];
    await dispatch(updateTable({ id, key, value }));
    await dispatch(updateProfileDetails({ id, key, value }));
  };

  const [tabId, setTabId] = useState(0);
  const tabSelect = (key) => {
    setTabId(key);
  };

  const [isAddSkills, setIsAddSkills] = useState(false);

  // const showAddProfieSkills = () => {
  //   setIsAddSkills(true);
  // };
  const hideAddProfieSkills = () => {
    setIsAddSkills(false);
  };

  const [isShowSocialModal, setIsSocialModal] = useState(false);

  // const showSocialModal = () => {
  //   setIsSocialModal(true);
  // };
  const hideSocialModal = () => {
    setIsSocialModal(false);
  };

  const [showComposeModal, setShowCompose] = useState(false);
  const [to, setTo] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);

  const getPreviouslySelectedMailAccount = (mailList) => {
    const mail = getDefaultMail();
    if (mail) {
      return mailList.find(item => item.domain.toLowerCase() === mail.toLowerCase()) || mailList[0];
    }
    return mailList[0];
  }

  const showBulkEmailModal = async () => {
    try {
      const res = await GetData(true, "/mail/list");
      const mailList = res.payload.result;
      if (mailList.length > 0) {
        const selected = getPreviouslySelectedMailAccount(mailList);
        setTo([
          {
            email: email1,
            name: name
          }
        ])
        setSelectedAccount(selected);
        setShowCompose(true);
      }
    } catch (e) {
      console.error(e);
    }
  }

  const [showInternalMailboxComposeModal, setShowInternalMailboxComposeModal] = useState(false);

  const handleInternalMailboxShow = () => {
    setShowInternalMailboxComposeModal(true);
  }

  const hideInternalMailboxComposeModal = () => {
    setShowInternalMailboxComposeModal(false);
  }

  return (
    <>
      {isAddSkills ? <AddIndustry hidePopup={hideAddProfieSkills} /> : null}
      {isShowSocialModal ? (
        <SocialChatsModal hidesocial={hideSocialModal} />
      ) : null}
      <ComposeMail
        show={showComposeModal}
        onHide={() => setShowCompose(false)}
        currentAccount={selectedAccount}
        toList={to}
        type="bulk"
      />

      <ComposeInternalMail 
        show={showInternalMailboxComposeModal}
        onHide={() => hideInternalMailboxComposeModal()}
      />

      <Modal
        show={show}
        onHide={handleCloseProfile}
        dialogClassName="dk-candidateProfile80"
        aria-labelledby="example-custom-modal-styling-title"
        onEntered={fetchDetails}
        onExited={function () {
        }}
        onShow={function () {
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className="dk-candModalHeader">
          <Modal.Title
            className="dk-candModalTitle"
            id="example-custom-modal-styling-title"
          >
            <div className="dkg-years-DropdownCon">
              <div className="dkg-clientid" style={{ fontWeight: "normal" }}>Case ID -  {candidateId}{" "}</div>
            </div>

            {prevIndex ? (
              <Link to="#" onClick={prevRecord}>
                <i className="fa fa-chevron-left mr-2"></i>
              </Link>
            ) : null}
            {showingCountOf ? showingCountOf : 1} of{" "}
            {showingCount !== 0 ? showingCount : 1}
            {nextIndex ? (
              <Link to="#" onClick={nextRecord}>
                <i className="fa fa-chevron-right ml-2"></i>
              </Link>
            ) : null}

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="dk-candProfileModalBody">
          <Backdrop
            className={classes.backdrop}
            open={open}
            onClick={() => {
              setOpen(false);
            }}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <div className="dk-BottomSecCon">
            <div className="row mr-0 ml-0">
              <div className="col-md-12 col-sm-12 dk-candidateVertcalTabCon pl-0 pr-0">
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="details"
                  onSelect={tabSelect}
                >
                  <div className="row mr-0 ml-0">
                    <div className="col-md-12 col-sm-12 dk-candProfileSideMenu dkg-candProfile-horizontal pl-0 pr-0"
                      id="dk-cliDetailsV2"
                    >
                      <Nav
                        variant="pills"
                        className="flex-row dk-candNavPills  dkg-horizontal-navpills"
                        onSelect={(selectedKey) => SetEventKey(selectedKey)}
                      >
                        <Nav.Item>
                          <Nav.Link eventKey="details">Profile</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="profile_skills">Skills</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="emails-eventkey">Mailbox {internalMailsCount?.inbox > 0 ? <span className="dkg-mailbadgecon">{internalMailsCount?.inbox}</span> : ''}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="profile-appications">Job Apps</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="profile-referrals">Referrals</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="profile-dkgcv">DK Resume</Nav.Link>
                        </Nav.Item>
                        {/* <Nav.Item>
                          <Nav.Link eventKey="event-logs">Desired Job</Nav.Link>
                        </Nav.Item> */}
                        <Nav.Item>
                          <Nav.Link eventKey="profile-desiredjob">Desired Job</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="profile-myservices">DK Services</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="emails-logs">Email Report</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="verify-email">Verify Email</Nav.Link>
                        </Nav.Item>
                        {/* <Nav.Item>
                          <Nav.Link eventKey="tech_skills">Tech Skills</Nav.Link>
                        </Nav.Item> */}
                        {/* <Nav.Item>
                          <Nav.Link eventKey="first-screening">1st Screen</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="second-screening">2nd Screen</Nav.Link>
                        </Nav.Item> */}
                        {/* <Nav.Item>
                          <Nav.Link eventKey="trackings">TRACKINGS</Nav.Link>
                        </Nav.Item> */}
                        <Nav.Item>
                          <Nav.Link eventKey="matching_jobs">Jobs Matching</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="documents">My Files</Nav.Link>
                        </Nav.Item>
                        {/* <Nav.Item>
                          <Nav.Link eventKey="account-logs">A/c Logs</Nav.Link>
                        </Nav.Item> */}
                      </Nav>
                    </div>
                    <div className="col-md-12 col-sm-12 dk-candProfileMainCont dk-candProfileMain-fullWidth">
                      <Tab.Content>
                        <Tab.Pane eventKey="details" className="dkg-cand-profile-newtabpane">
                          <div className="dk-topSectionCon">
                            <div className="row">
                              <div className="col-md-5 col-sm-12 pr-0">
                                <div className="dk-TopleftCon">
                                  <form>
                                    <div className="row">
                                      <div className="col-md-4 col-sm-12 pr-1 dkg-col-three-half">
                                        <div>
                                          <div className="dk-cansuMMFromGrp">
                                            <label></label>
                                            <div className="dk-modalHeader cvclear_LinkMain">

                                            </div>
                                          </div>
                                        </div>
                                        <div>
                                          <div className="dk-topLeftIcon-user">
                                            {gender == "Male" ? (
                                              <img src={(picimg !== "" || picimg !== null) ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                                                picimg : leftBoxUserMen} />
                                            ) : gender == "Female" ? (
                                              <img src={(picimg !== "" || picimg !== null) ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                                                picimg : leftBoxUserGirl} />
                                            ) : (
                                              <img src={leftBoxUserDefault} />
                                            )}
                                          </div>
                                        </div>
                                        {/* <div>
                                          <div className="form-group dk-Candinate-fromGroup d-flex dk-sexDropdown">
                                            <label>Gender</label>
                                            <Dropdown
                                              className={gender == "" ? "errorBorder" : null}
                                            >
                                              <Dropdown.Toggle variant="" id="dropdown-basic-5">
                                                {gender == "" ? "Select" : gender}
                                              </Dropdown.Toggle>
                                              <Dropdown.Menu className="dkg-candstatus-dropmenu">
                                                <Dropdown.Item
                                                  href="#/action-1"
                                                  data-id={id}
                                                  data-key="gender"
                                                  data-value="Male"
                                                  onClick={(e) => {
                                                    setGender("Male");
                                                    updateGender(id, "Male");
                                                  }}
                                                >
                                                  Male
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  href="#/action-2"
                                                  data-id={id}
                                                  data-key="gender"
                                                  data-value="Female"
                                                  onClick={(e) => {
                                                    setGender("Female");
                                                    updateGender(id, "Female");
                                                  }}
                                                >
                                                  Female
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  href="#/action-3"
                                                  data-id={id}
                                                  data-key="gender"
                                                  data-value="Others"
                                                  onClick={(e) => {
                                                    setGender("Others");
                                                    updateGender(id, "Others");
                                                  }}
                                                >
                                                  Others
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div> */}
                                        {/* <div>
                                          <div className="dk-cansuMMFromGrp">
                                            <label>Case ID</label>
                                            <div className="dk-candidateProfile-caseID">
                                              <label className="dk-caseIDbg">
                                                {candidateId}{" "}
                                                <span>
                                                  <FaIdCard />
                                                </span>
                                              </label>
                                            </div>
                                          </div>
                                        </div> */}
                                        <div className="dd">
                                          <div className="dkg-month-dropdownCon">
                                            <label>Year</label>
                                            <Dropdown className="dkg-years-Dropdown">
                                              <Dropdown.Toggle
                                                variant=""
                                                className="dkg-years-dropdown-toggle"
                                              >
                                                {defaultYear}
                                              </Dropdown.Toggle>
                                              <Dropdown.Menu className="dkg-years-DropdownMenu">
                                                {yearList.map(function (item, i) {
                                                  return (
                                                    <>
                                                      <Dropdown.Item
                                                        onClick={(e) => updateYears(candidateId, item.value)}
                                                        key={i}
                                                        href="#"
                                                        className="dkg-years-DropdownItem"
                                                      >
                                                        {item.value}
                                                      </Dropdown.Item>
                                                    </>
                                                  );
                                                })}
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="dddd">
                                          <div className="dkg-month-dropdownCon">
                                            <label>Month</label>
                                            <Dropdown className="dkg-years-Dropdown ">
                                              <Dropdown.Toggle
                                                variant=""
                                                className="dkg-years-dropdown-toggle"
                                              >
                                                {defaultMonth}
                                              </Dropdown.Toggle>
                                              <Dropdown.Menu className="dkg-years-DropdownMenu">
                                                {monthList.map(function (item, i) {
                                                  return (
                                                    <>
                                                      <Dropdown.Item
                                                        onClick={(e) => updateMonths(candidateId, item.value)}
                                                        key={i}
                                                        href="#"
                                                        className="dkg-years-DropdownItem"
                                                      >
                                                        {item.value}
                                                      </Dropdown.Item>
                                                    </>
                                                  );
                                                })}
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div>
                                          <div className="dk-cansuMMFromGrp">
                                            <label>Created</label>
                                            <div className="selDrop14 dkg-candprofile-readable">
                                              {/* <DatePicker date={createdAt} candidateId={id} updateDates={updateDates} column="createdAt" /> */}
                                              <CustomDateFormat
                                                date={createdAt}
                                                format="DD MMM YY"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div>
                                          <div className="dk-cansuMMFromGrp">
                                            <label>Updated</label>
                                            <div className="selDrop14 dkg-candprofile-readable">
                                              {/* <DatePicker date={updatedAt} candidateId={id} updateDates={updateDates} column="updatedAt" /> */}
                                              <CustomDateFormat
                                                date={updatedAt}
                                                format="DD MMM YY"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-8 col-sm-12  dkg-col-eight-half">
                                        <div className="">
                                          <div className="form-group dk-Candinate-fromGroup d-flex">
                                            <div className="d-flex w-100">
                                              <label>Name</label>
                                              <input
                                                type="text"
                                                className={
                                                  name == ""
                                                    ? "form-control errorBorder"
                                                    : "form-control"
                                                }
                                                style={{ minWidth: "130px", maxWidth: "130px" }}
                                                data-id={id}
                                                data-key="first_name"
                                                data-value={name}
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                onBlur={updateProfile}
                                              />
                                              {/* <span style={{minWidth: "28px" , maxWidth: "28px"}}>
                                                <i className="fas fa-user"></i>
                                              </span> */}
                                              <div className="d-flex pl-4 w-100">
                                                <input
                                                  type="text"
                                                  style={{ width: "100%" }}
                                                  className={
                                                    last_Name == ""
                                                      ? "form-control errorBorder"
                                                      : "form-control"
                                                  }
                                                  data-id={id}
                                                  data-key="last_name"
                                                  data-value={last_Name}
                                                  value={last_Name}
                                                  onChange={(e) => setLastName(e.target.value)}
                                                  onBlur={updateProfile}
                                                />
                                                {/* <span style={{minWidth: "28px" , maxWidth: "28px"}}>
                                                  <i className="fas fa-user"></i>
                                                </span> */}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div>
                                          <div className="form-group dk-Candinate-fromGroup d-flex">
                                            <label>Email 1</label>
                                            <input
                                              type="text"
                                              className={
                                                email1 == ""
                                                  ? "form-control errorBorder"
                                                  : "form-control"
                                              }
                                              data-id={id}
                                              data-key="email1"
                                              data-value={email1}
                                              value={email1}
                                              onChange={(e) => setEmail1(e.target.value)}
                                              onBlur={updateProfile}
                                            />
                                            <span onClick={showBulkEmailModal}>
                                              <i className="fas fa-envelope"></i>
                                            </span>
                                          </div>
                                        </div>
                                        <div>
                                          <div className="form-group dk-Candinate-fromGroup d-flex">
                                            <label>Email 2</label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              data-id={id}
                                              data-key="email2"
                                              data-value={email2}
                                              value={email2}
                                              onChange={(e) => setEmail2(e.target.value)}
                                              onBlur={updateProfile}
                                            />
                                            <span
                                              onClick={showBulkEmailModal}
                                              style={{ padding: "0 0.3rem", color: "#495057" }}
                                            >
                                              <i className="fas fa-envelope"></i>
                                            </span>
                                          </div>
                                        </div>
                                        <div>
                                          <div className="form-group dk-Candinate-fromGroup d-flex">
                                            <label>System</label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              data-id={id}
                                              defaultValue={systemEmail}
                                              value={systemEmail}
                                              data-key="systememail"
                                              readOnly
                                              style={{ backgroundColor: "#f2f2f2" }}
                                            />
                                            <span
                                              onClick={handleInternalMailboxShow}
                                              style={{ padding: "0 0.3rem", color: "#495057" }}
                                            >
                                              <i className="fas fa-envelope"></i>
                                            </span>
                                          </div>
                                        </div>
                                        <div>
                                          <div className="form-group dk-Candinate-fromGroup d-flex">
                                            <label>Phone 1</label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              data-id={id}
                                              data-key="phone1"
                                              data-value={phone1}
                                              value={phone1}
                                              onChange={(e) => setPhone1(e.target.value)}
                                              onBlur={updateProfile}
                                            />
                                            <span
                                              style={{ padding: "0 0.3rem", color: "#495057" }}
                                            >
                                              <i className="fas fa-phone"></i>
                                            </span>
                                          </div>
                                        </div>
                                        <div>
                                          <div className="form-group dk-Candinate-fromGroup d-flex">
                                            <label>Phone 2</label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              data-id={id}
                                              data-key="phone2"
                                              data-value={phone2}
                                              value={phone2}
                                              onChange={(e) => setPhone2(e.target.value)}
                                              onBlur={updateProfile}
                                            />
                                            <span
                                              style={{ padding: "0 0.3rem", color: "#495057" }}
                                            >
                                              <i className="fas fa-phone"></i>
                                            </span>
                                          </div>
                                        </div>
                                        {/* <div>
                                          <div className="form-group dk-Candinate-fromGroup d-flex">
                                            <label>WhatsApp</label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              data-id={id}
                                              data-key="skypeId"
                                              data-value={skypeId}
                                              value={skypeId}
                                              onChange={(e) => setSkypeId(e.target.value)}
                                              onBlur={updateProfile}
                                            />
                                            <span
                                              style={{ padding: "0 0.3rem", color: "#495057" }}
                                            >
                                              <img src={WhatsIcon} />
                                            </span>
                                          </div>
                                        </div> */}
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                              <div className="col-md-2 col-sm-12">
                                <div className="dk-TopCntreCon">
                                  <div className="dk-TopCntreBox">
                                   <span className="mr-2">
                                      <SelectAccountManagerDropDwn
                                        name={accountManagerName}
                                        img={accountManagerImg}
                                        canId={id}
                                        updateProfile={updateProfile}
                                        className="mr-2"
                                        updateDefault={updateDefault}
                                      />
                                    </span>
                                    <span className="mr-2">
                                      {" "}
                                      <SelectRecruiterDropDwn
                                        name={recName}
                                        img={recImg}
                                        canId={id}
                                        updateProfile={updateProfile}
                                      />
                                    </span>
                                    <span>
                                      {" "}
                                      <SelectRecruiterAdminDropDwn
                                        name={recAdminName}
                                        img={recAdminImg}
                                        canId={id}
                                        updateProfile={updateProfile}
                                      />
                                    </span>
                                  </div>
                                  <CandidateDocumentModal name="CV" canId={candidateId} />

                                  {
                                    (commentPopup == true) ? <>
                                      <CommentModal
                                        refTable="candidates"
                                        refId={candidateId}
                                        count={countComment}
                                        title="CASE COMMENTS"
                                        candidateStatus={newStatus != '' ? newStatus : profileDetails.caseStatus}
                                        candidateProgess={newProgess != '' ? newProgess : profileDetails.case_progress}
                                        candidateStage={newStage != '' ? newStage : profileDetails.case_stage}
                                        closedCommentPopup={closedCommentPopup}
                                      />
                                    </> : null
                                  }


                                  <div className="dk-TopCntreBox commChatBox">
                                    <div className="dk-comment-box">
                                      <span className="chatIcon mr-2">
                                        <img src={BubbleChat} className="chatimg" alt="BubbleChat_Icon" />
                                      </span>
                                    </div>

                                    <div className="dk-timeline-box" onClick={handleCommentShow}>
                                      <span className="commentIcon">
                                        <Link className="numCon" to="#">{countComment}</Link>
                                      </span>
                                      <span className="chatIcon mr-2 d-none">
                                        <FaRegClock />
                                      </span>
                                      <span className="commentIcon d-none">
                                        <Link className="numCon" to="#">
                                          <span><FaSignature style={{ color: "#fff" }} /></span>
                                        </Link>
                                      </span>
                                    </div>
                                  </div>

                                  <div className="dk-TopCntreBox cleartextBox pd-0">
                                    <div className="dk-socialFlex dkg-candstatus-icon-Con">
                                      {/* <Link to="#" onClick={showSocialModal}>
                                        <img src={EmailIcon} alt="" />
                                      </Link>
                                      <Link to="#" onClick={showSocialModal}>
                                        <img src={PhoneIcon} alt="" />
                                      </Link>
                                      <Link to="#" onClick={showSocialModal}>
                                        <img src={SmsIcon} alt="" />
                                      </Link>
                                      <Link to="#" onClick={showSocialModal}>
                                        <img src={WhatsIcon} alt="" />
                                      </Link>
                                      <Link to="#" onClick={showSocialModal}>
                                        <img src={UserIcon} alt="" />
                                      </Link> */}
                                      <Link to="#" className="dkg-candstatus-icon">
                                        <Dropdown
                                          className="dkg-cand-status-dropdown"
                                          id={`profileIndicator${id}`}
                                        >
                                          <Dropdown.Toggle variant="" id="dropdown-basic">
                                            <div className="dk-cvClear-circle">
                                              <i
                                                className="fas fa-circle"
                                                style={{
                                                  color: indicatorColor(indicatorValue),
                                                }}
                                              ></i>
                                            </div>
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu className="dkg-candstatus-dropmenu">
                                            <div className="d-flex">
                                              <Dropdown.Item
                                                href="#"
                                                onClick={() =>
                                                  updateProfileQuick(
                                                    id,
                                                    2,
                                                    "green",
                                                    "profileIndicator"
                                                  )
                                                }
                                                data-id={id}
                                                data-key="profileIndicator"
                                                data-value="2"
                                              >
                                                <span className="circleIcon ">
                                                  <i className="fas fa-circle" style={{ color: "green" }}></i>
                                                </span>
                                                <small className="statusText">
                                                  Profile Updated
                                                </small>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                href="#"
                                                onClick={() =>
                                                  updateProfileQuick(
                                                    id,
                                                    1,
                                                    "#d3d3d3",
                                                    "profileIndicator"
                                                  )
                                                }
                                                data-id={id}
                                                data-key="profileIndicator"
                                                data-value="1"
                                              >
                                                <span className="circleIcon">
                                                  <i className="fas fa-circle" style={{ color: "#d3d3d3" }}></i>
                                                </span>
                                                <small className="statusText">Profile Blank</small>
                                              </Dropdown.Item>
                                            </div>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </Link>
                                      <Link to="#" className="dkg-candstatus-icon">
                                        <Dropdown
                                          className="dkg-cand-status-dropdown flagDropDown"
                                          id={`flag_type${id}`}
                                        >
                                          <Dropdown.Toggle variant="" id="dropdown-basic-4">
                                            <div className="dk-cvClear-flag">
                                              <i
                                                className="fa fa-flag"
                                                style={{
                                                  color: flagColor(flagTypeValue),
                                                  fontSize: "14px",
                                                }}
                                              ></i>
                                            </div>
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu className="dkg-candstatus-dropmenu">
                                            <div className="d-flex">
                                              <Dropdown.Item
                                                href="#"
                                                onClick={() =>
                                                  updateProfileQuick(
                                                    id,
                                                    5,
                                                    "#3a9469",
                                                    "flag_type"
                                                  )
                                                }
                                                data-id={id}
                                                data-key="flag_type"
                                                data-value="5"
                                              >
                                                <i
                                                  className="fa fa-flag"
                                                  style={{ color: "#3a9469" }}
                                                ></i>
                                                <small className="flagText">Good Case</small>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                href="#"
                                                onClick={() =>
                                                  updateProfileQuick(
                                                    id,
                                                    3,
                                                    "#D58B5D",
                                                    "flag_type"
                                                  )
                                                }
                                                data-id={id}
                                                data-key="flag_type"
                                                data-value="3"
                                              >
                                                <i
                                                  className="fa fa-flag"
                                                  style={{ color: "#D58B5D" }}
                                                ></i>
                                                <small className="flagText">Normal Case</small>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                href="#"
                                                onClick={() =>
                                                  updateProfileQuick(
                                                    id,
                                                    2,
                                                    "#E2445B",
                                                    "flag_type"
                                                  )
                                                }
                                                data-id={id}
                                                data-key="flag_type"
                                                data-value="2"
                                              >
                                                <i
                                                  className="fa fa-flag"
                                                  style={{ color: "#E2445B" }}
                                                ></i>
                                                <small className="flagText">Bad Case</small>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                href="#"
                                                onClick={() =>
                                                  updateProfileQuick(
                                                    id,
                                                    0,
                                                    "#d3d3d3",
                                                    "flag_type"
                                                  )
                                                }
                                                data-id={id}
                                                data-key="flag_type"
                                                data-value="0"
                                              >
                                                <i
                                                  className="fa fa-flag"
                                                  style={{ color: "#d3d3d3" }}
                                                ></i>
                                                <small className="flagText">Blank</small>
                                              </Dropdown.Item>
                                            </div>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </Link>
                                      <Link to="#" className="dkg-candstatus-icon">
                                        <Dropdown
                                          className="dkg-cand-status-dropdown dk-userDropdown"
                                          id={`motivationStatus${id}`}
                                        >
                                          <Dropdown.Toggle variant="" id="dropdown-basic-3">
                                            <div className="dk-cvClear-userIcon">
                                              <FaReplyAll
                                                style={{
                                                  color:
                                                    profileMotivationStatus(
                                                      motivationStatus
                                                    ),
                                                }}
                                              />
                                            </div>
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu className="dkg-candstatus-dropmenu">
                                            <div className="d-flex">
                                              <Dropdown.Item
                                                href="#"
                                                onClick={() =>
                                                  updateProfileQuick(
                                                    id,
                                                    1,
                                                    "green",
                                                    "motivationStatus"
                                                  )
                                                }
                                              >
                                                <span>
                                                  <FaReplyAll style={{ color: "green" }} />
                                                </span>
                                                <small className="statusText">High Response</small>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                href="#"
                                                onClick={() =>
                                                  updateProfileQuick(
                                                    id,
                                                    2,
                                                    "#d58b5d",
                                                    "motivationStatus"
                                                  )
                                                }
                                              >
                                                <span>
                                                  <FaReplyAll style={{ color: "#d58b5d" }} />
                                                </span>
                                                <small className="statusText">
                                                  Good Response
                                                </small>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                href="#"
                                                onClick={() =>
                                                  updateProfileQuick(
                                                    id,
                                                    3,
                                                    "#ff0000",
                                                    "motivationStatus"
                                                  )
                                                }
                                              >
                                                <span>
                                                  <FaReplyAll style={{ color: "#ff0000" }} />
                                                </span>
                                                <small className="statusText">Low Response</small>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                href="#"
                                                onClick={() =>
                                                  updateProfileQuick(
                                                    id,
                                                    0,
                                                    "#d3d3d3",
                                                    "motivationStatus"
                                                  )
                                                }
                                              >
                                                <span>
                                                  <FaReplyAll style={{ color: "#d3d3d3" }} />
                                                </span>
                                                <small className="statusText">Blank</small>
                                              </Dropdown.Item>
                                            </div>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </Link>
                                      <Link to="#" className="dkg-candstatus-icon">
                                        <Dropdown
                                          className="dkg-cand-status-dropdown"
                                          id={`profileStatus${id}`}
                                        >
                                          <Dropdown.Toggle variant="" id="dropdown-basic-2">
                                            <div className="dk-cvClear-status">
                                              {
                                                <FaCheck
                                                  style={{
                                                    color:
                                                      profileStatus(profileStatusValue),
                                                  }}
                                                />
                                              }
                                            </div>
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu className="dk-statusDropdown">
                                            <div className="d-flex">
                                              <Dropdown.Item
                                                href="#"
                                                onClick={() =>
                                                  updateProfileQuick(
                                                    id,
                                                    1,
                                                    "#048204",
                                                    "profileStatus"
                                                  )
                                                }
                                                data-id={id}
                                                data-key="profileStatus"
                                                data-value="1"
                                              >
                                                <FaCheck style={{ color: "#048204" }} />
                                                <small className="smallTitle">
                                                  Top Priority
                                                </small>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                href="#"
                                                onClick={() =>
                                                  updateProfileQuick(
                                                    id,
                                                    2,
                                                    "#D58B5D",
                                                    "profileStatus"
                                                  )
                                                }
                                                data-id={id}
                                                data-key="profileStatus"
                                                data-value="2"
                                              >
                                                <FaCheck style={{ color: "#D58B5D" }} />
                                                <small className="smallTitle">
                                                  Mid Priority
                                                </small>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                href="#"
                                                onClick={() =>
                                                  updateProfileQuick(
                                                    id,
                                                    4,
                                                    "#d60101",
                                                    "profileStatus"
                                                  )
                                                }
                                                data-id={id}
                                                data-key="profileStatus"
                                                data-value="4"
                                              >
                                                <FaCheck style={{ color: "#d60101" }} />
                                                {/* <small className="smallTitle">0%</small> */}
                                                <small className="smallTitle">
                                                  Low Priority
                                                </small>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                href="#"
                                                onClick={() =>
                                                  updateProfileQuick(
                                                    id,
                                                    0,
                                                    "#d3d3d3",
                                                    "profileStatus"
                                                  )
                                                }
                                                data-id={id}
                                                data-key="profileStatus"
                                                data-value="0"
                                              >
                                                <FaCheck style={{ color: "#d3d3d3" }} />
                                                <small className="smallTitle">Blank</small>
                                              </Dropdown.Item>
                                            </div>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-5 col-sm-12 pl-0">
                                <div className="dk-TopRightCon">
                                  <form>
                                    <div className="row">
                                      <div className="col-md-6 pr-0 d-none">
                                        <div className="form-group dk-Candinate-fromGroup  d-flex">
                                          <label className="">Job Title</label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={jobTitle}
                                            readOnly
                                          />
                                        </div>
                                      </div>

                                      {/* <div className="col-md-6 pr-0">
                                                <div className="form-group dk-Candinate-fromGroup d-flex">
                                                    <label className="">Job ID</label>
                                                    <input type="text" placeholder="Fill Job ID" className="form-control input-form-control-job" value="" readOnly="true" style={{ cursor: "pointer", backgroundColor: "#e9ecef ! important" }} />
                                                </div>
                                            </div> */}

                                      <div className="col-md-6 pr-0">
                                        <div className="form-group dk-Candinate-fromGroup d-flex">
                                          <label className="">Company1</label>

                                          {!companyFilterText ? (
                                            <Dropdown className="dkg-company-selectDropdown">
                                              <Dropdown.Toggle
                                                variant=""
                                                className="dkg-company-selectDropdown-toggle"
                                              >
                                                Select …..
                                              </Dropdown.Toggle>
                                              <Dropdown.Menu className="dkg-company-selectDropdown-Menu">
                                                {companyCount.map((data, index) => (
                                                  <Dropdown.Item
                                                    href="#"
                                                    key={index + 1}
                                                    data-type="company"
                                                    data-value={data.company}
                                                    onClick={selectFilter}
                                                    className="dkg-company-selectDropdown-Item"
                                                  >
                                                    {data.company}{" "}
                                                    <span
                                                      style={{
                                                        background: "none",
                                                        border: "0",
                                                        float: "right",
                                                      }}
                                                    >
                                                      {data.count}
                                                    </span>
                                                  </Dropdown.Item>
                                                ))}
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          ) : (
                                            <div
                                              className="dk-dbsuMM-filterHeader"
                                              style={{
                                                width: "100%",
                                                background: "#fff",
                                                border: "1px solid #ced4da",
                                                fontSize: "12px",
                                                paddingLeft: "4px",
                                                lineHeight: "23px",
                                              }}
                                            >
                                              {companyFilterText}
                                              <span
                                                style={{
                                                  float: "right",
                                                  cursor: "pointer",
                                                  border: "0px",
                                                  backgroundColor: "#fff",
                                                }}
                                              >
                                                <i
                                                  className="fa fa-times-circle  ml-2"
                                                  data-type="company"
                                                  onClick={removeTag}
                                                ></i>
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-md-6 ">
                                        <div className="form-group dk-Candinate-fromGroup d-flex">
                                          <label className="">Language</label>

                                          {!languageFilterText ? (
                                            <Dropdown className="dkg-company-selectDropdown">
                                              <Dropdown.Toggle
                                                variant=""
                                                className="dkg-company-selectDropdown-toggle"
                                              >
                                                Select …..
                                              </Dropdown.Toggle>
                                              <Dropdown.Menu className="dkg-company-selectDropdown-Menu">
                                                {languageCount.map((data, index) => (
                                                  <Dropdown.Item
                                                    href="#"
                                                    key={index + 1}
                                                    data-type="lang"
                                                    data-value={data.lang}
                                                    onClick={selectFilter}
                                                    className="dkg-company-selectDropdown-Item"
                                                  >
                                                    {data.lang}
                                                    <span
                                                      style={{
                                                        background: "none",
                                                        border: "0",
                                                        float: "right",
                                                      }}
                                                    >
                                                      {data.count}
                                                    </span>
                                                  </Dropdown.Item>
                                                ))}
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          ) : (
                                            <div
                                              className="dk-dbsuMM-filterHeader"
                                              style={{
                                                width: "100%",
                                                background: "#fff",
                                                fontSize: "12px",
                                                border: "1px solid #ced4da",
                                                paddingLeft: "4px",
                                                lineHeight: "23px",
                                              }}
                                            >
                                              {languageFilterText}
                                              <span
                                                style={{
                                                  float: "right",
                                                  border: "0px",
                                                  cursor: "pointer",
                                                  backgroundColor: "#fff",
                                                }}
                                              >
                                                <i
                                                  className="fa fa-times-circle  ml-2"
                                                  data-type="lang"
                                                  onClick={removeTag}
                                                ></i>
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-md-6 pr-0 d-none">
                                        <div className="form-group dk-Candinate-fromGroup d-flex">
                                          <label className="">Company</label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={jobCompany}
                                            readOnly
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6 d-none">
                                        <div className="form-group dk-Candinate-fromGroup d-flex">
                                          <label className="">Company ID</label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={jobCompanyId}
                                            readOnly
                                          />
                                        </div>
                                      </div>
                                      {/* <div className="col-md-6 mt-4 pr-0">
                                                <div className="form-group dk-Candinate-fromGroup d-flex">
                                                    <label className="">Job ID</label>
                                                    <input type="text" placeholder="Fill Job ID"
                                                        onClick={() => { handleShow(); onfucuse() }}
                                                        className="form-control input-form-control-job dkg-cand-input-jobid" value={oldJobId} readOnly="true" style={{ cursor: "pointer", backgroundColor: "#e9ecef ! important" }} />
                                                </div>
                                                <Modal show={isJobIDShow} onHide={handleClose} className='dkg-canprof-modal-quz21' centered >
                                                    <Modal.Body className='dkg-canprof-modal-body-quz21'>
                                                        <div className="d-flex">
                                                            <div className="form-group">
                                                                <label for="">Current Job ID</label>
                                                                <label for="">
                                                                    <input type="text" className="form-control newValue" value={oldJobId} readOnly="true" />
                                                                </label>
                                                            </div>
                                                            <div className="form-group">
                                                                <label for="">New Job ID</label>
                                                                <label for="">
                                                                    <input type="text" id="textboxID" className="form-control" data-id={id} data-key="jobId" data-value={jobId}
                                                                        value={popupJobId}
                                                                        onChange={
                                                                            (e) => {
                                                                                setJobId(e.target.value)
                                                                                setPopupJobId(e.target.value)
                                                                            }
                                                                        }

                                                                    />
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex justify-content-center">
                                                            <button className="dk-saveBtn" onClick={(e) => updateJobCandidate(id)}>Save</button>
                                                        </div>
                                                    </Modal.Body>
                                                </Modal>
                                            </div> */}
                                      <div className="col-md-12 mt-4">
                                        <div className="form-group dk-Candinate-fromGroup dkg-jobtitle-label d-flex">
                                          <label className="">Job Title</label>
                                          <div className="dkg-profile-jobtitle-dropCon">
                                            <Dropdown className="dkg-jobtitle-selectDropdown">
                                              <Dropdown.Toggle
                                                variant=""
                                                className="dkg-jobtitle-selectDropdown-toggle"
                                              >
                                                {jobTitle}
                                              </Dropdown.Toggle>
                                              <Dropdown.Menu className="dkg-jobtitle-selectDropdown-Menu dkg-cp-jobtitleMenu">
                                                {titleCount.map((data, index) => (
                                                  <Dropdown.Item
                                                    href="#"
                                                    key={index + 1}
                                                    data-type="name"
                                                    data-value={data.name}
                                                    className="dkg-jobtitle-selectDropdown-Item"
                                                    onClick={(e) =>
                                                      updateJobCandidate1(id, data.job_id)
                                                    }
                                                  >
                                                    {data.job_id}: {data.company} - {data.lang}{" "}
                                                    {data.project !== "-"
                                                      ? " - " + data.project
                                                      : ""}{" "}
                                                    - {data.role}
                                                  </Dropdown.Item>
                                                ))}
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-6 pr-0">
                                        <div className="form-group dk-Candinate-fromGroup d-flex">
                                          <label className="">Job ID</label>
                                          {oldJobId === 0 ? (
                                            <input
                                              type="text"
                                              placeholder="Fill Job ID"
                                              onClick={() => {
                                                handleShow();
                                                onfucuse();
                                              }}
                                              className="form-control input-form-control-job zeroOldJobId"
                                              value={oldJobId}
                                              readOnly="true"
                                              style={{
                                                cursor: "pointer",
                                                backgroundColor: "#e9ecef ! important",
                                              }}
                                            />
                                          ) : (
                                            <input
                                              type="text"
                                              placeholder="Fill Job ID"
                                              onClick={() => {
                                                handleShow();
                                                onfucuse();
                                              }}
                                              className="form-control input-form-control-job"
                                              value={oldJobId}
                                              readOnly="true"
                                              style={{
                                                cursor: "pointer",
                                                backgroundColor: "#e9ecef ! important",
                                              }}
                                            />
                                          )}
                                        </div>
                                        <Modal
                                          show={isJobIDShow}
                                          onHide={handleClose}
                                          className="dkg-canprof-modal-quz21"
                                          centered
                                        >
                                          <Modal.Body className="dkg-canprof-modal-body-quz21">
                                            <div className="d-flex">
                                              <div className="form-group">
                                                <label for="">Current Job ID</label>
                                                <label for="">
                                                  <input
                                                    type="text"
                                                    className="form-control newValue"
                                                    value={oldJobId}
                                                    readOnly="true"
                                                  />
                                                </label>
                                              </div>
                                              <div className="form-group">
                                                <label for="">New Job ID</label>
                                                <label for="">
                                                  <input
                                                    type="text"
                                                    id="textboxID1"
                                                    className="form-control"
                                                    data-id={id}
                                                    data-key="jobId"
                                                    data-value={jobId}
                                                    value={popupJobId}
                                                    onChange={(e) => {
                                                      setJobId(e.target.value);
                                                      setPopupJobId(e.target.value);
                                                    }}
                                                  />
                                                </label>
                                              </div>
                                            </div>
                                            <div className="d-flex justify-content-center">
                                              <button
                                                className="dk-saveBtn"
                                                onClick={(e) => updateJobCandidate(id)}
                                              >
                                                Save
                                              </button>
                                            </div>
                                          </Modal.Body>
                                        </Modal>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group dk-Candinate-fromGroup d-flex">
                                          <label className="">Total Jobs</label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={jobNo}
                                            readOnly
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6 mb-0 pr-0">
                                        <div className="form-group dk-Candinate-fromGroup d-flex">
                                          <label className="">Company</label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={jobCompany}
                                            readOnly
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6 mb-0">
                                        <div className="form-group dk-Candinate-fromGroup d-flex">
                                          <label className="">City/Town</label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={jobCountry}
                                            readOnly
                                          />
                                        </div>
                                      </div>

                                      <div className="col-md-6 pr-0">
                                        <div className="form-group dk-Candinate-fromGroup d-flex">
                                          <label className="">Role Type</label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={jobRole}
                                            readOnly
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group dk-Candinate-fromGroup d-flex">
                                          <label className="">Project</label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={jobProject}
                                            readOnly
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6 pr-0">
                                        <div className="form-group dk-Candinate-fromGroup d-flex mb-0">
                                          <label className="">Language</label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={jobLanguage}
                                            readOnly
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group dk-Candinate-fromGroup d-flex dk-dateIcon mb-0">
                                          <label className="">Start Date</label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={jobStart}
                                            readOnly
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="dk-candidateTabPane">
                            <CandTabContentSummary
                              data={profileDetails}
                              updateProfile={updateProfile}
                              updateDates={updateDates}
                              updateEditor={updateEditor}
                              tabId={tabId}
                              changeColorType={changeColorType}
                            />
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="emails-logs">
                          <div className="dk-candidateTabPane">
                            <EmailLogs candidateId={candidateId} />
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="verify-email">
                          <div className="dk-candidateTabPane">
                            <VerifyEmail firstEmail={email1} secondEmail={email2} candidateId={candidateId} />
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="workflow">
                          <div className="dk-candidateTabPane">
                            <CandTabContentOverview />
                            <CandExperience />
                            <CandQualification />
                            <CandOtherInfo />
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="documents">
                          <div className="dk-candidateTabPane">
                            <Documents
                              candidateId={candidateId}
                              tabId={tabId}
                            />
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="first-screening">
                          {
                            (firstScrean === true) ? <div className="dk-candidateTabPane">
                              {
                                profileDetails.screanningDetails != undefined || profileDetails.screanningDetails != null ? <FirstScreening candidateId={canid} screaningData={profileDetails} /> : <FirstScreening candidateId={canid} screaningData={profileDetails} />
                              }

                            </div>
                              : null
                          }

                        </Tab.Pane>

                        <Tab.Pane eventKey="second-screening">
                          {
                            (secondScrean === true) ? <div className="dk-candidateTabPane">
                              {
                                profileDetails.secondScrening != undefined ? <SecondScreening candidateId={canid} secondScreaning={profileDetails} /> : null
                              }
                            </div>
                              : null
                          }

                        </Tab.Pane>
                        <Tab.Pane eventKey="matching_jobs">
                          {
                            (matchedJobs === true) ? <div className="dk-candidateTabPane">
                              <MatchingJobs />
                            </div> : null
                          }

                        </Tab.Pane>
                        <Tab.Pane eventKey="profile-referrals">
                          <Referrals />
                        </Tab.Pane>
                        <Tab.Pane eventKey="profile-dkgcv">
                        </Tab.Pane>
                        <Tab.Pane eventKey="profile-desiredjob">
                          <DesiredTab />
                        </Tab.Pane>
                        <Tab.Pane eventKey="event-logs">
                          <div className="dk-candidateTabPane">
                            <EventLog refTable="candidates"
                              refId={candidateId}
                              count={countComment}
                              title="CASE COMMENTS" />
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="profile-appications">
                          <div className="dk-candidateTabPane">
                            {/* <CaseStatus
                              data={profileDetails}
                              updateProfile={updateProfile}
                              updateDates={updateDates}
                              tabId={tabId}
                            /> */}
                            <Appication canId={candidateId} />
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="account-logs">
                        </Tab.Pane>
                        <Tab.Pane eventKey="profile-myservices">
                          <MyServices />
                        </Tab.Pane>
                        <Tab.Pane eventKey="emails-eventkey">
                          <CandidateMailbox candidateId={id} role={userInfo.role} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="tech_skills">
                          <div className="dk-candidateTabPane">
                            <TechnicalSkillsModal />
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="profile_skills">
                          <div className="dk-candidateTabPane">
                            <ProfileSkillsModal />
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </div>
                  </div>
                </Tab.Container>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CandidateProfile;
