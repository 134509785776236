import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { Route, Link } from "react-router-dom";
import Submenu from '../../subMenu/DatatableSubmenu';

const FullDatabaseMenu = () => {
    const dispatch = useDispatch();
    const { isLoading, database2021count, database2020count, database2019count, database2018count, database2017count, database2016count } = useSelector(state => state.candidates);
    const [data2021, setData2021] = useState([]);
    const [data2020, setData2020] = useState([]);
    const [data2019, setData2019] = useState([]);
    const [data2018, setData2018] = useState([]);
    const [data2017, setData2017] = useState([]);
    const [data2016, setData2016] = useState([]);

    useEffect(() => {
        const fetchList = async () => {
            try {
                // await dispatch(candidate2021DatabaseCount())
                // await dispatch(candidate2020DatabaseCount())
                // await dispatch(candidate2019DatabaseCount())
                // await dispatch(candidate2018DatabaseCount())
                // await dispatch(candidate2017DatabaseCount())
                // await dispatch(candidate2016DatabaseCount())
            } catch (err) {
                console.log(err)
            }
        }
        fetchList();
    }, [dispatch])

    useEffect(() => {
        if (!isLoading) {
            setTimeout(() => {
                // setData2021(database2021count[0].count)
                // setData2020(database2020count[0].count)
                // setData2019(database2019count[0].count)
                // setData2018(database2018count[0].count)
                // setData2017(database2017count[0].count)
                // setData2016(database2016count[0].count)
            }, 200)
        }


    }, [isLoading, database2021count, database2020count, database2019count, database2018count, database2017count, database2016count])

    const pathname = window.location.pathname;
    const segment2 = pathname.split("/")[2];

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK MasterBox";
    }, [pathname]);

    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-datatableNavbar">
                <Route>
                    <ul>
                        {/* <li><Link to="/full-database/summary" className={`${(segment2 === 'summary') ? 'white-active' : ''}`}>Dashboard </Link></li> */}
                        <li><Link to="/full-database/search-cv" className={`${(segment2 === 'search-cv') ? 'active' : ''}`}>Summary</Link></li>
                        <li><Link to="/full-database/data-table" className={`${(segment2 === 'data-table') ? 'white-active' : ''}`}>Data Table<span className='tweentyTwentyOne'>{data2021}</span></Link></li>
                        <li><Link to="/full-database/twenty-five" className={`${(segment2 === 'twenty-five') ? 'white-active' : ''}`}>Full DB<span className='tweentyTwentyOne'>0</span></Link></li>
                        {/* <li><Link to="/full-database/twenty-four" className={`${(segment2 === 'twenty-four') ? 'white-active' : ''}`}>2024<span className='tweentyTwentyOne'>0</span></Link></li>
                        <li><Link to="/full-database/twenty-three" className={`${(segment2 === 'twenty-three') ? 'white-active' : ''}`}>2023<span className='tweentyTwentyOne'>0</span></Link></li>
                        <li><Link to="/full-database/twenty-two" className={`${(segment2 === 'twenty-two') ? 'white-active' : ''}`}>2022<span className='tweentyTwentyOne'>0</span></Link></li>
                        <li><Link to="/full-database/twenty-one" className={`${(segment2 === 'twenty-one') ? 'white-active' : ''}`}>2021<span className='tweentyTwentyOne'>0</span></Link></li>
                        <li><Link to="/full-database/twenty-table" className={`${(segment2 === 'twenty-table') ? 'white-active' : ''}`}>2020<span className='tweentyTwentyOne'>0</span></Link></li>
                        <li><Link to="/full-database/twenty-nineteen" className={`${(segment2 === 'twenty-nineteen') ? 'white-active' : ''}`}>2019<span className='tweentyTwentyOne'>0</span></Link></li>
                        <li><Link to="/full-database/twenty-eighteen" className={`${(segment2 === 'twenty-eighteen') ? 'white-active' : ''}`}>2018<span className='tweentyTwentyOne'>0</span></Link></li>
                        <li><Link to="/full-database/twenty-seventeen" className={`${(segment2 === 'twenty-seventeen') ? 'white-active' : ''}`}>2017<span className='tweentyTwentyOne'>0</span></Link></li>
                        <li><Link to="/full-database/twenty-sixteen" className={`${(segment2 === 'twenty-sixteen') ? 'white-active' : ''}`}>2016<span className='tweentyTwentyOne'>0</span></Link></li>
                        */}
                    </ul> 
                </Route>
            </div>
            <Submenu path={pathname} />
        </React.Fragment>
    );
}

export default FullDatabaseMenu;