import React, { useEffect, useState, useMemo, lazy, Suspense } from "react";
import { FaFilter, FaSync } from "react-icons/fa";
import Dropdown from "react-bootstrap/Dropdown";
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  usePagination,
  useAsyncDebounce,
} from "react-table";
import { useDispatch, useSelector } from "react-redux";
import "./bankingcustom.scss";
import {
  clearState,
  getBankingList,
  deleteFun,
  moveRecordsToPaid,
  updateByKey,
  taskDetails,
  fetchCaseStatus,
  fetchCaseProgress,
  fetchCaseStage,
} from "../../../../slice/accounting/accountBanking";
import { ButtonGroup, Button, DropdownButton } from "react-bootstrap";
import TableLoader from "../../../ui/tableLoader";
import MonthFilterDropdown from "../../../ui/monthFilter/monthsFilter";
import $ from "jquery";
import DeleteModal from "./modal/delete/DeleteRow";
import MonthBGColour from "../../../ui/monthBgColor/monthbg";
//import TypeDropdown from "./TypeDropdown";
import TypesFilter from "./tableFilters/typefilter/TypeFilter";
import { showSuccess } from "../../../../slice/utils/message/messageSlice";
import CommentModal from "../../../utils/comment";
import CategoryyFilter from "./tableFilters/statusfilter";
import AdvancedFilter from "./modal/SearchBanking";
import UpdateModal from "./modal/UpdateModal";
import AddNew from "./modal/AddNewInvoice";
//import AddNew from "./modal/AddNewClientsInvoice";
import EditDetails from "./modal/EditDetails";
import TypeValue from "../../../pages/dkglobalfinance/financebanking/typeValue";
import { SYSTEM_CONSTANTS } from "../../../../constants";

import moment from "moment-timezone";
import Checkbox from "../../../ui/checkbox/radioBtn";
const TableSuspence = lazy(() =>
  import("../../../ui/tableLoader/tableSuspence")
);

const useInstance = (instance) => {
  if (instance && instance.getInstanceCallback) {
    instance.getInstanceCallback(instance);
  }
};

function Table({
  columns,
  data,
  loading,
  getInstanceCallback,
  filter,
  updateMyData,
  monthsFilterId,
  typeFilterId,
  monthsId,
  typessId,
  caseTaskTypeId,
  servicesId,
  plateformId,
  vatNoId,
  paidOutId,
  paidInId,
  zoneId,
  businessId,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    //rows,
    page,
    prepareRow,
    //state,
    setFilter,
    // canPreviousPage,
    // canNextPage,
    // pageOptions,
    // pageCount,
    // gotoPage,
    // nextPage,
    // previousPage,
    // setPageSize,
    // preGlobalFilteredRows,
    // setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      updateMyData,
      getInstanceCallback,
      autoResetPage: false,
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetRowState: false,
      initialState: {
        pageIndex: 0,
        pageSize: 100000000000,
        hiddenColumns: ["paidOut_status", "paidIn_status"],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    (hooks) => hooks.useInstance.push(useInstance)
  );

  useEffect(() => {
    if (caseTaskTypeId === "0") {
      setTimeout(() => {
        setFilter("category");
      }, 200);
    } else {
      setFilterType("category");
      doFilter(caseTaskTypeId);
    }
  }, [caseTaskTypeId]);

  useEffect(() => {
    if (zoneId === "0") {
      setTimeout(() => {
        setFilter("zone");
      }, 200);
    } else {
      setFilterType("zone");
      doFilter(zoneId);
    }
  }, [zoneId]);

  useEffect(() => {
    if (paidInId === "0") {
      setTimeout(() => {
        setFilter("paidIn_status");
      }, 200);
    } else {
      setFilterType("paidIn_status");
      doFilter(paidInId);
    }
  }, [paidInId]);
  useEffect(() => {
    if (paidOutId === "0") {
      setTimeout(() => {
        setFilter("paidOut_status");
      }, 200);
    } else {
      setFilterType("paidOut_status");
      doFilter(paidOutId);
    }
  }, [paidOutId]);

  useEffect(() => {
    if (businessId === "0") {
      setTimeout(() => {
        setFilter("business");
      }, 200);
    } else {
      setFilterType("business");
      doFilter(businessId);
    }
  }, [businessId]);

  useEffect(() => {
    if (vatNoId === "0") {
      setTimeout(() => {
        setFilter("vatNo");
      }, 200);
    } else {
      setFilterType("vatNo");
      doFilter(vatNoId);
    }
  }, [vatNoId]);

  useEffect(() => {
    if (servicesId === "0") {
      setTimeout(() => {
        setFilter("services");
      }, 200);
    } else {
      if (servicesId == "Blank") {
        setFilterType("services");
        doFilter("");
      } else {
        setFilterType("services");
        doFilter(servicesId);
      }
    }
  }, [servicesId]);
  useEffect(() => {
    if (typessId === "0") {
      setTimeout(() => {
        setFilter("accountType");
      }, 200);
    } else {
      setFilterType("accountType");
      doFilter(typessId);
    }
  }, [typessId]);
  useEffect(() => {
    if (monthsId === "0") {
      setTimeout(() => {
        setFilter("month");
      }, 200);
    } else {
      setFilterType("month");
      doFilter(monthsId);
    }
  }, [monthsId]);
  useEffect(() => {
    if (plateformId === "0") {
      setTimeout(() => {
        setFilter("plateform");
      }, 200);
    } else {
      //doFilter(plateformId);
      // setFilterType('plateform')
      if (plateformId == "Blank") {
        setFilterType("plateform");
        doFilter("");
      } else {
        setFilterType("plateform");
        doFilter(plateformId);
      }
    }
  }, [plateformId]);

  useEffect(() => {
    if (typeFilterId === "0") {
      setTimeout(() => {
        setFilter("accountType", "");
      }, 200);
    } else {
      setFilterType("accountType");
      doFilter(typeFilterId);
    }
  }, [typeFilterId]);

  useEffect(() => {
    if (monthsFilterId === "0") {
      setTimeout(() => {
        setFilter("month", "");
      }, 200);
    } else {
      setFilterType("month");
      doFilter(monthsFilterId);
    }
  }, [monthsFilterId]);

  // const handlePageChange = (event, value) => {
  //     gotoPage(Number(value) - 1);
  // };

  const [filterType, setFilterType] = useState("");

  const doFilter = React.useCallback(
    useAsyncDebounce((id) => {
      setFilter(filterType, id);
    }, 200),
    [filterType]
  );

  return (
    <React.Fragment>
      <table
        className="table table-bordered dkg-acc-invoice-table"
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return loading ? (
              [...Array(10)].map((e, i) => (
                <tr>
                  <TableLoader colSpan="14" />
                </tr>
              ))
            ) : (
              <Suspense fallback={<TableSuspence colSpan="14" />}>
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps([
                        {
                          className: cell.column.className, // pay attention to this
                          style: cell.column.style,
                          // set here your other custom props
                        },
                      ])}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              </Suspense>
            );
          })}
        </tbody>
        <tfoot>
          {footerGroups.map((group) => (
            <tr {...group.getFooterGroupProps()}>
              {group.headers.map((column) => (
                <td {...column.getFooterProps()} className="dkg-suMM">
                  {column.render("Footer")}
                </td>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
    </React.Fragment>
  );
}

function debounce(func, wait, immediate) {
  var timeout;
  return (...args) => {
    var context = this;
    var later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isClearAll,
  restClearAllFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState("");

  const onChangeSearchTitle = (e) => {
    const { value: nextValue } = e.target;
    setValue(e.target.value);
    debouncedSave(nextValue);
  };

  const debouncedSave = React.useCallback(
    debounce((nextValue) => {
      setGlobalFilter(nextValue);
    }, 1000)
  );

  const clearSearch = () => {
    setValue("");
    setTimeout(() => {
      setGlobalFilter(undefined);
    }, 200);
  };

  useEffect(() => {
    if (isClearAll) {
      clearSearch();
      restClearAllFilter();
    }
  }, [isClearAll]);

  return (
    <React.Fragment>
      <input
        value={value}
        className="form-control"
        onChange={onChangeSearchTitle}
        placeholder={`Search ...`}
      />
      {value !== undefined && value.length > 0 ? (
        <div className="input-group-append">
          <button className="" type="button" onClick={clearSearch}>
            <i className="fa fa-times"></i>
          </button>
        </div>
      ) : (
        <div className="input-group-append">
          <button className="" type="button">
            <i className="fa fa-search"></i>
          </button>
        </div>
      )}
    </React.Fragment>
  );
}

const BankingTwentyFive = () => {
  const dispatch = useDispatch();
  const {
    isLoading,
    bankingBookList,
    isUpdate,
    message,
    isTaskUpdate,
    isTaskInserted,
    isTaskDelete,
    taskTypeList,
    iscommentCountUpdate,
  } = useSelector((state) => state.bankingBooks);

  const [list, setList] = useState([]);
  const [data, setData] = useState([]);
  const [rowId, setRowId] = useState(0);
  const [dataRowId, setDataRowId] = useState(0);

  const lastSegment = window.location.href.split("/").pop(); //for decode use atob("zyz")

  useEffect(() => {
    document.title = "Accounting Banking | Dk Global CRM";
  }, []);

  useEffect(() => {
    const fetchList = async () => {
      try {
        await dispatch(getBankingList({ year: atob(lastSegment) }));
        await dispatch(
          fetchCaseStatus({
            id: SYSTEM_CONSTANTS.INVOICE_SALES_BOOK.caseStatusID,
          })
        );
        await dispatch(
          fetchCaseProgress({
            id: SYSTEM_CONSTANTS.INVOICE_SALES_BOOK.caseProgressID,
          })
        );
        await dispatch(
          fetchCaseStage({
            id: SYSTEM_CONSTANTS.INVOICE_SALES_BOOK.caseStageID,
          })
        );
      } catch (err) {
        console.log(err);
      }
    };
    fetchList();
  }, [dispatch]);

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        setData(bankingBookList);
      }, 200);
    }
    if (iscommentCountUpdate) {
      dispatch(clearState());
      setData(bankingBookList);
    }
    if (isUpdate) {
      dispatch(clearState());
      setData(bankingBookList);
      setIsActive(false);
      SetshowEdit(false);
    }
  }, [isLoading, isUpdate, isTaskUpdate, iscommentCountUpdate, dispatch]);

  useEffect(() => {
    if (isTaskDelete) {
      setLoading(false);
      setSuccess(true);
      dispatch(clearState());
      dispatch(getBankingList({ year: atob(lastSegment) }));
      dispatch(showSuccess({ msg: "Record deleted successfully" }));
    }
  }, [isTaskDelete]);

  // useEffect(() => {
  //     if (isTaskUpdate) {
  //         setIsActive(false)
  //         dispatch(clearState())
  //         //dispatch(getBankingList({year:atob(lastSegment)}))
  //         dispatch(showSuccess({ msg: 'Record updated successfully!' }))
  //     }
  // }, [isTaskUpdate])

  const [checkBtnTxt, setCheckBtnTxt] = useState("Check All");
  const [countCheck, setCountCheck] = useState(0);
  const [isActive, setIsActive] = useState(false);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const checkActiveExtraDiv = async (isShowExitra, checkedBoxCount) => {
    if (isShowExitra) {
      setIsActive(true);
      setCountCheck(checkedBoxCount);
    } else {
      setIsActive(false);
      setCountCheck(checkedBoxCount);
    }
  };

  const checkExtraDivBtnTxt = (btnTxt) => {
    setCheckBtnTxt(btnTxt);
  };

  const handleToggle = () => {
    $(".chkCandidate").prop("checked", false);
    setIsActive(false);
  };

  const deleteItemBulk = async () => {
    var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
    if (checkedBoxCount == 0) {
    } else {
      var canIds = [];
      $.each($(".chkCandidate:checked"), function () {
        canIds.push($(this).val());
      });
      setLoading(true);
      setSuccess(false);
      await dispatch(deleteFun({ id: canIds }));
      $(".chkCandidate").prop("checked", false);
      setIsActive(false);
    }
  };

  const [dataMoveTo, SetDataMoveTo] = useState("");
  const [showView, SetShowView] = useState(false);
  const [showEdit, SetshowEdit] = useState(false);

  // const callViewPopup = async (data) => {
  //     await dispatch(taskDetails({ id: data }))
  //     SetShowView(true)
  // }
  const editFunction = async (e) => {
    await dispatch(taskDetails({ id: dataRowId }));
    SetshowEdit(true);
  };
  const closeEditModal = () => {
    SetshowEdit(false);
  };

  //const [profileCanId, setProfileCanId] = useState(0);
  //const [isProfileModal, setIsProfileModal] = useState(false);

  // const handleCloseProfile = () => {
  //   setIsProfileModal(false);
  //   //SetshowEdit(false)
  // };
  // const veiwHandleClose = () => {
  //   SetShowView(false);
  // };
  // const handleClose = () => {
  //   SetshowEdit(false);
  // };

  const [isShowSearch, setShowSearchModal] = useState(false);

  const showSearch = () => {
    setShowSearchModal(true);
  };

  const SearchClose = () => {
    setShowSearchModal(false);
  };

  const [isShowAddNew, setShowAddNew] = useState(false);

  const showAddNew = () => {
    setShowAddNew(true);
  };

  const SearchAddNew = () => {
    setShowAddNew(false);
  };

  const handleDateChange = async (e, idd, column) => {
    await dispatch(
      updateByKey({
        id: idd,
        key: column,
        value: moment(e).format("DD MMM YY"),
      })
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "SNo.",
        accessor: "id",
        Cell: ({ cell }) => {
          return (
            <span className="dk-checkinputIconCon">
              {Number(cell.row.id) + 1}
              <div className="dk-checkinputIcon">
                <Checkbox
                  id={cell.row.original.id}
                  checkActiveExtraDiv={checkActiveExtraDiv}
                  checkExtraDivBtnTxt={checkExtraDivBtnTxt}
                  setDataRowId={setDataRowId}
                />
              </div>
            </span>
          );
        },
      },
      {
        Header: "TYPE",
        accessor: "accountType",
        Cell: ({ cell }) => {
          const onItemClick = async (id, columnId, value, img) => {
            updateMyData(id, columnId, value, img);
          };
          return (
            <React.Fragment>
              <Dropdown
                className="dkg-typecol-dropdown"
                id={`accountType${cell.row.original.id}`}
              >
                <Dropdown.Toggle
                  variant=""
                  className="dkg-typecol-dropdown-toggle"
                >
                  <TypeValue value={cell.row.original.accountType} />
                </Dropdown.Toggle>
                <Dropdown.Menu className="dkg-typecol-dropdown-menu">
                  <Dropdown.Item
                    onClick={() =>
                      onItemClick(
                        cell.row.original.id,
                        "accountType",
                        "Income",
                        "#3a9469"
                      )
                    }
                    href="#"
                    className="dkg-typecol-dropdown-item"
                    style={{ backgroundColor: "#3a9469", color: "#fff" }}
                  >
                    Income
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      onItemClick(
                        cell.row.original.id,
                        "accountType",
                        "Expense",
                        "#d91010"
                      )
                    }
                    href="#"
                    className="dkg-typecol-dropdown-item"
                    style={{ backgroundColor: "#d91010", color: "#fff" }}
                  >
                    Expense
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </React.Fragment>
          );
        },
      },
      {
        Header: "MONTH",
        accessor: "month",
        Cell: ({ cell }) => {
          return <MonthBGColour value={cell.row.original.month} />;
        },
      },
      {
        Header: "TRANSACTION",
        accessor: "transaction",
      },
      {
        Header: "PLATFORM",
        accessor: "plateform",
      },
      {
        Header: "CATEGORY",
        accessor: "category",
      },
      {
        Header: "SERVICES",
        accessor: "services",
      },
      {
        Header: "NAME OF BUSINESS/PERSON",
        accessor: "business",
      },
      {
        Header: "Comment",
        accessor: "commentCount",
        className: "pl-2 pr-2",
        Cell: ({ cell }) => {
          const commentPoupShow = (e) => {
            setRefId(cell.row.original.canId);
            setCommentCount(cell.row.original.commentCount);
            setCandidteStatus(cell.row.original.caseStatus);
            setCandidateProgess(cell.row.original.case_progress);
            setCandidateStage(cell.row.original.case_stage);
            setCommentPopupShow(true);
          };
          return (
            <>
              {cell.row.original.commentCount === 0 ? (
                <span
                  className="commentBoxCon zeroComment"
                  onClick={commentPoupShow}
                >
                  <span className="numCon zeroCommentCount">
                    {cell.row.original.commentCount}
                  </span>
                </span>
              ) : (
                <span className="commentBoxCon" onClick={commentPoupShow}>
                  <span className="numCon">
                    {cell.row.original.commentCount}
                  </span>
                </span>
              )}
            </>
          );
        },
      },
      {
        Header: "Zone",
        accessor: "zone",
        Cell: ({ cell }) => {
          const onItemClick = async (id, columnId, value, img) => {
            updateMyData(id, columnId, value, img);
          };
          return (
            <React.Fragment>
              <Dropdown
                className="dkg-typecol-dropdown"
                id={`zone${cell.row.original.id}`}
              >
                <Dropdown.Toggle
                  variant=""
                  className="dkg-typecol-dropdown-toggle"
                  style={{ color: "#333" }}
                >
                  {cell.row.original.zone}
                </Dropdown.Toggle>
                <Dropdown.Menu className="dkg-typecol-dropdown-menu">
                  <Dropdown.Item
                    onClick={() =>
                      onItemClick(
                        cell.row.original.id,
                        "zone",
                        "IRE",
                        "#3a9469"
                      )
                    }
                    href="#"
                    className="dkg-typecol-dropdown-item"
                    style={{ backgroundColor: "#f2f2f2", color: "#333" }}
                  >
                    IRE
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      onItemClick(cell.row.original.id, "zone", "EU", "#d91010")
                    }
                    href="#"
                    className="dkg-typecol-dropdown-item"
                    style={{ backgroundColor: "#f2f2f2", color: "#333" }}
                  >
                    EU
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      onItemClick(
                        cell.row.original.id,
                        "zone",
                        "NON-EU",
                        "#d91010"
                      )
                    }
                    href="#"
                    className="dkg-typecol-dropdown-item"
                    style={{ backgroundColor: "#f2f2f2", color: "#333" }}
                  >
                    NON-EU
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </React.Fragment>
          );
        },
      },
      {
        Header: "VAT",
        accessor: "vatNo",
        Cell: ({ cell }) => {
          return (
            <>
              <span
                onClick={() =>
                  openUpdateValuePop(
                    cell.row.original.id,
                    "vatNo",
                    cell.row.original.vatNo
                  )
                }
                className="cursor-p"
              >
                {cell.row.original.vatNo}
              </span>
            </>
          );
        },
        Footer: (info) => {
          const total = React.useMemo(
            () =>
              info.flatRows.reduce(
                (sum, row) => Number(row.original.vatNo) + sum,
                0
              ),
            [info.rows]
          );
          return (
            <>
              <span className="totalAmtPaymentTask">
                {total.toFixed(2) + " " + "€"}{" "}
              </span>
            </>
          );
        },
      },

      {
        Header: "PAID OUT €",
        accessor: "paidOut",
        Cell: ({ cell }) => {
          return (
            <>
              <span
                onClick={() =>
                  openUpdateValuePop(
                    cell.row.original.id,
                    "paidOut",
                    cell.row.original.paidOut
                  )
                }
                className="cursor-p"
              >
                {cell.row.original.paidOut}
              </span>
            </>
          );
        },
        Footer: (info) => {
          const total = React.useMemo(
            () =>
              info.flatRows.reduce(
                (sum, row) => Number(row.original.paidOut) + sum,
                0
              ),
            [info.rows]
          );
          return (
            <>
              <span className="totalAmtPaymentTask">
                {total.toFixed(2) + " " + "€"}{" "}
              </span>
            </>
          );
        },
      },
      {
        Header: "PAID IN €",
        accessor: "paidIn",
        Cell: ({ cell }) => {
          return (
            <>
              <span
                onClick={() =>
                  openUpdateValuePop(
                    cell.row.original.id,
                    "paidIn",
                    cell.row.original.paidIn
                  )
                }
                className="cursor-p"
              >
                {cell.row.original.paidIn}
              </span>
            </>
          );
        },
        Footer: (info) => {
          const total = React.useMemo(
            () =>
              info.flatRows.reduce(
                (sum, row) => Number(row.original.paidIn) + sum,
                0
              ),
            [info.rows]
          );
          return (
            <>
              <span className="totalAmtPaymentTask">
                {total.toFixed(2) + " " + "€"}{" "}
              </span>
            </>
          );
        },
      },
      {
        Header: "BALANCE €",
        accessor: "ballance",
        Cell: ({ cell }) => {
          return (
            <>
              <span
                onClick={() =>
                  openUpdateValuePop(
                    cell.row.original.id,
                    "ballance",
                    cell.row.original.ballance
                  )
                }
                className="cursor-p"
              >
                {cell.row.original.ballance}
              </span>
            </>
          );
        },
        Footer: (info) => {
          const total = React.useMemo(
            () =>
              info.flatRows.reduce(
                (sum, row) => Number(row.original.ballance) + sum,
                0
              ),
            [info.rows]
          );
          return (
            <>
              <span className="totalAmtPaymentTask">
                {total.toFixed(2) + " " + "€"}{" "}
              </span>
            </>
          );
        },
      },
      {
        Header: "paidOut_status",
        accessor: "paidOut_status",
      },
      {
        Header: "paidIn_status",
        accessor: "paidIn_status",
      },
    ],
    []
  );

  const [instanceData, setInstanceData] = useState("0");
  const reactTableInstance = (instance) => {
    if (instance) {
      setList(instance.flatRows);
      setInstanceData(instance);
    }
  };

  const updateMyData = async (id, columnId, value, img) => {
    switch (columnId) {
      case "status":
        $(`#caseStatus${id} button .caseDiv`)
          .html(value)
          .css("background-color", img);
        break;

      case "caseStatus":
        $(`#caseStatus${id} button .caseDiv`)
          .html(value)
          .css("background-color", img);

        break;

      case "accountType":
        $(`#accountType${id} button .caseDiv`).html(value);
        break;
      case "progress":
        $(`#progress${id} button .caseDiv`).html(value);
        break;

      case "caseStage":
        $(`#caseStage${id} button .caseDiv`).html(value);
        break;
      case "am_id":
        $(`#am_id${id} button .tooltipMain img`).attr(
          "src",
          process.env.REACT_APP_IMG_CLOUD_BASE_URL + img
        );
        break;

      case "rec_id":
        $(`#rec_id${id} button .tooltipMain img`).attr(
          "src",
          process.env.REACT_APP_IMG_CLOUD_BASE_URL + img
        );
        break;

      case "rec_admin":
        $(`#rec_admin${id} button .tooltipMain img`).attr(
          "src",
          process.env.REACT_APP_IMG_CLOUD_BASE_URL + img
        );
        break;
      default:
        break;
    }

    await dispatch(updateByKey({ id, key: columnId, value }));
  };

  const [isClearAll, setIsClearAll] = useState(false);
  const clearAllFilter = async () => {
    setFilterTags([]);
    setMonthsId("0");
    setTypeId("0");
    setServicesTypeId("0");
    setBusinessId("0");
    setVatNoId("0");
    setPaidInId("0");
    setZones("0");
    setPaidOutId("0");
    setPlateformId("0");
    setProjectId("0");
    setCaseStatusId("0");
    setMonthsFilterId("0");
    setTypeFilterId("0");
    setProgressId("0");
    setStageId("0");
    setCompanyId("0");
    setLanguageId("0");
    setRoleTypeId("0");
    setcategoryFilterDDLText("Category");
    setMonthsFilterText("Month");
    setTypeFilterText("Type");
    await dispatch(getBankingList({ year: atob(lastSegment) }));
  };

  const restClearAllFilter = async () => {
    setIsClearAll(false);
  };

  const [categoryFilterDDLText, setcategoryFilterDDLText] =
    useState("Category");
  const [caseTaskTypeId, setTaskCaseTypeId] = useState("0");

  const [filterTags, setFilterTags] = useState([]);
  const [taskStatusFilterText, setTaskStatusFilterText] = useState("CATEGORY");
  const [caseStatusFilterText, setCaseStatusFilterText] =
    useState("PAY STATUS");
  const [paymentTypeFilterText, setPaymentTypeFilterText] =
    useState("PAY TYPE");
  const [caseTypeId, setCaseTypeId] = useState("0");
  const [caseStatusId, setCaseStatusId] = useState("0");
  const [casePayTypeId, setCasePayTypeId] = useState("0");
  const [monthsFilterId, setMonthsFilterId] = useState("0");
  const [monthsFilterText, setMonthsFilterText] = useState("Month");
  const [typeFilterText, setTypeFilterText] = useState("Type");
  const [typeFilterId, setTypeFilterId] = useState("0");

  const selectStatusFilter = async (e) => {
    const type = e.target.dataset.type;
    const value = e.target.dataset.value;
    let i;

    switch (type) {
      case "caseStatus":
        i = filterTags.findIndex(function (x) {
          return x.type === "caseStatus";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setCaseTypeId(value);
        setTaskStatusFilterText(value);
        break;

      case "paymentType":
        i = filterTags.findIndex(function (x) {
          return x.type === "paymentType";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setCaseTypeId(value);
        setTaskStatusFilterText(value);
        break;
      case "payType":
        i = filterTags.findIndex(function (x) {
          return x.type === "payType";
        });

        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setCasePayTypeId(value);
        setPaymentTypeFilterText(value);
        break;
      case "taskType":
        setTaskCaseTypeId(value);
        setTaskTypeFilterText(value);
        break;
    }
  };

  const onSelectStatusFilter = (eventKey) => {
    setcategoryFilterDDLText(eventKey);
    setCaseStatusId(eventKey);
  };

  const onSelectTypeFilter = (eventKey) => {
    setTypeFilterText(eventKey);
    setTypeFilterId(eventKey);
  };
  const onSelectMonthFilter = (eventKey) => {
    setMonthsFilterText(eventKey);
    setMonthsFilterId(eventKey);
  };

  const [taskTypeFilterText, setTaskTypeFilterText] = useState("Category");

  const [monthsId, setMonthsId] = useState("0");
  const [typessId, setTypeId] = useState("0");
  const [servicesId, setServicesTypeId] = useState("0");
  const [paidInId, setPaidInId] = useState("0");
  const [zoneId, setZones] = useState("0");
  const [paidOutId, setPaidOutId] = useState("0");
  const [businessId, setBusinessId] = useState("0");
  const [vatNoId, setVatNoId] = useState("0");
  const [plateformId, setPlateformId] = useState("0");
  const [projectId, setProjectId] = useState("0");
  const [progressId, setProgressId] = useState("0");
  const [stageId, setStageId] = useState("0");
  const [companyId, setCompanyId] = useState("0");
  const [languageId, setLanguageId] = useState("0");
  const [roleTypeId, setRoleTypeId] = useState("0");

  const selectOtherFilter = async (e) => {
    const type = e.target.dataset.type;
    const value = e.target.dataset.value;

    let i;
    switch (type) {
      case "caseStatus":
        i = filterTags.findIndex(function (x) {
          return x.type === "caseStatus";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setCaseStatusId(value);
        setcategoryFilterDDLText(value);
        break;
      case "paidIn_status":
        i = filterTags.findIndex(function (x) {
          return x.type === "paidIn_status";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setPaidInId(value);
        break;

      case "zone":
        i = filterTags.findIndex(function (x) {
          return x.type === "zone";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setZones(value);
        break;
      case "paidOut_status":
        i = filterTags.findIndex(function (x) {
          return x.type === "paidOut_status";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setPaidOutId(value);
        break;
      case "business":
        i = filterTags.findIndex(function (x) {
          return x.type === "business";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setBusinessId(value);
        break;
      case "vatNo":
        i = filterTags.findIndex(function (x) {
          return x.type === "vatNo";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setVatNoId(value);
        break;
      case "accountType":
        i = filterTags.findIndex(function (x) {
          return x.type === "accountType";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setTypeId(value);
        break;
      case "month":
        i = filterTags.findIndex(function (x) {
          return x.type === "month";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setMonthsId(value);
        break;
      case "services":
        i = filterTags.findIndex(function (x) {
          return x.type === "services";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setServicesTypeId(value);
        break;
      case "plateform":
        i = filterTags.findIndex(function (x) {
          return x.type === "plateform";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setPlateformId(value);
        break;
      case "language":
        i = filterTags.findIndex(function (x) {
          return x.type === "language";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setLanguageId(value);
        break;
      case "roleType":
        i = filterTags.findIndex(function (x) {
          return x.type === "roleType";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setRoleTypeId(value);
        break;
      case "project":
        i = filterTags.findIndex(function (x) {
          return x.type === "project";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setProjectId(value);
        break;
      case "progress":
        i = filterTags.findIndex(function (x) {
          return x.type === "progress";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setProgressId(value);
        break;
      case "stage":
        i = filterTags.findIndex(function (x) {
          return x.type === "stage";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setStageId(value);
        break;
      case "company":
        i = filterTags.findIndex(function (x) {
          return x.type === "company";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setCompanyId(value);
        break;
      case "taskType":
        i = filterTags.findIndex(function (x) {
          return x.type === "taskType";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setTaskCaseTypeId(value);
        setTaskTypeFilterText(value);
        break;

      default:
        i = filterTags.findIndex(function (x) {
          return x.type === type;
        });
        if (i === -1) {
          filterTags.push({ type, value });
        }
        break;
    }
  };

  const removeTag = async (e) => {
    const type = e.target.dataset.type;
    setFilterTags(filterTags.filter((item) => item.type !== type));

    switch (type) {
      case "paymentType":
        setCaseTypeId("0");
        setTaskStatusFilterText("CATEGORY");
        break;

      case "progress":
        setProgressId("0");
        break;
      case "stage":
        setStageId("0");
        break;
      case "project":
        setProjectId("0");
        break;
      case "company":
        setCompanyId("0");
        break;
      case "language":
        setLanguageId("0");
        break;
      case "roleType":
        setRoleTypeId("0");
        break;

      case "status":
        setCaseStatusId("0");
        setCaseStatusFilterText("PAY STATUS");
        break;
      case "payType":
        setCasePayTypeId("0");
        setPaymentTypeFilterText("PAY TYPE");
        break;
      case "caseStatus":
        setCaseStatusId("0");
        setcategoryFilterDDLText("Status");
        break;
      case "month":
        setMonthsFilterId("0");
        setMonthsFilterText("Month");
        setMonthsId("0");
        break;
      case "type":
        setTypeFilterId("0");
        setTypeFilterText("Month");

        break;
      case "paidIn_status":
        setPaidInId("0");
        break;
      case "zone":
        setZones("0");
        break;
      case "paidOut_status":
        setPaidOutId("0");
        break;
      case "business":
        setBusinessId("0");
        break;
      case "vatNo":
        setVatNoId("0");
        break;
      case "services":
        setServicesTypeId("0");
        break;
      case "plateform":
        setPlateformId("0");
        break;

      case "taskType":
        setTaskCaseTypeId("0");
        setTaskTypeFilterText("Category");
        break;
      case "accountType":
        setTypeFilterId("0");
        setTypeFilterText("Type");
        setTypeId("0");
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (dataMoveTo !== "") {
      (async () => {
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        if (checkedBoxCount == 0) {
        } else {
          var canIds = [];
          $.each($(".chkCandidate:checked"), function () {
            canIds.push($(this).val());
          });
          await dispatch(
            moveRecordsToPaid({ id: dataRowId, status: "Fully Paid" })
          );
          $(".chkCandidate").prop("checked", false);
          setIsActive(false);
        }
      })();
    }
  }, [dataMoveTo]);

  const [isShowUpdate, setShowUpdate] = useState(false);
  const showUpdate = () => {
    setShowUpdate(true);
  };

  const SearchUpdate = () => {
    setShowUpdate(false);
  };

  const [columnKey, setColumnKey] = useState("");
  const [columnValue, setColumnValue] = useState("0");
  const [isUpdatePop, setIsUpdatePop] = useState(false);

  const openUpdateValuePop = (id, key, value) => {
    setRowId(id);
    setColumnKey(key);
    setColumnValue(value);
    setShowUpdate(true);
  };
  const hideUpdateValuePop = () => {
    setShowUpdate(false);
  };
  /******comment popup start**** */
  const [refTable, setRefTable] = useState("accounting_bankings");
  const [refId, setRefId] = useState(0);
  const [commentsCount, setCommentCount] = useState(0);
  const [title, setTitle] = useState("Banking Comments");
  const [newStatus, setNewStatus] = useState("");
  const [newProgess, setNewProgess] = useState("");
  const [newStage, setNewStage] = useState("");
  const [candidateStatus, setCandidteStatus] = useState("");
  const [candidateProgess, setCandidateProgess] = useState("");
  const [candidateStage, setCandidateStage] = useState("");
  const [commentPopupShow, setCommentPopupShow] = useState(false);
  /******comment popup end**** */
  const commentHandleClose = () => {
    setCommentPopupShow(false);
  };

  return (
    <>
      {commentPopupShow ? (
        <CommentModal
          refTable={refTable}
          refId={refId}
          count={commentsCount}
          title={title}
          commentHandleClose={commentHandleClose}
          candidateStatus={newStatus != "" ? newStatus : candidateStatus}
          candidateProgess={newProgess != "" ? newProgess : candidateProgess}
          candidateStage={newStage != "" ? newStage : candidateStage}
        />
      ) : null}
      {isShowSearch ? (
        <AdvancedFilter
          onHide={SearchClose}
          headerTitle="Search Banking & Filter"
          list={list}
          selectOtherFilter={selectOtherFilter}
        />
      ) : null}
      {isShowUpdate ? (
        <UpdateModal
          onHide={hideUpdateValuePop}
          rowId={rowId}
          columnKey={columnKey}
          value={columnValue}
        />
      ) : null}
      {isShowAddNew ? (
        <AddNew onHide={SearchAddNew} currentYear={atob(lastSegment)} />
      ) : null}
      {showEdit ? (
        <EditDetails onHide={closeEditModal} currentYear={atob(lastSegment)} />
      ) : null}

      <div className="dk-dkBankingYears-main">
        <div className="dk-bankingYearsHead-main">
          <div className="dk-bankingSearch-cols">
            <div className="d-flex">
              <div className="dk-search-input">
                {instanceData !== "0" ? (
                  <GlobalFilter
                    preGlobalFilteredRows={instanceData.preGlobalFilteredRows}
                    globalFilter={instanceData.state.globalFilter}
                    setGlobalFilter={instanceData.setGlobalFilter}
                    isClearAll={isClearAll}
                    restClearAllFilter={restClearAllFilter}
                  />
                ) : null}
              </div>
              <div className="dk-reloadBtn" onClick={showAddNew}>
                <button className="addMonthBtn">
                  <i className="fas fa-plus"></i>
                </button>
              </div>
              <div className="dk-reloadBtn" onClick={clearAllFilter}>
                <button className="addMonthBtn" onClick={clearAllFilter}>
                  <FaSync />
                </button>
              </div>
              <div className="dk-reloadBtn">
                <button className="addMonthBtn" onClick={showSearch}>
                  <FaFilter />
                </button>
              </div>
            </div>
          </div>
          <div className="dk-recwallet-centerCon">
            <div className="dk-filterTagsCon">
              {filterTags.length > 0 ? (
                filterTags.length > 0 &&
                filterTags.map((item, index) => (
                  <ButtonGroup
                    className="dk-filterbtnGrp mr-2"
                    key={`tagButton${index}`}
                  >
                    <Button className="dk-filterbtntext">{item.value}</Button>
                    <Button
                      className="dk-filterbtnicon"
                      data-type={item.type}
                      onClick={removeTag}
                    >
                      <i
                        className="fas fa-times"
                        data-type={item.type}
                        onClick={removeTag}
                      ></i>
                    </Button>
                  </ButtonGroup>
                ))
              ) : (
                <div className="dkg-pagetitle">
                  BANK STATEMENT - {atob(lastSegment)}
                </div>
              )}
            </div>
          </div>
          <div className="dk-sendoutyears-rightPanel-btn">
            <div className="mr-2 dkg-banking-categry-filter-234">
              <TypesFilter
                list={list}
                caseStatusFilterText={typeFilterText}
                onSelectStatusFilter={onSelectTypeFilter}
              />
              {typeFilterText !== "Type" ? (
                <i
                  className="fas fa-times-circle dk-lrftCross"
                  data-type="accountType"
                  onClick={removeTag}
                ></i>
              ) : null}
            </div>
            <div className="mr-2" style={{ position: "relative" }}>
              <MonthFilterDropdown
                list={list}
                caseStatusFilterText={monthsFilterText}
                onSelectStatusFilter={onSelectMonthFilter}
                tableType={"accounting_invoice_books"}
              />
              {monthsFilterText !== "Month" ? (
                <i
                  className="fas fa-times-circle dk-lrftCross"
                  data-type="month"
                  onClick={removeTag}
                ></i>
              ) : null}
            </div>
            <div className="dkg-banking-categry-filter-234">
              <CategoryyFilter
                list={list}
                taskTypeFilterText={taskTypeFilterText}
                selectStatusFilter={selectStatusFilter}
              />
              {taskTypeFilterText !== "Category" ? (
                <i
                  className="fas fa-times-circle crossUserFilter dk-lrftCross"
                  data-type="taskType"
                  onClick={removeTag}
                ></i>
              ) : null}
            </div>
          </div>
        </div>
        <div className="dk-bankingYearsTable-main">
          <div className="table-responsive">
            <Table
              data={data}
              columns={columns}
              getInstanceCallback={reactTableInstance}
              loading={isLoading}
              updateMyData={updateMyData}
              caseTypeId={caseTypeId}
              caseStatusId={caseStatusId}
              casePayTypeId={casePayTypeId}
              monthsFilterId={monthsFilterId}
              typeFilterId={typeFilterId}
              monthsId={monthsId}
              typessId={typessId}
              plateformId={plateformId}
              paidInId={paidInId}
              zoneId={zoneId}
              paidOutId={paidOutId}
              businessId={businessId}
              vatNoId={vatNoId}
              servicesId={servicesId}
              progressId={progressId}
              stageId={stageId}
              companyId={companyId}
              projectId={projectId}
              languageId={languageId}
              roleTypeId={roleTypeId}
              caseTaskTypeId={caseTaskTypeId}
            />
          </div>
        </div>
      </div>
      <div
        className={`batch-actions-menu-wrapper dk-action-popupMain ${
          isActive ? "activePopup" : ""
        }`}
        style={{ width: "auto" }}
      >
        <div className="num-of-actions_wrapper">
          <div className="num-of-actions">{countCheck}</div>
        </div>
        <div className="batch-actions-title-section pl-0">
          <div className="dk-rowSelected">
            <span>Row Selected</span>
          </div>
        </div>

        <div className="batch-actions-item dk-checkAll" onClick={editFunction}>
          <span onClick={editFunction}>
            <i className="far fa-edit"></i>
          </span>
          Edit
        </div>

        <DeleteModal
          loading={loading}
          success={success}
          deleteItem={deleteItemBulk}
          tableName="accounting_bankings"
        />
        <div
          className="batch-actions-delete-item CloseAll d-flex align-items-center justify-content-center"
          onClick={handleToggle}
        >
          <span>
            <i className="fas fa-times"></i>
          </span>
        </div>
      </div>
    </>
  );
};

export default BankingTwentyFive;
