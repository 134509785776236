import React, { useEffect, useState, useRef } from "react";
import { Route, Link } from "react-router-dom";
import "../responsiveMenu.scss";
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import './mainmenusubmenuc.scss';
import { counts, archivedCounts } from "../../../../../../../slice/task/taskSlice"
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

const DashboardSubmenu = (props) => {
    const dispatch = useDispatch();
    const { isSuccess, isMoved, isArchive, countsProgress, archivedDataCount } = useSelector(state => state.task);

    const classes = useStyles();
    const pathname = window.location.pathname;
    const segment2 = pathname.split("/")[2];
    const segment3 = pathname.split("/")[3];
    // console.log("Dashboard Departments :" + segment2);

    const [toggleMenu, setToggleMenu] = useState(false);
    useEffect(() => {
        setToggleMenu(false)
    }, [pathname])

    const ref = useRef(null);
    const { onClickOutside } = props;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside && onClickOutside();
                setToggleMenu(false)
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [onClickOutside]);

    useEffect(() => {
        const fetchList = async () => {
            try {
                await dispatch(counts({}))
            } catch (err) {
                console.log(err)
            }
        }
        fetchList();
    }, [])

    useEffect(() => {
        if (isSuccess) {
            const fetchList = async () => {
                try {
                    await dispatch(counts({}))

                } catch (err) {
                    console.log(err)
                }
            }
            fetchList();
        }
        if (isMoved) {
            const fetchList = async () => {
                try {
                    await dispatch(counts({}))
                } catch (err) {
                    console.log(err)
                }
            }
            fetchList();
        }
        if (isArchive) {
            const fetchList = async () => {
                try {
                    await dispatch(archivedCounts({}))
                } catch (err) {
                    console.log(err)
                }
            }
            fetchList();
        }
        dispatch(archivedCounts({}))
    }, [isSuccess, isMoved, isArchive])

    return (
        <React.Fragment>
            <div className="dk-subMenuMain dkg-mainModuleSubmenu res-sub-menu" ref={ref}>
                <IconButton aria-label="delete" className="res-sub-btn" onClick={() => setToggleMenu(!toggleMenu)}>
                    <i className="fas fa-bars"></i>
                </IconButton>
                <Route>
                    <ul className={`${toggleMenu ? "res-mobileNav show" : null}`}>
                        <li><Link to="/dashboard/plateform" className={`${(segment2 == 'plateform') ? 'active' : ''}`} >Home</Link></li>
                        <li><Link to="/dashboard/principles" className={`${(segment2 == 'principles') ? 'active' : ''}`}>CSF</Link></li>
                        <li><Link to="/dashboard/job-profile/dk-global" className={`${(segment2 == 'job-profile') ? 'active' : ''}`}>Rec Teams</Link></li>
                        <li><Link to="/dashboard/get-started" className={`${(segment2 == 'get-started') ? 'active' : ''}`}>Get Start</Link></li>
                        <li><Link to="/dashboard/check-in" className={(segment2 == 'check-in') ? 'active' : ''}>Check In</Link></li>
                        <li><Link to="/dashboard/moods" className={`${(segment2 == 'moods') ? 'active' : ''}`}>Blackbook</Link></li>
                        <li><Link to="/dashboard/checklist/dk-global" className={`${(segment2 == 'checklist') ? 'active' : ''}`}>Jobs Profile</Link></li>
                        {/* <li><Link to="/dashboard/work-models/overview" className={`${(segment2 == 'work-models') ? 'active' : ''}`}>Work Models</Link></li> */}
                        <li><Link to="/dashboard/my-profile" className={`${(segment2 == 'my-profile') ? 'active' : ''}`}>My Tasks</Link></li>
                        <li><Link to="/dashboard/workflow" className={`${(segment2 == 'workflow') ? 'active' : ''}`}>My Workload</Link></li>
                        <li><Link to="/dashboard/reports" className={`${(segment2 == 'reports') ? 'active' : ''}`}>Quick Training</Link></li>
                        {/* <li><Link to="/dashboard/requests/summary" className={`${(segment2 == 'requests') ? 'active' : ''}`}>Daily Tasks</Link></li> */}
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default DashboardSubmenu;
