import { SYSTEM_CONSTANTS } from "../../constants";
//import { FaCheck, FaTimes, FaQuestion } from "react-icons/fa";

export function indicatorColor(piType) {
    switch (piType) {
        case 1:
            return SYSTEM_CONSTANTS.CANDIDATES.profileIndicatorType.Blank

        case 2:
            return SYSTEM_CONSTANTS.CANDIDATES.profileIndicatorType.Green

        default:
            return '#333333';
    }
}

export function flagColor(flagType) {
    switch (flagType) {
        case 0:
            return SYSTEM_CONSTANTS.CANDIDATES.flagType.Blank

        case 1:
            return SYSTEM_CONSTANTS.CANDIDATES.flagType.Grey

        case 2:
            return SYSTEM_CONSTANTS.CANDIDATES.flagType.Red

        case 3:
            return SYSTEM_CONSTANTS.CANDIDATES.flagType.Orange

        case 4:
            return SYSTEM_CONSTANTS.CANDIDATES.flagType.Blue

        case 5:
            return SYSTEM_CONSTANTS.CANDIDATES.flagType.Green

        default:
            return '#333333';
    }
}

export function profileStatus(profileStatus) {
    switch (profileStatus) {
        case 0:
            return SYSTEM_CONSTANTS.CANDIDATES.profileStatus.Blank

        case 1:
            return SYSTEM_CONSTANTS.CANDIDATES.profileStatus.Green

        case 2:
            return SYSTEM_CONSTANTS.CANDIDATES.profileStatus.Orange

        case 3:
            return SYSTEM_CONSTANTS.CANDIDATES.profileStatus.Blue

        case 4:
            return SYSTEM_CONSTANTS.CANDIDATES.profileStatus.Red

        default:
            return '#d3d3d3'
    }
}

export function profileMotivationStatus(motivationStatus) {
    switch (motivationStatus) {
        case 0:
            return SYSTEM_CONSTANTS.CANDIDATES.motivationStatus.Blank

        case 1:
            return SYSTEM_CONSTANTS.CANDIDATES.motivationStatus.Green

        case 2:
            return SYSTEM_CONSTANTS.CANDIDATES.motivationStatus.Orange

        case 3:
            return SYSTEM_CONSTANTS.CANDIDATES.motivationStatus.Red

        default:
            return '#d3d3d3'
    }
}

export function caseStatusColor(caseStatus) {
    switch (caseStatus) {
        case 'New Case':
            return SYSTEM_CONSTANTS.CANDIDATES.CaseStatusColor.NewCase
        case 'Selected':
            return SYSTEM_CONSTANTS.CANDIDATES.CaseStatusColor.Screening
        case 'Qualifying':
            return SYSTEM_CONSTANTS.CANDIDATES.CaseStatusColor.Processing
        case 'Interviews':
            return SYSTEM_CONSTANTS.CANDIDATES.CaseStatusColor.Sendout
        case 'Job Offers':
            return SYSTEM_CONSTANTS.CANDIDATES.CaseStatusColor.JobOffer
        case 'Joiners':
            return SYSTEM_CONSTANTS.CANDIDATES.CaseStatusColor.Joined


        // case 'Database':
        //     return SYSTEM_CONSTANTS.CANDIDATES.CaseStatusColor.Queries
        case 'Refused':
            return SYSTEM_CONSTANTS.CANDIDATES.CaseStatusColor.RefusedOffer
        case 'Blacklist':
            return SYSTEM_CONSTANTS.CANDIDATES.CaseStatusColor.Blacklist
        case 'Invoice':
            return SYSTEM_CONSTANTS.CANDIDATES.CaseStatusColor.Invoice
        case 'Closed':
            return SYSTEM_CONSTANTS.CANDIDATES.CaseStatusColor.Closed
        case 'Closed - L1':
            return SYSTEM_CONSTANTS.CANDIDATES.CaseStatusColor.ClosedL1
        case 'Closed - L2':
            return SYSTEM_CONSTANTS.CANDIDATES.CaseStatusColor.ClosedL2
        case 'Qualifying':
            return SYSTEM_CONSTANTS.CANDIDATES.CaseStatusColor.QualifiedColor
        case 'Database':
            return SYSTEM_CONSTANTS.CANDIDATES.CaseStatusColor.DatabaseColor
        case 'Sendout':
            return SYSTEM_CONSTANTS.CANDIDATES.CaseStatusColor.SendoutColor
        case 'Credit':
            return SYSTEM_CONSTANTS.CANDIDATES.CaseStatusColor.Credit

        // case 'Promotion':
        //     return SYSTEM_CONSTANTS.CANDIDATES.CaseStatusColor.NotReachable

        default:
            return '#FFFFFF';
    }
}

export function interviewCaseStatusColor(caseStatus) {
    switch (caseStatus) {

        case 'Interviews':
            return SYSTEM_CONSTANTS.BUSINESS_BOOK_INTERVIEWS_STATUS.caseProgressColor
        default:
            return '#FFFFFF';
    }
}