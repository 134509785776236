import React from 'react';
import { Link } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown';
import './expesedashboard.scss';

const ExpenseDashboard = () => {
    return (
        <>
            <div className='container-fluid dkg-income-exp-summMain'>
                <div className='row dkg-income-exp-summCon'>
                    <div className="dk-kpiCaseHead">
                        <div className="dk-kpiCase-yearsDropdown">
                            <div className="d-flex align-items-center">
                                <Dropdown>
                                    <Dropdown.Toggle variant="" id="dropdown-basic">
                                        2022
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item >2022</Dropdown.Item>
                                        <Dropdown.Item >2021</Dropdown.Item>
                                        <Dropdown.Item >2020</Dropdown.Item>
                                        <Dropdown.Item >2019</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                            </div>
                        </div>
                        <div className="dk-kpiCase-months">
                            <ul>
                                <li><Link to="#">All</Link></li>
                                <li><Link to="#">Jan</Link></li>
                                <li><Link to="#">Feb</Link></li>
                                <li><Link to="#">Mar</Link></li>
                                <li><Link to="#">Apr</Link></li>
                                <li><Link to="#">May</Link></li>
                                <li><Link to="#">Jun</Link></li>
                                <li><Link to="#">Jul</Link></li>
                                <li><Link to="#">Aug</Link></li>
                                <li><Link to="#">Sep</Link></li>
                                <li><Link to="#">Oct</Link></li>
                                <li><Link to="#" className='active'>Nov</Link></li>
                                <li><Link to="#">Dec</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className='w-100 dkg-income-exp-summ-pageCon'>
                        <div className='dkg-trsumm-moduleCon'>
                            <div className='dkg-income-exp-boxcols'>
                                <div className='dkg-income-exp-summ-box pl-0' style={{ height: "calc(100vh - 235px)" }}>
                                    <div className='dkg-income-exp-summ-boxHeader'>
                                        <h2 className='dkg-income-exp-summ-Headertitle'>CLIENTS</h2>
                                    </div>
                                    <div className='dkg-income-exp-summ-boxBody'>
                                        <table className="table dkg-income-exp-summ-boxtable dkg-clientsales-cols">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className='dkg-income-exp-summ-box' style={{ height: "calc(100vh - 235px)" }}>
                                    <div className='dkg-income-exp-summ-boxHeader'>
                                        <h2 className='dkg-income-exp-summ-Headertitle'>JOB BOARDS</h2>
                                    </div>
                                    <div className='dkg-income-exp-summ-boxBody'>
                                        <table className="table dkg-income-exp-summ-boxtable dkg-users-table-cols">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr >
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className='dkg-income-exp-summ-box' style={{ height: "calc(100vh - 235px)" }}>
                                    <div className='dkg-income-exp-summ-boxHeader'>
                                        <h2 className='dkg-income-exp-summ-Headertitle'>IT SERVICES</h2>
                                    </div>
                                    <div className='dkg-income-exp-summ-boxBody'>
                                        <table className="table dkg-income-exp-summ-boxtable dkg-users-table-cols">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr >
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className='dkg-income-exp-summ-box' style={{ height: "calc(100vh - 235px)" }}>
                                    <div className='dkg-income-exp-summ-boxHeader'>
                                        <h2 className='dkg-income-exp-summ-Headertitle'>BUSINESS SERVICES</h2>
                                    </div>
                                    <div className='dkg-income-exp-summ-boxBody'>
                                        <table className="table dkg-income-exp-summ-boxtable dkg-users-table-cols">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr >
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className='dkg-income-exp-summ-box pr-0' style={{ height: "calc(100vh - 235px)" }}>
                                    <div className='dkg-income-exp-summ-boxHeader'>
                                        <h2 className='dkg-income-exp-summ-Headertitle'>EMPLOYEES</h2>
                                    </div>
                                    <div className='dkg-income-exp-summ-boxBody'>
                                        <table className="table dkg-income-exp-summ-boxtable dkg-users-table-cols">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr >
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="dk-invoiceBoxMain-container">
                <div className="d-flex">
                    <div className="dk-invoiceBox-Cols-1">
                        <div className="dk-invoiceBox-columns">
                            <div className="sbtitle">Clients</div>
                            <div className="dk-invoiceBox-columns-panel" style={{ minHeight: '573px' }}></div>
                        </div>
                    </div>
                    <div className="dk-invoiceBox-Cols-1">
                        <div className="d-flex">
                            <div className="dk-invoiceBox-col">
                                <div className="sbtitle">Job Board</div>
                                <div className="dk-invoiceBox-columns-panel" style={{ minHeight: '250px' }}></div>
                            </div>
                            <div className="dk-invoiceBox-col">
                                <div className="sbtitle">IT Services</div>
                                <div className="dk-invoiceBox-columns-panel" style={{ minHeight: '250px' }}></div>
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="dk-invoiceBox-col">
                                <div className="sbtitle">Software</div>
                                <div className="dk-invoiceBox-columns-panel" style={{ minHeight: '250px' }}></div>
                            </div>
                            <div className="dk-invoiceBox-col">
                                <div className="sbtitle">Calls & SMS</div>
                                <div className="dk-invoiceBox-columns-panel" style={{ minHeight: '250px' }}></div>
                            </div>
                        </div>
                    </div>
                    <div className="dk-invoiceBox-Cols-1">
                        <div className="dk-invoiceBox-columns">
                            <div className="sbtitle">Accountant</div>
                            <div className="dk-invoiceBox-columns-panel" style={{ minHeight: '573px' }}></div>
                        </div>
                    </div>
                    <div className="dk-invoiceBox-Cols-1">
                        <div className="dk-invoiceBox-columns">
                            <div className="sbtitle">DK Staff</div>
                            <div className="dk-invoiceBox-columns-panel" style={{ minHeight: '573px' }}></div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default ExpenseDashboard;