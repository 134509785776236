import React, { useState, useEffect, useRef, } from 'react';
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import './clientskpi.scss';
import ReorderModal from './ReorderModal';
import AddClientKpi from './AddClientKpi';
//import { useTable } from "react-table";
import { useDispatch, useSelector } from "react-redux";
import { showSuccess, showError, clearMessage } from "../../../../slice/utils/message/messageSlice";
import { listTable, companyListTable, addValue, sortData, deleteClient, bulkDeleteState, clearState, updateValue, } from "../../../../slice/clientKpi/clientKpiSlice";
//import Tooltip from "../../Ui/Tooltip";
import TableLoader from "../../../ui/tableLoader";
//import { DateTime } from "../../../ui/dateFormats/DateTime";
//import EditableCell from "./EditableCell";
import $ from "jquery";
import DeleteModal from "../../../ui/Modal/deletemodal/DeleteModal";
//import CompanyDrodown from "./CompanyDropdown";
import UserList from "../../../ui/userList/";
import UpdatePop from "./UpdatePop";
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
// import TasksLeftPanel from '../../kpitasks/leftpanel/TasksLeftPanel';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    tblDdlActive: {
        backgroundColor: '#31a886 !important',
        color: '#ffffff !important',
        minWidth: 90
    },
    tblDdlInActive: {
        backgroundColor: '#d8345f !important',
        color: '#ffffff !important',
        minWidth: 90
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: 'green',
    },
}));


const ClientKpi = () => {
    const dispatch = useDispatch();
    const { dataList, companyDataList, isLoading, isUpdate, isInsert, isReorder, isSort, isBulkDelete, isError, isDelete, errorMessage } = useSelector(state => state.clientKpi);

    //const { annualDataList, annualMonthlyDataList, isSuccess,  message } = useSelector(state => state.kpiTeam)

    const [bulkLoad, setBulkLoad] = useState(false)
    const [isActive, setIsActive] = useState(false);
    const [countCheck, setCountCheck] = useState(0)
    const [checkBtnTxt, setCheckBtnTxt] = useState('Check All')

    const classes = useStyles();

    const [tutorials, setTutorials] = useState([]);
    const [addNewModal, setIsAddNewModal] = useState(false)
    const [reorderModal, setIsReorderModal] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const tutorialsRef = useRef();
    tutorialsRef.current = tutorials;

    //console.log(companyDataList)

    useEffect(() => {

        if (isBulkDelete) {
            dispatch(clearState())
            dispatch(listTable({ year: yearValue, month: monthValue }))
            setIsActive(false)
            setCheckBtnTxt('Check All')
            setCountCheck(0)
            setBulkLoad(false)
            $('.chkCandidate').prop('checked', false);
            setIsActive(false)
            dispatch(showSuccess({ msg: errorMessage }))
            hideDeleteModal()
        }
    }, [isBulkDelete])

    const hideDeleteModal = () => {
        setIsDeletePop(false)
    }

    const showReorderModal = () => {
        setIsReorderModal(true)
    }
    const hideReorderModal = () => {
        setIsReorderModal(false)
    }
    const showAddNewModal = () => {
        setIsAddNewModal(true)
    }
    const hideAddNewModal = () => {
        setIsAddNewModal(false)
    }

    var today = new Date();
    const [yearValue, setYearValue] = useState(today.getFullYear())
    const [monthValue, setMonthValue] = useState(today.getMonth() + 1)

    const retrieveData = () => {
        dispatch(listTable({ year: yearValue, month: monthValue }))
        //dispatch(companyListTable({ year: yearValue, month: monthValue }))
    }
    useEffect(retrieveData, [yearValue, monthValue]);
    useEffect(() => {
        if (!isLoading && dataList) {
            setTutorials(dataList)
        }
        if (isUpdate) {
            setTutorials(dataList)
            dispatch(clearState())
        }
        if (isInsert) {
            setTutorials(dataList)
            dispatch(clearState())
        }
        if (isReorder) {
            setTutorials(dataList)
            dispatch(clearState())
            retrieveData()
        }
        if (isDelete) {
            setShowDelete(false)
            dispatch(showSuccess({ msg: 'Delete Successfully.' }))
            dispatch(clearState())
            setTutorials(dataList)
        }
        if (isSort) {
            setTutorials(dataList)
            dispatch(clearState())
        }
    }, [isLoading, isBulkDelete, isUpdate, isInsert, isReorder, isDelete, isSort])

    const myRef = useRef(null);
    const [showDelete, setShowDelete] = useState(false)

    const [columnKey, setColumnKey] = useState('')
    const [columnValue, setColumnValue] = useState('0')
    const [isUpdatePop, setIsUpdatePop] = useState(false)

    // const deleteSelected = (id) => {
    //     setRowId(id)
    //     setShowDelete(true)
    // }

    const handleClose = () => {
        setShowDelete(false)
    }

    const deleteItem = async () => {
        //  dispatch(deleteClient({ id: rowId }))
    }

    const sortBy = e => {
        dispatch(sortData({ key: e.target.dataset.column }));
    }

    const searchFunction = (e) => {
        setSearchValue(e.target.value)
        var value = (searchValue).toLowerCase();
        $("#data-table tbody tr").filter(function () {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
        });
    }

    const resetTable = () => {
        setSearchValue('')
        dispatch(clearState())
        retrieveData()
    }

    const openUpdateValuePop = (id, key, value) => {
        setRowId(id)
        setColumnKey(key)
        setColumnValue(value)
        setIsUpdatePop(true)
    }

    const hideUpdateValuePop = () => {
        setIsUpdatePop(false)
    }


    const addNew = () => {
        dispatch(addValue({ year: yearValue, month: monthValue, uid: -1, updatedBy: -1, clientGroup: '', companies: '', roles: 0, jobs: 0, interviews: 0, jobOffers: 0, saleTarget: 0, bonus: 0, netSale: 0, startDate: today }))
    }

    const toggleCheckbox = (e, id) => {
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        if (checkedBoxCount > 0) {
            setCountCheck(checkedBoxCount)
            setIsActive(true)
        } else {
            setCountCheck(0)
            setIsActive(false)
        }
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        var totalCheckboxes = $('input[name="chkCandidate[]"]').length;
        if (checkedBoxCount < totalCheckboxes) {
            setCheckBtnTxt('Check All')
        }
        if (checkedBoxCount == totalCheckboxes) {
            setCheckBtnTxt('Uncheck All')
        }
    }

    const [rowId, setRowId] = useState('0')
    const [isDeletePop, setIsDeletePop] = useState(false);
    const [isDeleteBulkPop, setIsDeleteBulkPop] = useState(false);

    const handleToggle = () => {
        $('.chkCandidate').prop('checked', false);
        setIsActive(false);
    };

    const toggleCheckAll = () => {
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        var totalCheckboxes = $('input[name="chkCandidate[]"]').length;
        if (checkedBoxCount < totalCheckboxes) {
            setCheckBtnTxt('Uncheck All')
            setCountCheck(totalCheckboxes)
            $('.chkCandidate').prop('checked', true);
            // setCheckedBoxes(checkedBoxes.filter((v, i, a) => a.indexOf(v) === i))
        }
        if (checkedBoxCount == totalCheckboxes) {
            setCheckBtnTxt('Check All')
            setCountCheck(0)
            $('.chkCandidate').prop('checked', false);
            setIsActive(false)
        }
        console.log(checkedBoxCount)
    }

    const closeDelete = () => {
        setIsDeletePop(false)
    }

    const deleteSelected = () => {
        setIsDeleteBulkPop(true)
    }
    const closeDeleteBulk = () => {
        setIsDeleteBulkPop(false)
    }

    const deleteItemBulk = async () => {
        setBulkLoad(true)
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        if (checkedBoxCount == 0) {
        } else {
            var canIds = [];
            $.each($(".chkCandidate:checked"), function () {
                canIds.push($(this).val());
            });

            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            await fetch(process.env.REACT_APP_BASE_URL + '/clients-kpis/bulkdelete', {
                method: 'POST',
                body: JSON.stringify({ 'ids': canIds }),
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                    'platform': 'web',
                    'Authorization': 'Bearer' + ' ' + token.access_token
                }
            }).then(response => {
                if (response.status === 200) {
                    dispatch(showSuccess({ msg: 'Delete successfully' }))
                    async function fetchNewData() {
                        try {
                            await dispatch(clearState())
                            await dispatch(listTable({ year: yearValue, month: monthValue }))
                            await dispatch(bulkDeleteState())
                        } catch (err) {
                            console.log(err)
                        }
                    }
                    fetchNewData()
                    setBulkLoad(false)
                    closeDeleteBulk(false)
                    setIsActive(false)
                }
            })
        }
    }

    const selectUser = (e) => {
        dispatch(updateValue({ id: e.target.dataset.rowid, key: "uid", value: e.target.dataset.id }))
    }

    return (
        <>
            {
                (reorderModal) ? <ReorderModal handleClose={hideReorderModal} dataList={tutorials} /> : null
            }

            {
                (addNewModal) ? <AddClientKpi handleClose={hideAddNewModal} /> : null
            }
            {/* {
                (showDelete) ? <DeleteModal handleClose={handleClose} deleteItem={deleteItem} /> : null
            } */}
            {
                (isUpdatePop) ? <UpdatePop onHide={hideUpdateValuePop} rowId={rowId} columnKey={columnKey} value={columnValue} /> : null
            }

            {
                (isDeletePop) ? <DeleteModal id={rowId} onHide={hideDeleteModal} deleteItem={deleteItem} handleClose={closeDelete} /> : null
            }

            {
                (isDeleteBulkPop) ? <DeleteModal id={rowId} onHide={hideDeleteModal} deleteItem={deleteItemBulk} handleClose={closeDeleteBulk} /> : null
            }
            <div className="dk-sendouts-containerMain dk-clientinKpi-main">
                {/* <TasksLeftPanel /> */}
                <div className="dk-clentsKpiMain">
                    <div className="dk-clientsKpiColsMain">
                        <div className="dk-clientsYearsMonth">
                            <div className="d-md-flex align-items-center">
                                <div className="d-flex align-items-center">
                                    <div className="dk-yearsDropdown">
                                        <input type="text" placeholder="Search..." value={searchValue} className="form-control" onChange={searchFunction} />
                                    </div>
                                    <div className="dk-clientsAddMonth">
                                        {/* <button onClick={showAddNewModal}><i className="fas fa-plus"></i></button> */}
                                        <button className="mr-2" onClick={addNew}><i className="fas fa-plus"></i></button>
                                        {/* <button onClick={resetTable}><i className="fas fa-sync-alt"></i></button> */}
                                        <button onClick={showReorderModal}><i className="fas fa-arrows-alt"></i></button>
                                    </div>
                                </div>

                                <div className="dk-clientsMonth">
                                    <ul>
                                        <li><Link to="#" onClick={() => setMonthValue('1')} className={(monthValue == '1') ? 'active' : null}>Jan</Link></li>
                                        <li><Link to="#" onClick={() => setMonthValue('2')} className={(monthValue == '2') ? 'active' : null}>Feb</Link></li>
                                        <li><Link to="#" onClick={() => setMonthValue('3')} className={(monthValue == '3') ? 'active' : null}>Mar</Link></li>
                                        <li><Link to="#" onClick={() => setMonthValue('4')} className={(monthValue == '4') ? 'active' : null}>Apr</Link></li>
                                        <li><Link to="#" onClick={() => setMonthValue('5')} className={(monthValue == '5') ? 'active' : null}>May</Link></li>
                                        <li><Link to="#" onClick={() => setMonthValue('6')} className={(monthValue == '6') ? 'active' : null}>Jun</Link></li>
                                        <li><Link to="#" onClick={() => setMonthValue('7')} className={(monthValue == '7') ? 'active' : null}>Jul</Link></li>
                                        <li><Link to="#" onClick={() => setMonthValue('8')} className={(monthValue == '8') ? 'active' : null}>Aug</Link></li>
                                        <li><Link to="#" onClick={() => setMonthValue('9')} className={(monthValue == '9') ? 'active' : null}>Sep</Link></li>
                                        <li><Link to="#" onClick={() => setMonthValue('10')} className={(monthValue == '10') ? 'active' : null}>Oct</Link></li>
                                        <li><Link to="#" onClick={() => setMonthValue('11')} className={(monthValue == '11') ? 'active' : null}>Nov</Link></li>
                                        <li><Link to="#" onClick={() => setMonthValue('12')} className={(monthValue == '12') ? 'active' : null}>Dec</Link></li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                        <div className="dk-clientsKpiTable">
                            <div className="table-responsive">
                                <table id="data-table" className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th className="dk-ascDsc pointer-cursor" onClick={sortBy} data-column="orderNo">No</th>
                                            <th className="dk-ascDsc pointer-cursor" onClick={sortBy} data-column="AM">AM</th>
                                            <th className="dk-ascDsc pointer-cursor" onClick={sortBy} data-column="company">Client Group</th>
                                            <th className="dk-ascDsc pointer-cursor" onClick={sortBy} data-column="company">Clients</th>
                                            <th className="dk-ascDsc pointer-cursor" onClick={sortBy} data-column="projects">Projects</th>
                                            <th className="dk-ascDsc pointer-cursor" onClick={sortBy} data-column="role">Roles</th>
                                            <th className="dk-ascDsc pointer-cursor" onClick={sortBy} data-column="noOfJobs">Jobs</th>
                                            <th className="dk-ascDsc pointer-cursor" onClick={sortBy} data-column="interviews">Sendouts</th>
                                            <th className="dk-ascDsc pointer-cursor" onClick={sortBy} data-column="salesTarget">Sale Target</th>
                                            <th className="dk-ascDsc pointer-cursor" onClick={sortBy} data-column="jobOffers">Job Offers</th>
                                            <th className="dk-ascDsc pointer-cursor" onClick={sortBy} data-column="jobOffers">Refused</th>
                                            <th className="dk-ascDsc pointer-cursor" onClick={sortBy} data-column="minus">Net Sale</th>
                                            {/* <th className="dk-ascDsc pointer-cursor" onClick={sortBy} data-column="netSales">Remaining</th> */}
                                            <th className="dk-ascDsc pointer-cursor" onClick={sortBy}>%</th>
                                            <th className="dk-ascDsc" style={{ borderTop: "none" }}>Up / Down</th>
                                            {/* <th className="dk-ascDsc pointer-cursor" onClick={sortBy} data-column="updatedAt">Updated On</th> */}
                                        </tr>
                                    </thead>
                                    {
                                        (!isLoading) ?
                                            <tbody>
                                                {

                                                    tutorials.map((item, index) =>
                                                        <tr key={index + 1}>
                                                            <td>
                                                                {/* <div className="dk-noCols">
                                                                <span className="serialNo">{index + 1}</span>
                                                            </div> */}
                                                                <div className="dk-smsTableCheckbox">
                                                                    {index + 1}
                                                                    <input type="checkbox" className="chkCandidate" name="chkCandidate[]" value={item.id} onChange={(e) => toggleCheckbox(e, item.id)} />
                                                                </div>
                                                            </td>
                                                            <td>  <input type="hidden" id="userId" value="0" />
                                                                <Dropdown className="dk-globalUserDropdown">
                                                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                                        {
                                                                            <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + item.recInfo.profileImage} className="select-user-img" />
                                                                        }
                                                                    </Dropdown.Toggle>

                                                                    <Dropdown.Menu className='dk-adstracker-DrpMenu12'>
                                                                        <div className="d-flex">
                                                                            <UserList rowid={item.id} onClick={selectUser} />
                                                                        </div>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </td>
                                                            <td className="pointer-cursor dkg-client-compainesName" onClick={() => openUpdateValuePop(item.id, 'clientGroup', item.clientGroup)}>
                                                                {
                                                                    item.clientGroup
                                                                }
                                                            </td>
                                                            <td className="pointer-cursor dkg-client-compainesName" onClick={() => openUpdateValuePop(item.id, 'companies', item.companies)}>
                                                                {
                                                                    item.companies
                                                                }
                                                            </td>
                                                            <td className="pointer-cursor" onClick={() => openUpdateValuePop(item.id, 'project', item.project)}>
                                                                {
                                                                    item.project
                                                                }
                                                            </td>
                                                            <td className="pointer-cursor" onClick={() => openUpdateValuePop(item.id, 'roles', item.roles)}>
                                                                {
                                                                    item.roles
                                                                }
                                                            </td>
                                                            <td className="pointer-cursor" onClick={() => openUpdateValuePop(item.id, 'jobs', item.jobs)}>
                                                                {
                                                                    item.jobs
                                                                }
                                                            </td>
                                                            <td className="pointer-cursor" onClick={() => openUpdateValuePop(item.id, 'interviews', item.interviews)}>
                                                                {
                                                                    item.interviews
                                                                }
                                                            </td>
                                                            <td className="pointer-cursor dkg-client-saleTarget" onClick={() => openUpdateValuePop(item.id, 'saleTarget', item.saleTarget)}>
                                                                {
                                                                    item.saleTarget
                                                                } €

                                                            </td>
                                                            <td className="pointer-cursor dkg-client-bluecolor" onClick={() => openUpdateValuePop(item.id, 'jobOffers', item.jobOffers)}>
                                                                {
                                                                    item.jobOffers
                                                                }
                                                            </td>
                                                            <td className="pointer-cursor dkg-client-refusedTarget" onClick={() => openUpdateValuePop(item.id, 'refused', item.refused)}>
                                                                {
                                                                    item.refused
                                                                }
                                                            </td>
                                                            <td className="text-success pointer-cursor ">
                                                                {

                                                                    (item.jobOffers - item.refused)
                                                                } €

                                                            </td>


                                                            <td>
                                                                {
                                                                    (item.salesTarget > item.bonus) ?
                                                                        <div className="text-danger  align-items-center">
                                                                            {
                                                                                Math.round((((item.jobOffers - item.refused)) * 100) / (item.saleTarget))
                                                                            } %
                                                                        </div>
                                                                        :
                                                                        <div className=" align-items-center">
                                                                            {
                                                                                Math.round(((item.jobOffers - item.refused) * 100) / ((item.saleTarget)))
                                                                            } %
                                                                        </div>
                                                                }

                                                            </td>

                                                            <td>

                                                                <span className="dk-clientsKpiUpDown down">
                                                                    {
                                                                        ((item.saleTarget) > (item.jobOffers - item.refused))
                                                                            ?
                                                                            <div className="text-danger  align-items-center">
                                                                                {(item.saleTarget - (item.jobOffers - item.refused))}
                                                                            </div>
                                                                            :
                                                                            <div className="text-success  align-items-center">
                                                                                {((item.jobOffers - item.refused) - (item.saleTarget))}
                                                                            </div>
                                                                    }
                                                                </span>
                                                            </td>

                                                            {/* <td><DateTime date={item.updatedAt} /></td> */}

                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                            :
                                            <TableLoader colSpan="14" />
                                    }
                                    <tfoot>
                                        <tr>
                                            <td colSpan="4" style={{ border: 'none' }}></td>
                                            <td className="sumBgBlank">
                                                {
                                                    tutorials.reduce((a, v) => a = a + Number(v.project), 0)
                                                }
                                            </td>
                                            <td className="sumBgBlank text-center" >
                                                {
                                                    tutorials.reduce((a, v) => a = a + Number(v.roles), 0)
                                                }
                                            </td>
                                            <td className="sumBgBlank text-center">
                                                {
                                                    tutorials.reduce((a, v) => a = a + Number(v.jobs), 0)
                                                }</td>
                                            <td className="sumBgBlank text-center">
                                                {
                                                    tutorials.reduce((a, v) => a = a + Number(v.interviews), 0)
                                                }</td>
                                            <td className="sumBgBlank">
                                                {
                                                    tutorials.reduce((a, v) => a = a + Number(v.saleTarget), 0)
                                                } €</td>
                                            <td className="sumBgBlank">
                                                {
                                                    tutorials.reduce((a, v) => a = a + Number(v.jobOffers), 0)
                                                }</td>
                                            <td className="sumBgBlank">
                                                {
                                                    tutorials.reduce((a, v) => a = a + Number(v.refused), 0)
                                                }</td>
                                            <td className="sumBgBlank">

                                                {
                                                    Math.round(((tutorials.reduce((a, v) => a = a + Number(v.jobOffers), 0) - tutorials.reduce((a, v) => a = a + Number(v.refused), 0))))
                                                }
                                            </td>

                                            <td>
                                                {
                                                    Math.round(((tutorials.reduce((a, v) => a = a + Number(v.jobOffers), 0) - tutorials.reduce((a, v) => a = a + Number(v.refused), 0)) * 100) / tutorials.reduce((a, v) => a = a + Number(v.saleTarget), 0))
                                                }

                                                %
                                            </td>


                                            <td className="sum countGrossSale">
                                                {Math.round(tutorials.reduce((a, v) => a = a + Number(v.saleTarget), 0) - ((tutorials.reduce((a, v) => a = a + Number(v.jobOffers), 0) - tutorials.reduce((a, v) => a = a + Number(v.refused), 0))))} €

                                            </td>

                                            {/* <td style={{ border: 'none' }}></td> */}

                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`dk-smsMessage-selectMsg ${isActive ? "activePopup" : ""}`}>
                <div className="d-flex align-items-center justify-content-between">
                    <div className="dk-selectNo">{countCheck}</div>
                    <div className="dk-rowSelected">
                        <span>Row Selected</span>
                    </div>
                    <div className="dk-checkAll" onClick={toggleCheckAll}>
                        <span><i className="fas fa-check"></i></span>
                        {checkBtnTxt}
                    </div>
                    {
                        (bulkLoad) ?
                            <div className={`dk-checkAll ` + classes.backdrop}>
                                <CircularProgress color="inherit" />
                            </div>
                            :
                            <div className="dk-checkAll" onClick={deleteSelected}>
                                <span><i className="fas fa-trash-alt"></i></span>
                                Delete
                            </div>
                    }
                    <div className="dk-checkAll d-flex align-items-center justify-content-center" onClick={handleToggle}>
                        <span><i className="fas fa-times"></i></span>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ClientKpi;

