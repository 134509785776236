import React, { useEffect, useState, useRef } from "react";
import { Route, Link } from "react-router-dom";
import "./responsiveMenu.scss";

import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const JobsSubmenu = (props) => {
  const classes = useStyles();
  const pathname = window.location.pathname;
  const segment1 = pathname.split("/")[2];
  const segment3 = pathname.split("/")[3];

  const [toggleMenu, setToggleMenu] = useState(false);
  useEffect(() => {
    setToggleMenu(false);
  }, [pathname]);

  const ref = useRef(null);
  const { onClickOutside } = props;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
        setToggleMenu(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [onClickOutside]);
  return (
    <React.Fragment>
      <div className="dk-subMenuMain res-sub-menu" ref={ref}>
        <IconButton
          aria-label="delete"
          className="res-sub-btn"
          onClick={() => setToggleMenu(!toggleMenu)}
        >
          <i className="fas fa-bars"></i>
        </IconButton>
        <Route>
          <ul className={`${toggleMenu ? "res-mobileNav show" : null}`}>
            <li>
              <Link
                to="/jobs/summary"
                className={`${segment1 == "summary" ? "active" : ""}`}
              >
                Summary
              </Link>
            </li>

            <li>
              <Link
                to="/jobs/table-live-jobs"
                className={`${segment1 == "table-live-jobs" ? "active" : ""}`}
              >
                Active 1
              </Link>
            </li>
            <li>
              <Link
                to="/jobs/table-inactive-jobs-one"
                className={`${segment1 == "table-inactive-jobs-one" ? "active" : ""}`}
              >
                Inactive 1
              </Link>
            </li>
            <li>
              <Link
                to="/jobs/closed-jobs"
                className={`${segment1 == "closed-jobs" ? "active" : ""}`}
              >
                Closed 1
              </Link>
            </li>
            <li>
              <Link
                to="/jobs/live-jobs"
                className={`${segment1 == "live-jobs" ? "active" : ""}`}
              >
                Active 2
              </Link>
            </li>
            <li>
              <Link
                to="/jobs/inactive-jobs"
                className={`${segment1 == "inactive-jobs" ? "active" : ""}`}
              >
                Inactive 2
              </Link>
            </li>
            <li>
              <Link
                to="/jobs/closed-one"
                className={`${segment1 == "closed-one" ? "active" : ""}`}
              >
                Closed 2
              </Link>
            </li>
           
            {/* <li><Link to="/jobs/job-board" className={`${(segment1 == 'job-board') ? 'active' : ''}`}>Job Board</Link></li> */}
            <li>
              <Link
                to="/jobs/add-new"
                className={`${segment1 == "add-new" ? "active" : ""}`}
              >
                Add New
              </Link>
            </li>
            <li>
              <Link
                to="/jobs/demand/summary"
                className={`${segment1 == "demand" ? "active" : ""}`}
              >
                Demand
              </Link>
            </li>
          </ul>
        </Route>
      </div>
    </React.Fragment>
  );
};

export default JobsSubmenu;
