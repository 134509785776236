import React, { useState } from 'react';
import { Link } from "react-router-dom";
import './dkgstaffcustom.scss';
import Dropdown from 'react-bootstrap/Dropdown'
// import DkgStaffLeftPanel from './leftpanel/DkgStaffLeftPanel';
import { FaSearch, FaPlus, FaEye, FaDownload, FaFilter, FaArrowsAlt, FaTrashAlt, FaEdit } from 'react-icons/fa';
import SearchModal from "./modal/SearchModal";
import AddNewModal from "./modal/AddNewModal";
import EditModal from './modal/EditModal';
import ViewModal from './modal/ViewModal';

const ContractsDkgStaff = () => {
    const [isShowSearch, setShowSearchModal] = useState(false)
    const [isShowAddNew, setShowAddNew] = useState(false)
    const [isEditNew, setEditNew] = useState(false)
    const [isViewTax, setViewTax] = useState(false)


    const showSearch = () => {
        setShowSearchModal(true)
    }

    const SearchClose = () => {
        setShowSearchModal(false)
    }
    const showAddNew = () => {
        setShowAddNew(true)
    }

    const SearchAddNew = () => {
        setShowAddNew(false)
    }
    const showEditModal = () => {
        setEditNew(true)
    }
    const hideEditModal = () => {
        setEditNew(false)
    }
    const showviewModal = () => {
        setViewTax(true)
    }
    const hideviewModal = () => {
        setViewTax(false)
    }
    return (
        <>
            {
                (isShowSearch) ? <SearchModal onHide={SearchClose} /> : null
            }
            {
                (isShowAddNew) ? <AddNewModal onHide={SearchAddNew} /> : null
            }
            {
                (isEditNew) ? <EditModal onHide={hideEditModal} /> : null
            }
            {
                (isViewTax) ? <ViewModal onHide={hideviewModal} /> : null
            }
            <div className="dk-dkgStaff-containerMain">
                {/* <DkgStaffLeftPanel /> */}

                <div className="dk-dkgStaff-rightPanel">
                    <div className="dk-dkgStaffnHead">
                        <div className='dkg-leftStaffnHead'>
                            <div className="dk-searchCols">
                                <input type="text" name="" id="" placeholder="Search..." className="form-control" />
                                <button><FaSearch /></button>
                            </div>
                            <div className="dk-searchIocn" onClick={showAddNew}>
                                <Link to="#" ><FaPlus /></Link>
                            </div>
                        </div>
                        <div className="title">CLIENTS CONTRACTS</div>
                        <div className="d-flex">
                            <div className="dk-searchIocn mr-2">
                                <Link to="#" onClick={showSearch}><FaFilter /></Link>
                            </div>
                            {/* <div className="dk-dkgStaff-addNew">
                                <Link to="#"><FaArrowsAlt /> Reorder</Link>
                            </div> */}
                        </div>
                    </div>
                    <div className="dk-dkgStaff-table">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Status</th>
                                    <th>Start Date</th>
                                    <th>Expire On</th>
                                    <th>Duration</th>
                                    <th>Contract Type</th>
                                    <th>Name of Client</th>
                                    <th>Location of Client</th>
                                    <th>Services Type</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            1
                                            {/* <input type="checkbox" className="dk-chkSendouts" name="" id="" /> */}
                                        </div>
                                    </td>
                                    <td>
                                        <Dropdown className='dkg-return-status-dropdown'>
                                            <Dropdown.Toggle variant="" className='dkg-return-status-dropdown-toggle' style={{ backgroundColor: "#3a9469", color: "#fff" }}>
                                                Active
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className='dkg-return-status-dropdown-Menu' style={{ borderRadius: "0", padding: "0" }}>
                                                <Dropdown.Item className='dkg-return-status-dropdown-item' style={{ backgroundColor: "#3a9469", color: "#fff" }} href="#">Active</Dropdown.Item>
                                                <Dropdown.Item className='dkg-return-status-dropdown-item' style={{ backgroundColor: "red", color: "#fff" }} href="#">InActive</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                    <td>02 Sep 22</td>
                                    <td>10 Sep 22</td>
                                    <td>10 Days</td>
                                    <td>Contract Type 1</td>
                                    <td>Rossella Casciello</td>
                                    <td>Ireland</td>
                                    <td>Recruitment</td>
                                    <td>
                                        <div className="dk-acc-taxreturnAction">
                                            <Link to="#" className="dkg-viewBtn mr-2" onClick={showviewModal}><FaEye /></Link>
                                            <Link to="#" className="dkg-downloadBtn mr-2"><FaDownload /></Link>
                                            <Link to="#" className="dkg-editBtn mr-2" onClick={showEditModal}><FaEdit /></Link>
                                            <Link to="#" className="dkg-deleteBtn"><FaTrashAlt /></Link>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContractsDkgStaff;
