import React from 'react';
import './bankingdashboard.scss';

const BankingDashboard = () => {
    return (
        <>

            <div className="dkg-banking-dash-mainPage-57">
                <div className="d-flex">
                    <div className="dk-invoiceBox-Cols">
                        <div className="d-flex dk-bankingBox-left-two-cols">
                            <div className="dk-invoiceBox-col">
                                <div className="sbtitle">Year</div>
                                <div className="dk-invoiceBox-columns-panel" style={{ minHeight: '250px' }}></div>
                            </div>
                            <div className="dk-invoiceBox-col">
                                <div className="sbtitle">TYPE</div>
                                <div className="dk-invoiceBox-columns-panel" style={{ minHeight: '250px' }}></div>
                            </div>
                            <div className="dk-invoiceBox-col">
                                <div className="sbtitle">PLATFORM</div>
                                <div className="dk-invoiceBox-columns-panel" style={{ minHeight: '250px' }}></div>
                            </div>
                        </div>
                        <div className="d-flex dk-bankingBox-left-two-cols">
                            <div className="dk-invoiceBox-col">
                                <div className="sbtitle">Month</div>
                                <div className="dk-invoiceBox-columns-panel" style={{ minHeight: '250px' }}></div>
                            </div>
                            <div className="dk-invoiceBox-col">
                                <div className="sbtitle">VAT</div>
                                <div className="dk-invoiceBox-columns-panel" style={{ minHeight: '250px' }}></div>
                            </div>
                            <div className="dk-invoiceBox-col">
                                <div className="sbtitle">CATEGORY</div>
                                <div className="dk-invoiceBox-columns-panel" style={{ minHeight: '250px' }}></div>
                            </div>
                        </div>
                    </div>
                    <div className="dk-invoiceBox-Cols">
                        <div className="dk-invoiceBox-columns">
                            <div className="sbtitle">Services</div>
                            <div className="dk-invoiceBox-columns-panel" style={{ minHeight: '573px' }}></div>
                        </div>
                    </div>
                    <div className="dk-invoiceBox-Cols">
                        <div className="dk-invoiceBox-columns">
                            <div className="sbtitle">Name of Business</div>
                            <div className="dk-invoiceBox-columns-panel" style={{ minHeight: '573px' }}></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BankingDashboard;