import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  lazy,
  Suspense,
} from "react";
//import { Link } from "react-router-dom";
import "./tableViewJob.scss";
import "../../../../assets/scss/extradiv.scss";
import {
  ButtonGroup,
  Button,
  Dropdown,
  DropdownButton,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { FaEdit, FaFileAlt } from "react-icons/fa";
import "./jobboardtable.scss";
import "./userpicker/grid-ampicker.scss";
//import Logo from "../../../../assets/images/default-company-logo.png";
import DetialViewPoup from "./modal/DetialViewPoup.js";
import EditdetialviewPoup from "./modal/EditdetialviewPoup";
//import SmallDetialViewPoup from "./modal/SmallDetialViewPoup";
import CreateJobModal from "./modal/createjobs/CreateJob";
import RightModalFilter from "./RightModalFilter";
import { useDispatch, useSelector } from "react-redux";
import {
  closedlist,
  updateJob,
  clearState,
  deletejobsRecords,
} from "../../../../slice/jobs/jobsSlice";
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  usePagination,
  useAsyncDebounce,
} from "react-table";
import $ from "jquery";
//import Pagination from "@material-ui/lab/Pagination";
import UserList from "../../../ui/userList/userListByRole";
//import Status from "./dropdown/status";
import TableStatus from "./dropdown/status/tableStatus";
import TableValues from "./dropdown/status/tableValues";
import JobTableStatus from "../../../ui/dropdownList/JobTableStatus";
import JobTableValues from "../../../ui/dropdownList/JobTableValues";
import { FaEuroSign , FaGift} from "react-icons/fa";


//import RMFilter from "./tableFilter/users/rm";
import AMFilter from "./tableFilter/users/am";
import RAFilter from "./tableFilter/users/ra";
import RCFilter from "./tableFilter/users/rc";
//import CommonRCFilter from "./tableFilter/users/commonRc/";
import CustomDateFormat from "../../../ui/dateFormats/CustomDateFormat";
import TableLoader from "../../../ui/tableLoader";
import moment from "moment-timezone";
import JobStatus from "./tableFilter/jobstatus";
import JobValues from "./tableFilter/jobValues";
import CommentModal from "../../../utils/comment";
import Checkbox from "../utils/checkbox";
import UpdatePop from "./modal/UpdateClosedPop.jsx";
import JobSpecLanguge from "./tableFilter/jobspecstatus";
import DatePicker from "./datepicker/DatePicker";

const TableSuspence = lazy(() =>
  import("../../../ui/tableLoader/tableSuspence")
);

const useInstance = (instance) => {
  if (instance && instance.getInstanceCallback) {
    instance.getInstanceCallback(instance);
  }
};

function Table({
  columns,
  data,
  getInstanceCallback,
  rmFilterId,
  raFilterId,
  rc3FilterId,
  amFilterId,
  prFilterId,
  localityId,
  companyId,
  categoryId,
  languageId,
  projectId,
  roleId,
  cityId,
  hireId,
  statusId,
  valuesId,
  rcFilterId,
  dateId,
  //jobSpecId,
  jobStatusId,
  loading,
  countryId,
  jobprofileId,
  updated_onId,
  createdAtId,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    page,
    prepareRow,
    // gotoPage,
    // state,
    setFilter,
    // canPreviousPage,
    // canNextPage,
    pageOptions,
    // pageCount,
    // nextPage,
    // previousPage,
    // setPageSize,
    // preGlobalFilteredRows,
    // setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      getInstanceCallback,
      autoResetFilters: false,
      autoResetRowState: false,
      initialState: {
        pageIndex: 0,
        pageSize: 400,
        hiddenColumns: [
          "RMId",
          "PRId",
          "date",
          "updated_on",
          "job_profile",
          "category",
          "locality",
          "country",
          "location",
          "fee",
        ],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    (hooks) => hooks.useInstance.push(useInstance)
  );

  // const handlePageChange = (event, value) => {
  //   gotoPage(Number(value) - 1);
  // };

  const [filterType, setFilterType] = useState("");
  useEffect(() => {
    if (rmFilterId === "0") {
      setTimeout(() => {
        setFilter("RMId", "");
      }, 200);
    } else {
      setFilterType("RMId");
      doFilter(rmFilterId);
    }
  }, [rmFilterId]);

  useEffect(() => {
    if (raFilterId === "0") {
      setTimeout(() => {
        setFilter("RAId", "");
      }, 200);
    } else {
      setFilterType("RAId");
      doFilter(raFilterId);
    }
  }, [raFilterId]);

  // useEffect(() => {
  //   if (rc3FilterId === "0") {
  //     setTimeout(() => {
  //       setFilter("RC3Id", "");
  //     }, 200);
  //   } else {
  //     setFilterType("RC3Id");
  //     doFilter(rc3FilterId);
  //   }
  // }, [rc3FilterId]);

  useEffect(() => {
    if (amFilterId === "0") {
      setTimeout(() => {
        setFilter("AMId", "");
      }, 200);
    } else {
      setFilterType("AMId");
      doFilter(amFilterId);
    }
  }, [amFilterId]);

  useEffect(() => {
    if (prFilterId === "0") {
      setTimeout(() => {
        setFilter("PRId", "");
      }, 200);
    } else {
      setFilterType("PRId");
      doFilter(prFilterId);
    }
  }, [prFilterId]);

  useEffect(() => {
    if (categoryId === "0") {
      setTimeout(() => {
        setFilter("category", "");
      }, 200);
    } else {
      setFilterType("category");
      doFilter(categoryId);
    }
  }, [categoryId]);

  useEffect(() => {
    if (countryId === "0") {
      setTimeout(() => {
        setFilterType("country");
        setFilter("country", "");
      }, 200);
    } else {
      setFilterType("country");
      doFilter(countryId);
    }
  }, [countryId]);

  useEffect(() => {
    if (localityId === "0") {
      setTimeout(() => {
        setFilter("locality", "");
      }, 200);
    } else {
      setFilterType("locality");
      doFilter(localityId);
    }
  }, [localityId]);

  useEffect(() => {
    if (companyId === "0") {
      setTimeout(() => {
        setFilter("company", "");
      }, 200);
    } else {
      setFilterType("company");
      doFilter(companyId);
    }
  }, [companyId]);

  useEffect(() => {
    if (languageId === "0") {
      setTimeout(() => {
        setFilter("lang", "");
      }, 200);
    } else {
      setFilterType("lang");
      doFilter(languageId);
    }
  }, [languageId]);

  useEffect(() => {
    if (projectId === "0") {
      setTimeout(() => {
        setFilter("project", "");
      }, 200);
    } else {
      setFilterType("project");
      doFilter(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    if (roleId === "0") {
      setTimeout(() => {
        setFilter("role", "");
      }, 200);
    } else {
      setFilterType("role");
      doFilter(roleId);
    }
  }, [roleId]);

  useEffect(() => {
    if (cityId === "0") {
      setTimeout(() => {
        setFilter("location", "");
      }, 200);
    } else {
      setFilterType("location");
      doFilter(cityId);
    }
  }, [cityId]);

  useEffect(() => {
    if (jobprofileId === "0") {
      setTimeout(() => {
        setFilter("job_profile", "");
      }, 200);
    } else {
      setFilterType("job_profile");
      doFilter(jobprofileId);
    }
  }, [jobprofileId]);

  useEffect(() => {
    if (updated_onId === "0") {
      setTimeout(() => {
        setFilter("updated_on", "");
      }, 200);
    } else {
      setFilterType("updated_on");
      doFilter(updated_onId);
    }
  }, [updated_onId]);

  useEffect(() => {
    if (createdAtId === "0") {
      setTimeout(() => {
        setFilter("createdAt", "");
      }, 200);
    } else {
      setFilterType("createdAt");
      doFilter(createdAtId);
    }
  }, [createdAtId]);

  useEffect(() => {
    if (hireId === "0") {
      setTimeout(() => {
        setFilter("fee", "");
      }, 200);
    } else {
      setFilterType("fee");
      doFilter(hireId);
    }
  }, [hireId]);

  useEffect(() => {
    if (valuesId === "0") {
      setTimeout(() => {
        setFilter("values", "");
      }, 200);
    } else {
      setFilterType("values");
      doFilter(valuesId);
    }
  }, [valuesId]);

  useEffect(() => {
    if (statusId === "0") {
      setTimeout(() => {
        setFilter("status", "");
      }, 200);
    } else {
      setFilterType("status");
      doFilter(statusId);
    }
  }, [statusId]);

  useEffect(() => {
    if (rcFilterId === "0") {
      setTimeout(() => {
        setFilter("RCId", "");
      }, 200);
    } else {
      setFilterType("RCId");
      doFilter(rcFilterId);
    }
  }, [rcFilterId]);

  useEffect(() => {
    if (dateId === "0") {
      setTimeout(() => {
        setFilter("date", "");
      }, 200);
    } else {
      setFilterType("date");
      doFilter(dateId);
    }
  }, [dateId]);

  useEffect(() => {
    if (jobStatusId === "0") {
      setTimeout(() => {
        setFilter("status", "");
      }, 200);
    } else {
      setFilterType("status");
      doFilter(jobStatusId);
    }
  }, [jobStatusId]);

  const doFilter = useCallback(
    useAsyncDebounce((id) => {
      setFilter(filterType, id);
    }, 200),
    [filterType]
  );
  return (
    <React.Fragment>
      <table
        className="table dkpipeline-newCVTable dkg-livejobs-table-234 dkg-closejobs-one-table"
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return loading ? (
              [...Array(10)].map((e, i) => (
                <tr>
                  <TableLoader colSpan="25" />
                </tr>
              ))
            ) : (
              <Suspense fallback={<TableSuspence colSpan="25" />}>
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps([
                        {
                          className: cell.column.className, // pay attention to this
                          style: cell.column.style,
                          // set here your other custom props
                        },
                      ])}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              </Suspense>
            );
          })}
        </tbody>
        <tfoot>
          {footerGroups.map((group) => (
            <tr {...group.getFooterGroupProps()}>
              {group.headers.map((column) => (
                <td {...column.getFooterProps()}>{column.render("Footer")}</td>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>

      <div className="col-sm-12">
        <div className="dk-resPaginationCon pagination">
          <div className="dk-resPagination">
            <div className="dk-resLeftPag">
              <div
                className="dk-texCounter"
                style={{ color: "#fff !imporant" }}
              >
                Showing {pageIndex + 1} to {pageOptions.length} (of{" "}
                {rows.length} entries)
              </div>
            </div>
            {/* <div className="dk-resrightPag pagination-btns">
                            <Pagination count={pageCount} shape="rounded" onChange={handlePageChange} />
                            <TablePagination count={pageCount} onChange={handlePageChange} />
                        </div> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

function debounce(func, wait, immediate) {
  var timeout;

  return (...args) => {
    var context = this;

    var later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isClearAll,
  restClearAllFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState("");

  // const [value, setValue] = React.useState(globalFilter)
  // const onChange = useAsyncDebounce(value => {
  //     setGlobalFilter(value || undefined)
  // }, 200)

  const onChangeSearchTitle = (e) => {
    const { value: nextValue } = e.target;
    setValue(e.target.value);
    debouncedSave(nextValue);
  };

  const debouncedSave = React.useCallback(
    debounce((nextValue) => {
      setGlobalFilter(nextValue);
    }, 1000)
  );

  const clearSearch = () => {
    setValue("");
    setTimeout(() => {
      setGlobalFilter(undefined);
    }, 200);
  };

  useEffect(() => {
    if (isClearAll) {
      clearSearch();
      restClearAllFilter();
    }
  }, [isClearAll]);

  return (
    <React.Fragment>
      <input
        value={value}
        className="form-control"
        onChange={onChangeSearchTitle}
        placeholder={`Search...`}
        onFocus={(e) => (e.target.placeholder = "")}
        onBlur={(e) => (e.target.placeholder = "Search...")}
      />
      {value !== undefined && value.length > 0 ? (
        <div className="input-group-append">
          <button
            className="btn btn-secondary"
            type="button"
            onClick={clearSearch}
          >
            <i className="fa fa-times"></i>
          </button>
        </div>
      ) : (
        <div className="input-group-append">
          <button className="btn btn-secondary" type="button">
            <i className="fa fa-search"></i>
          </button>
        </div>
      )}
    </React.Fragment>
  );
}

const ClosedJob = () => {
  const dispatch = useDispatch();
  const { isLoading, isSuccess, isUpdate, message, closedjobseDataList } =
    useSelector((state) => state.jobs);

  const [data, setData] = useState([]);
  const [jobId, setjobId] = useState(0);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const [canjobs, setCanjobs] = useState(0);
  const [companyName, setCompanyName] = useState("");
  const [clientidds, setClientidds] = useState(0);

  const [columnKey, setColumnKey] = useState("");
  const [columnValue, setColumnValue] = useState("0");
  const [isUpdatePop, setIsUpdatePop] = useState(false);

  /******comment popup start****** */
  const [refId, setRefId] = useState(0);
  const [commentsCount, setCommentCount] = useState(0);
  const [editShow, setEditShow] = useState(false);

  const [commentPopupShow, setCommentPopupShow] = useState(false);
  const commentHandleClose = () => {
    setCommentPopupShow(false);
  };
  /******comment popup end****** */
  const [rowId, setRowId] = useState("0");
  const openUpdateValuePop = (id, key, value) => {
    setRowId(id);
    setColumnKey(key);
    setColumnValue(value);
    setIsUpdatePop(true);
  };

  const hideUpdateValuePop = () => {
    setIsUpdatePop(false);
  };

  const handleClose = async() =>{
    setShow(false);
    await dispatch(closedlist({ isDelete: 0 }));
  } 
  const handleEditClose = async() =>{
    setEditShow(false);
   await dispatch(closedlist({ isDelete: 0 }));
  }

  const handleClose1 = () => setShow1(false);

  const [list, setList] = useState([]);

  useEffect(() => {
    document.title = "Closed Jobs | Recruitment | DK Global CRM";
  }, []);

  useEffect(() => {
    const fetchList = async () => {
      try {
        await dispatch(closedlist({ isDelete: 0 }));
      } catch (err) {
        console.log(err);
      }
    };
    fetchList();
  }, []);

  useEffect(() => {
    if (!isLoading && closedjobseDataList.length > 0) {
      setTimeout(() => {
        setData(closedjobseDataList);
      }, 200);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isUpdate) {
      dispatch(clearState());
      //setData(closedjobseDataList);
      dispatch(closedlist({ isDelete: 0 }));
    }
  }, [isUpdate]);

  const [isProfile, setIsProfile] = useState(false);
  const [isEditProfile, setIsEditProfile] = useState(false);
  const [isRightFilter, setIsRightFilter] = useState(false);

  const showrightFilter = () => {
    setIsRightFilter(true);
    setShow1(true);
  };

  const [totalJobsNumber, setTotalJobsNumber] = useState([]);

  // const status = [
  //   {
  //     key: "normal",
  //     value: "Normal",
  //     text: "Normal",
  //   },
  //   {
  //     key: "urgent",
  //     value: "Urgent",
  //     text: "Urgent",
  //   },
  //   {
  //     key: "to qualify",
  //     value: "To qualify",
  //     text: "To qualify",
  //   },
  //   {
  //     key: "on hold",
  //     value: "On Hold",
  //     text: "On Hold",
  //   },
  //   {
  //     key: "closed",
  //     value: "Closed",
  //     text: "Closed",
  //   },
  // ];

  const columns = useMemo(
    () => [
      {
        id: "id",
        Header: "Job ID",
        accessor: "job_id",
        Cell: ({ cell }) => {
          const handleShow = (e) => {
            setjobId(cell.row.original.id);
            setCanjobs(cell.row.original.job_id);
            setCompanyName(cell.row.original.company);
            setClientidds(cell.row.original.companyDetails.clientId);
            setIsProfile(true);
            setShow(true);
          };

          const editshowModal = (e) => {
            setjobId(cell.row.original.id);
            setCompanyName(cell.row.original.company);
            setClientidds(cell.row.original.companyDetails.clientId);
            setIsEditProfile(true);
            setEditShow(true);
          };
          return (
            <React.Fragment>
              <div className="pipleFilterCols">
                <span
                  className="dk-chevron-rightIcon"
                  style={{ marginRight: "2px" }}
                >
                  <i
                    className="fa fa-chevron-right"
                    data-id={cell.row.original.id}
                    // onClick={handleShow}
                    onClick={editshowModal}
                  ></i>
                  {/* {cell.row.original.job_id} */}
                </span>
                <span
                  className="dk-chevron-rightIcon"
                  style={{ marginLeft: "1px", marginRight: "5px" }}
                >
                  <span className="dkg-jobs-editicon">
                    <FaFileAlt
                      data-id={cell.row.original.id}
                      onClick={handleShow}
                    />
                  </span>
                  {cell.row.original.job_id}
                </span>
                <span className="dk-checkinputIcon">
                  <Checkbox
                    id={cell.row.original.id}
                    checkActiveExtraDiv={checkActiveExtraDiv}
                    checkExtraDivBtnTxt={checkExtraDivBtnTxt}
                  />
                </span>
              </div>
            </React.Fragment>
          );
        },
      },

      {
        id: "status",
        Header: "Status",
        accessor: "status",
        Cell: ({ cell }) => {
          const onItemClick = (id, columnId, value) => {
            updateMyData(id, columnId, value);
          };
          return (
            <React.Fragment>
              <div className="dk-caseStatusDropCon">
                <Dropdown id={`caseStatus${cell.row.original.id}`}>
                  <Dropdown.Toggle
                    variant=""
                    className="dk-caseStatusfliterbtn"
                  >
                    <TableStatus value={cell.row.original.status} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="caseStatusMenu">
                    <JobTableStatus
                      id={cell.row.original.id}
                      columnId="status"
                      onItemClick={onItemClick}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </React.Fragment>
          );
        },
      },
      {
        id: "values",
        Header: "Values",
        accessor: "values",
        Cell: ({ cell }) => {
          const onItemClick = (id, columnId, value) => {
            updateMyData(id, columnId, value);
          };
          return (
            <React.Fragment>
              <div className="dk-caseStatusDropCon">
                <Dropdown id={`caseStatus${cell.row.original.id}`}>
                  <Dropdown.Toggle
                    variant=""
                    className="dk-caseStatusfliterbtn"
                  >
                    <TableValues value={cell.row.original.values} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="caseStatusMenu">
                    <JobTableValues
                      id={cell.row.original.id}
                      columnId="values"
                      onItemClick={onItemClick}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </React.Fragment>
          );
        },
      },
      {
        id: "AMId",
        Header: "AM",
        accessor: "recAM.uid",
        Cell: ({ cell }) => {
          const onItemClick = (id, columnId, value, img) => {
            updateMyData(id, columnId, value, img);
          };
          return (
            <React.Fragment>
              <div className="dk-useFilterCon">
                <Dropdown id={`rec_am${cell.row.original.id}`}>
                  <Dropdown.Toggle variant="" className="dk-fliterbtn">
                    <div className="tooltipMain">
                      <img
                        style={{ height: "25px", width: "25px" }}
                        src={
                          process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                          cell.row.original.recAM.profileImage
                        }
                        className="filterImg"
                        alt={cell.row.original.recAM.firstName}
                      />
                      <div className="tooltipText">
                        {cell.row.original.recAM.firstName +
                          " " +
                          cell.row.original.recAM.lastName}
                      </div>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dk-pipleuserFilterMenu">
                    <div className="d-flex">
                      <UserList
                        id={cell.row.original.id}
                        columnId="rec_am"
                        onItemClick={onItemClick}
                      />
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </React.Fragment>
          );
        },
      },
      // {
      //   id: "RCId",
      //   Header: "RC1",
      //   accessor: (row) =>
      //     row.assignWithjob.map((item, index) => item.userInfo.uid),
      //   Cell: ({ cell }) => {
      //     let allvalue = {};
      //     const onItemClick2 = (id, columnId, value) => {
      //       allvalue = {
      //         id: value,
      //         type: "assignUpdate",
      //         jobid: cell.row.original.id,
      //         value: value,
      //       };
      //       updateMyData(id, columnId, allvalue);
      //     };

      //     const onItemClick1 = (id, columnId, value) => {
      //       allvalue = {
      //         id: value,
      //         type: "assign",
      //         jobid: cell.row.original.id,
      //         value: value,
      //       };
      //       updateMyData(allvalue.id, columnId, allvalue);
      //     };

      //     const listItems = cell.row.original.assignWithjob.length ? (
      //       cell.row.original.assignWithjob.map((item, index) =>
      //         index === 0 ? (
      //           <React.Fragment>
      //             <div className="dk-useFilterCon">
      //               <Dropdown id={`user_id${cell.row.original.id}`}>
      //                 <Dropdown.Toggle variant="" className="dk-fliterbtn">
      //                   <div className="tooltipMain">
      //                     <img
      //                       style={{ height: "25px", width: "25px" }}
      //                       src={
      //                         process.env.REACT_APP_IMG_CLOUD_BASE_URL +
      //                         item.userInfo.profileImage
      //                       }
      //                       className="filterImg"
      //                       alt={item.userInfo.firstName}
      //                     />
      //                     <div className="tooltipText">
      //                       {item.userInfo.firstName +
      //                         " " +
      //                         item.userInfo.lastName}
      //                     </div>
      //                   </div>
      //                 </Dropdown.Toggle>
      //                 <Dropdown.Menu className="dk-pipleuserFilterMenu">
      //                   <div className="d-flex">
      //                     <UserList
      //                       id={item.id}
      //                       columnId="user_id"
      //                       onItemClick={onItemClick2}
      //                     />
      //                   </div>
      //                 </Dropdown.Menu>
      //               </Dropdown>
      //             </div>
      //           </React.Fragment>
      //         ) : (
      //           ""
      //         )
      //       )
      //     ) : (
      //       <React.Fragment>
      //         <div className="dk-useFilterCon">
      //           <Dropdown id={`user_id${cell.row.original.id}`}>
      //             <Dropdown.Toggle variant="" className="dk-fliterbtn">
      //               <div className="tooltipMain">
      //                 <img
      //                   style={{ height: "25px", width: "25px" }}
      //                   src={
      //                     process.env.REACT_APP_IMG_CLOUD_BASE_URL +
      //                     "/users/images/user.png"
      //                   }
      //                   className="filterImg"
      //                   alt="No User Selected"
      //                 />{" "}
      //               </div>
      //             </Dropdown.Toggle>
      //             <Dropdown.Menu className="dk-pipleuserFilterMenu">
      //               <div className="d-flex">
      //                 <UserList
      //                   id="-1"
      //                   columnId="user_id"
      //                   onItemClick={onItemClick1}
      //                 />
      //               </div>
      //             </Dropdown.Menu>
      //           </Dropdown>
      //         </div>
      //       </React.Fragment>
      //     );

      //     return (
      //       <React.Fragment>
      //         <div className="dk-useFilterCon">
      //           <div className="dk-AmDropDwnCon mr-2">{listItems}</div>
      //         </div>
      //       </React.Fragment>
      //     );
      //   },
      // },
      {
        id: "RCId",
        Header: "RC1",
        accessor: "recRM.uid",
        Cell: ({ cell }) => {
          const onItemClick = (id, columnId, value, img) => {
            updateMyData(id, columnId, value, img);
          };
          return (
            <React.Fragment>
              <div className="dk-useFilterCon">
                <Dropdown id={`rec_rm${cell.row.original.id}`}>
                  <Dropdown.Toggle variant="" className="dk-fliterbtn">
                    <div className="tooltipMain">
                      <img
                        style={{ height: "25px", width: "25px" }}
                        src={
                          process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                          cell.row.original.recRM.profileImage
                        }
                        className="filterImg"
                        alt={cell.row.original.recRM.firstName}
                      />
                      <div className="tooltipText">
                        {cell.row.original.recRM.firstName +
                          " " +
                          cell.row.original.recRM.lastName}
                      </div>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dk-pipleuserFilterMenu">
                    <div className="d-flex">
                      <UserList
                        id={cell.row.original.id}
                        columnId="rec_rm"
                        onItemClick={onItemClick}
                      />
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </React.Fragment>
          );
        },
      },
      {
        id: "RAId",
        Header: "RC2",
        accessor: "recRA.uid",
        Cell: ({ cell }) => {
          const onItemClick = (id, columnId, value, img) => {
            updateMyData(id, columnId, value, img);
          };
          return (
            <React.Fragment>
              <div className="dk-useFilterCon">
                <Dropdown id={`rec_ra${cell.row.original.id}`}>
                  <Dropdown.Toggle variant="" className="dk-fliterbtn">
                    <div className="tooltipMain">
                      <img
                        style={{ height: "25px", width: "25px" }}
                        src={
                          process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                          cell.row.original.recRA.profileImage
                        }
                        className="filterImg"
                        alt={cell.row.original.recRA.firstName}
                      />
                      <div className="tooltipText">
                        {cell.row.original.recRA.firstName +
                          " " +
                          cell.row.original.recRA.lastName}
                      </div>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dk-pipleuserFilterMenu">
                    <div className="d-flex">
                      <UserList
                        id={cell.row.original.id}
                        columnId="rec_ra"
                        onItemClick={onItemClick}
                      />
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </React.Fragment>
          );
        },
      },
      // {
      //   id: "RC3Id",
      //   Header: "RC3",
      //   accessor: "rec3rc.uid",
      //   Cell: ({ cell }) => {
      //     const onItemClick = (id, columnId, value, img) => {
      //       updateMyData(id, columnId, value, img);
      //     };
      //     return (
      //       <React.Fragment>
      //         <div className="dk-useFilterCon">
      //           <Dropdown id={`rc3${cell.row.original.id}`}>
      //             <Dropdown.Toggle variant="" className="dk-fliterbtn">
      //               <div className="tooltipMain">
      //                 <img
      //                   style={{ height: "25px", width: "25px" }}
      //                   src={
      //                     process.env.REACT_APP_IMG_CLOUD_BASE_URL +
      //                     cell.row.original.rec3rc.profileImage
      //                   }
      //                   className="filterImg"
      //                   alt={cell.row.original.rec3rc.firstName}
      //                 />
      //                 <div className="tooltipText">
      //                   {cell.row.original.rec3rc.firstName +
      //                     " " +
      //                     cell.row.original.rec3rc.lastName}
      //                 </div>
      //               </div>
      //             </Dropdown.Toggle>
      //             <Dropdown.Menu className="dk-pipleuserFilterMenu">
      //               <div className="d-flex">
      //                 <UserList
      //                   id={cell.row.original.id}
      //                   columnId="rc3"
      //                   onItemClick={onItemClick}
      //                 />
      //               </div>
      //             </Dropdown.Menu>
      //           </Dropdown>
      //         </div>
      //       </React.Fragment>
      //     );
      //   },
      // },
      {
        id: "RMId",
        Header: "RM Id",
        accessor: "recRM.uid",
      },

      {
        id: "PRId",
        Header: "PR Id",
        accessor: "priority",
      },
      {
        id: "date",
        Header: "Date",
        accessor: (row) => row.date1 + " " + row.date1,
      },
      {
        id: "job_profile",
        Header: "Job Profile",
        accessor: (row) => (row.job_profile == "" ? "Blank" : row.job_profile),
      },
      {
        id: "demand",
        Header: "Demand Came",
        accessor: "demand",
        Cell: ({ cell }) => {
          const onDateClick = (date) => {
            //return false
            // setDemandCalender(true)
            // setjobId(cell.row.original.id)
            //update on calender ok button
            // console.log(moment().utc().format("YYYY-MM-DD HH:mm:ss"))
            // console.log(moment().format("YYYY-MM-DD HH:mm:ss"))
            updateMyData(
              cell.row.original.id,
              "demand",
              moment(date).utc().format("YYYY-MM-DD HH:mm:ss")
            );
          };
          return (
            <React.Fragment>
              <div
                className="dkg-jobs-demand-datepicker"
                style={{ display: "flex" }}
              >
                <OverlayTrigger
                  overlay={(props) => (
                    <Tooltip {...props}>Click to Update</Tooltip>
                  )}
                  placement="top"
                >
                  <span
                    style={{
                      cursor: "pointer",
                    }}
                    data-id={cell.row.original.id}
                    data-column="demand"
                    //onClick={onDateClick}
                  >
                    {moment(moment(cell.row.original.demand)).fromNow(true)} ago
                  </span>
                </OverlayTrigger>

                <DatePicker selectedDate="" handleDateChange={onDateClick} />
              </div>
            </React.Fragment>
          );
        },
      },
      {
        id: "updated",
        Header: "Confirmed",
        accessor: "updated_on",
        Cell: ({ cell }) => {
          const onDateClick = (e) => {
            updateMyData(
              e.target.dataset.id,
              e.target.dataset.column,
              moment().utc().format("YYYY-MM-DD HH:mm:ss")
            );
          };
          return (
            <React.Fragment>
              <OverlayTrigger
                overlay={(props) => (
                  <Tooltip {...props}>Click to Update</Tooltip>
                )}
                placement="top"
              >
                <span
                  style={{
                    cursor: "pointer",
                  }}
                  data-id={cell.row.original.id}
                  data-column="updated_on"
                  onClick={onDateClick}
                >
                  {moment(moment(cell.row.original.updated_on)).fromNow(true)}
                </span>
              </OverlayTrigger>
            </React.Fragment>
          );
        },
      },
      {
        id: "JP",
        Header: "Jobs",
        accessor: "job_jp",
        Cell: ({ cell }) => {
          return (
            <React.Fragment>
              <div
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                }}
                id={cell.row.original.id}
                onClick={() =>
                  openUpdateValuePop(
                    cell.row.original.id,
                    "job_jp",
                    cell.row.original.job_jp
                  )
                }
              >
                {cell.row.original.job_jp}
              </div>
            </React.Fragment>
          );
        },
        // Footer: (info) => {
        //   setTotalJobsNumber(info.page);
        //   return "";
        // },
        Footer: (info) => {
          setTotalJobsNumber(info.page);

          const total = React.useMemo(
            () =>
              info.page.reduce(
                (sum, row) => Number(row.original.job_jp) + sum,
                0
              ),
            [info.page]
          );
          return <>{total}</>;
        },
      },
      {
        id: "l1_new",
        Header: "L1",
        accessor: "l1_new",
        Cell: ({ cell }) => {
          return (
            <React.Fragment>
              <div
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                }}
                id={cell.row.original.id}
                onClick={() =>
                  openUpdateValuePop(
                    cell.row.original.id,
                    "l1_new",
                    cell.row.original.l1_new
                  )
                }
              >
                {cell.row.original.l1_new}
              </div>
            </React.Fragment>
          );
        },
        // Footer: (info) => {
        //   setTotalJobsNumber(info.page);
        //   return "";
        // },
        Footer: (info) => {
          setTotalJobsNumber(info.page);

          const total = React.useMemo(
            () =>
              info.page.reduce(
                (sum, row) => Number(row.original.l1_new) + sum,
                0
              ),
            [info.page]
          );
          return <>{total}</>;
        },
      },
      {
        id: "no_of_jobs",
        Header: "L2",
        accessor: "no_of_jobs",
        Cell: ({ cell }) => {
          return (
            <React.Fragment>
              <div
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                }}
                id={cell.row.original.id}
                onClick={() =>
                  openUpdateValuePop(
                    cell.row.original.id,
                    "no_of_jobs",
                    cell.row.original.no_of_jobs
                  )
                }
              >
                {cell.row.original.no_of_jobs}
              </div>
            </React.Fragment>
          );
        },
        // Footer: (info) => {
        //   setTotalJobsNumber(info.page);
        //   return "";
        // },
        Footer: (info) => {
          setTotalJobsNumber(info.page);

          const total = React.useMemo(
            () =>
              info.page.reduce(
                (sum, row) => Number(row.original.no_of_jobs) + sum,
                0
              ),
            [info.page]
          );
          return <>{total}</>;
        },
      },
      {
        id: "L1",
        Header: "L3",
        accessor: "job_l1",
        Cell: ({ cell }) => {
          return (
            <React.Fragment>
              <div
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                }}
                id={cell.row.original.id}
                onClick={() =>
                  openUpdateValuePop(
                    cell.row.original.id,
                    "job_l1",
                    cell.row.original.job_l1
                  )
                }
              >
                {cell.row.original.job_l1}
              </div>
            </React.Fragment>
          );
        },
        // Footer: (info) => {
        //   setTotalJobsNumber(info.page);
        //   return "";
        // },
        Footer: (info) => {
          setTotalJobsNumber(info.page);

          const total = React.useMemo(
            () =>
              info.page.reduce(
                (sum, row) => Number(row.original.job_l1) + sum,
                0
              ),
            [info.page]
          );
          return <>{total}</>;
        },
      },
      {
        id: "L2",
        Header: "L4",
        accessor: "job_l2",
        Cell: ({ cell }) => {
          return (
            <React.Fragment>
              <div
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                }}
                id={cell.row.original.id}
                onClick={() =>
                  openUpdateValuePop(
                    cell.row.original.id,
                    "job_l2",
                    cell.row.original.job_l2
                  )
                }
              >
                {cell.row.original.job_l2}
              </div>
            </React.Fragment>
          );
        },
        // Footer: (info) => {
        //   setTotalJobsNumber(info.page);
        //   return "";
        // },
        Footer: (info) => {
          setTotalJobsNumber(info.page);

          const total = React.useMemo(
            () =>
              info.page.reduce(
                (sum, row) => Number(row.original.job_l2) + sum,
                0
              ),
            [info.page]
          );
          return <>{total}</>;
        },
      },
      {
        id: "SO",
        Header: "L5",
        accessor: "job_so",
        Cell: ({ cell }) => {
          return (
            <React.Fragment>
              <div
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                }}
                id={cell.row.original.id}
                onClick={() =>
                  openUpdateValuePop(
                    cell.row.original.id,
                    "job_so",
                    cell.row.original.job_so
                  )
                }
              >
                {cell.row.original.job_so}
              </div>
            </React.Fragment>
          );
        },
        // Footer: (info) => {
        //   setTotalJobsNumber(info.page);
        //   return "";
        // },
        Footer: (info) => {
          setTotalJobsNumber(info.page);

          const total = React.useMemo(
            () =>
              info.page.reduce(
                (sum, row) => Number(row.original.job_so) + sum,
                0
              ),
            [info.page]
          );
          return <>{total}</>;
        },
      },
      {
        id: "JO",
        Header: "L6",
        accessor: "job_jo",
        Cell: ({ cell }) => {
          return (
            <React.Fragment>
              <div
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                }}
                id={cell.row.original.id}
                onClick={() =>
                  openUpdateValuePop(
                    cell.row.original.id,
                    "job_jo",
                    cell.row.original.job_jo
                  )
                }
              >
                {cell.row.original.job_jo}
              </div>
            </React.Fragment>
          );
        },
        // Footer: (info) => {
        //   setTotalJobsNumber(info.page);
        //   return "";
        // },
        Footer: (info) => {
          setTotalJobsNumber(info.page);

          const total = React.useMemo(
            () =>
              info.page.reduce(
                (sum, row) => Number(row.original.job_jo) + sum,
                0
              ),
            [info.page]
          );
          return <>{total}</>;
        },
      },
      {
        id: "commentCount",
        Header: "Comment",
        accessor: "commentCount",
        Cell: ({ cell }) => {
          // const onItemClick = (id, columnId, value) => {
          //   updateMyData(id, columnId, value);
          // };
          const commentPoupShow = (e) => {
            setRefId(cell.row.original.id);
            setCommentCount(cell.row.original.commentCount);
            setCommentPopupShow(true);
          };
          return (
            <React.Fragment>
              <div
                className="d-flex align-items-center justify-content-center grid-table-box"
                style={{
                  cursor: "unset",
                  background: "none",
                  borderBottom: "0px",
                }}
              >
                {/* <CommentModal
                  refTable="livejobs"
                  refId={cell.row.original.id}
                  count={cell.row.original.commentCount}
                  title="Closed Jobs Comments"
                /> */}
                {cell.row.original.commentCount === 0 ? (
                  <span
                    className="commentBoxCon zeroComment"
                    onClick={commentPoupShow}
                  >
                    <span className="numCon zeroCommentCount">
                      {cell.row.original.commentCount}
                    </span>
                  </span>
                ) : (
                  <span className="commentBoxCon" onClick={commentPoupShow}>
                    <span className="numCon">
                      {cell.row.original.commentCount}
                    </span>
                  </span>
                )}
              </div>
            </React.Fragment>
          );
        },
      },
      {
        id: "name",
        Header: "Title",
        accessor: "title_description",
      },
      {
        id: "company",
        Header: "Company",
        accessor: "company",
      },
      {
        id: "category",
        Header: "Category",
        accessor: "category",
      },
      {
        id: "job_country",
        Header: "Country",
        accessor: "job_country",
      },
      {
        id: "job_location",
        Header: "City/Town",
        accessor: "location",
      },
      {
        id: "lang",
        Header: "Language",
        accessor: "lang",
      },
      {
        id: "project",
        Header: "Project",
        accessor: "project",
      },
      {
        id: "role",
        Header: "Role Type",
        accessor: "role",
      },
      {
        id: "start1",
        Header: "Start Date 1",
        accessor: "date1",
        Cell: ({ cell }) => {
          return (
            <React.Fragment>
              <div
                id={cell.row.original.id}
                onClick={() =>
                  openUpdateValuePop(
                    cell.row.original.id,
                    "date1",
                    cell.row.original.date1
                  )
                }
              >
                {cell.row.original.date1}
              </div>
            </React.Fragment>
          );
        },
      },
      {
        id: "start2",
        Header: "Start Date 2",
        accessor: "date2",
        Cell: ({ cell }) => {
          return (
            <React.Fragment>
              <div
                id={cell.row.original.id}
                onClick={() =>
                  openUpdateValuePop(
                    cell.row.original.id,
                    "date1",
                    cell.row.original.date2
                  )
                }
              >
                {cell.row.original.date2}
              </div>
            </React.Fragment>
          );
        },
      },
      // {
      //   id: "category",
      //   Header: "Category",
      //   accessor: "category",
      // },
      {
        id: "locality",
        Header: "Locality",
        accessor: (row) => (row.locality == "" ? "Blank" : row.locality),
      },
      {
        id: "country",
        Header: "Country",
        accessor: (row) => (row.job_country == "" ? "Blank" : row.job_country),
      },
      // {
      //   id: "category",
      //   Header: "Category",
      //   accessor: (row) => (row.category == "" ? "Blank" : row.category),
      // },
      {
        id: "location",
        Header: "City",
        accessor: (row) => (row.location == "" ? "Blank" : row.location),
      },
      {
        id: "fee",
        Header: "Job Fee",
        accessor: "fee",
        accessor: (row) =>
          row.fee == "" || row.fee == "0" ? "Blank" : row.fee,
      },

      {
        id: "updated_on",
        Header: "Updated",
        accessor: (row) => moment(moment(row.updated_on)).format("DD MMM, YY"),
      },

      {
        id: "createdAt",
        Header: "Job Created",
        accessor: (row) => moment(moment(row.createdAt)).format("DD MMM, YY"),
      },
    ],
    []
  );

  const [instanceData, setInstanceData] = useState("0");
  const reactTableInstance = (instance) => {
    if (instance) {
      setList(instance.flatRows);
      setInstanceData(instance);
    }
  };

  const updateMyData = (id, columnId, value) => {
    dispatch(updateJob({ id, key: columnId, value }));
  };

  const [showTotalJob, setShowTotalJob] = useState("0");

  useEffect(() => {
    const totalRow = totalJobsNumber.length;
    const totalJob = totalJobsNumber.reduce(
      (total, row) => (total = total + Number(row.original.job_jp)),
      0
    );
    setShowTotalJob(totalJob);
  }, [totalJobsNumber]);

  const [rmFilterId, setRmFilterId] = useState("0");
  const [rmFilterImg, setRmFilterImg] = useState("0");

  const [rc3FilterId, setRc3FilterId] = useState("0");
  const [rc3FilterImg, setRc3FilterImg] = useState("0");
  const [raFilterId, setRaFilterId] = useState("0");
  const [raFilterImg, setRaFilterImg] = useState("0");

  const [r2FilterId, set2FilterId] = useState("0");
  const [r3FilterId, set3FilterId] = useState("0");
  const [rcFilterId, setRcFilterId] = useState("0");
  const [rcFilterImg, setRcFilterImg] = useState("0");

  const [amFilterId, setAmFilterId] = useState("0");
  const [amFilterImg, setAmFilterImg] = useState("0");

  const [prFilterId, setPrFilterId] = useState("0");
  const [prFilterImg, setPrFilterImg] = useState("0");

  const [statusId, setStatusId] = useState("0");
  const [valuesId, setValuesId] = useState("0");
  const [categoryId, setCategoryId] = useState("0");
  const [localityId, setLocalityId] = useState("0");
  const [companyId, setCompanyId] = useState("0");
  const [languageId, setLanguageId] = useState("0");
  const [projectId, setProjectId] = useState("0");
  const [roleId, setRoleId] = useState("0");
  const [cityId, setCityId] = useState("0");
  const [hireId, setHireId] = useState("0");
  const [dateId, setDateId] = useState("0");
  const [jobSpecId, setjobSpecId] = useState("0");
  const [jobStatusId, setjobStatusId] = useState("0");

  const [countryId, setCountryId] = useState("0");
  const [jobprofileId, setJobprofileId] = useState("0");
  const [updated_onId, setUpdated_onId] = useState("0");
  const [createdAtId, setCreatedAtId] = useState("0");

  const [checkBtnTxt, setCheckBtnTxt] = useState("Check All");
  const [countCheck, setCountCheck] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [dataMoveTo, SetDataMoveTo] = useState("");

  const checkActiveExtraDiv = (isShowExitra, checkedBoxCount) => {
    if (isShowExitra) {
      setIsActive(true);
      setCountCheck(checkedBoxCount);
    } else {
      setIsActive(false);
      setCountCheck(checkedBoxCount);
    }
  };

  const toggleCheckAll = () => {
    var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
    var totalCheckboxes = $('input[name="chkCandidate[]"]').length;
    if (checkedBoxCount < totalCheckboxes) {
      setCheckBtnTxt("Uncheck All");
      setCountCheck(totalCheckboxes);
      $(".chkCandidate").prop("checked", true);
    }
    if (checkedBoxCount == totalCheckboxes) {
      setCheckBtnTxt("Check All");
      setCountCheck(0);
      $(".chkCandidate").prop("checked", false);
      setIsActive(false);
    }
  };

  const handleToggle = () => {
    $(".chkCandidate").prop("checked", false);
    setIsActive(false);
  };

  const moveRecords = async (e) => {
    SetDataMoveTo(e.target.dataset.moveto);
  };

  useEffect(() => {
    if (dataMoveTo !== "") {
      (async () => {
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        if (checkedBoxCount == 0) {
        } else {
          var canIds = [];
          $.each($(".chkCandidate:checked"), function () {
            canIds.push($(this).val());
          });

          // console.log(canIds)
          // console.log(dataMoveTo)
          await dispatch(deletejobsRecords({ ids: canIds, isSoftDelete: 1 }));
          $(".chkCandidate").prop("checked", false);
          setIsActive(false);
        }
      })();
    }
  }, [dataMoveTo]);

  const checkExtraDivBtnTxt = (btnTxt) => {
    setCheckBtnTxt(btnTxt);
  };

  const selectUserFilter = async (e) => {
    const type = e.target.dataset.type;
      switch (type) {
      case "RMID":
        setRmFilterId(e.target.dataset.id);
        setRmFilterImg(e.target.dataset.img);
        break;

      case "RAID":
        set2FilterId(e.target.dataset.id);
        setRaFilterId(e.target.dataset.id);
        setRcFilterImg(e.target.dataset.img);
        //setRaFilterImg(e.target.dataset.img);
        break;
      // case "RC3Id":
      //   //setRcFilterId(e.target.dataset.id);
      //   set3FilterId(e.target.dataset.id);
      //   setRc3FilterId(e.target.dataset.id);
      //   setRcFilterImg(e.target.dataset.img);
      //   //setRc3FilterImg(e.target.dataset.img);
      //   break;

      case "RCID":
        //set2FilterId(e.target.dataset.id);
        setRcFilterId(e.target.dataset.id);
        setRcFilterImg(e.target.dataset.img);
        break;

      case "AMID":
        setAmFilterId(e.target.dataset.id);
        setAmFilterImg(e.target.dataset.img);
        break;

      case "priority":
        setPrFilterId(e.target.dataset.id);
        setPrFilterImg(e.target.dataset.img);
        break;

      default:
        break;
    }
  };

  const clearUserFilter = (e) => {
    const type = e.target.dataset.type;
    switch (type) {
      case "RMID":
        setRmFilterId("0");
        setRmFilterImg("0");
        break;

      case "RAID":
        setRaFilterId("0");
        set2FilterId("0");
        setRaFilterImg("0");
        break;

      // case "RC3Id":
      //   set3FilterId("0")
      //   setRc3FilterId("0");
      //   setRc3FilterImg("0");
      //   break;

      case "RCID":
        setRcFilterId("0");
        setRcFilterImg("0");
        break;

      case "AMID":
        setAmFilterId("0");
        setAmFilterImg("0");
        break;

      case "priority":
        setPrFilterId("0");
        setPrFilterImg("0");
        break;

      default:
        break;
    }
  };

  const [filterTags, setFilterTags] = useState([]);
  const [jobSpecFilter, setJobSpecFilter] = useState("Language");
  const [jobStatusFilter, setjobStatusFilter] = useState("Status");
  const [jobValuesFilter, setjobValuesFilter] = useState("Values");

  const selectOtherFilter = async (e) => {
    const type = e.target.dataset.type;
    const value = e.target.dataset.value;
    let i;
    switch (type) {
      case "status":
        i = filterTags.findIndex(function (x) {
          return x.type === "status";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setjobStatusId(value);
        setjobStatusFilter(value);
        break;

      case "jobSpec":
        i = filterTags.findIndex(function (x) {
          return x.type === "jobSpec";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setjobSpecId(value);
        setJobSpecFilter(value);
        break;

      case "values":
        i = filterTags.findIndex(function (x) {
          return x.type === "values";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setjobValuesFilter(value);
        setValuesId(value);
        break;

      case "category":
        i = filterTags.findIndex(function (x) {
          return x.type === "category";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setCategoryId(value);
        break;

      case "country":
        i = filterTags.findIndex(function (x) {
          return x.type === "country";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setCountryId(value);
        break;

      case "locality":
        i = filterTags.findIndex(function (x) {
          return x.type === "locality";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setLocalityId(value);
        break;

      case "company":
        i = filterTags.findIndex(function (x) {
          return x.type === "company";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setCompanyId(value);
        break;

      case "language":
        i = filterTags.findIndex(function (x) {
          return x.type === "language";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setLanguageId(value);
        setJobSpecFilter(value);
        break;

      case "project":
        i = filterTags.findIndex(function (x) {
          return x.type === "project";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setProjectId(value);
        break;

      case "role":
        i = filterTags.findIndex(function (x) {
          return x.type === "role";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setRoleId(value);
        break;

      case "city":
        i = filterTags.findIndex(function (x) {
          return x.type === "city";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setCityId(value);
        break;

      case "job_profile":
        i = filterTags.findIndex(function (x) {
          return x.type === "job_profile";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setJobprofileId(value);
        break;

      case "updated_on":
        i = filterTags.findIndex(function (x) {
          return x.type === "updated_on";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setUpdated_onId(value);
        break;

      case "createdAt":
        i = filterTags.findIndex(function (x) {
          return x.type === "createdAt";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setCreatedAtId(value);
        break;

      case "hire":
        i = filterTags.findIndex(function (x) {
          return x.type === "hire";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setHireId(value);
        break;

      case "date":
        i = filterTags.findIndex(function (x) {
          return x.type === "date";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setDateId(value);
        break;

      default:
        i = filterTags.findIndex(function (x) {
          return x.type === type;
        });
        if (i === -1) {
          filterTags.push({ type, value });
        }
        break;
    }
  };

  const removeTag = async (e) => {
    const type = e.target.dataset.type;
    setFilterTags(filterTags.filter((item) => item.type !== type));
    switch (type) {
      case "status":
        setjobStatusId("0");
        setjobStatusFilter("Status");
        break;
      case "values":
        setValuesId("0");
        setjobValuesFilter("Values");
        break;

      case "jobSpec":
        setjobSpecId("0");
        setJobSpecFilter("Language");
        break;

      case "category":
        setCategoryId("0");
        break;

      case "country":
        setCountryId("0");
        break;

      case "locality":
        setLocalityId("0");
        break;

      case "company":
        setCompanyId("0");
        break;

      case "language":
        setLanguageId("0");
        setJobSpecFilter("Language");
        break;

      case "project":
        setProjectId("0");
        break;

      case "role":
        setRoleId("0");
        break;

      case "city":
        setCityId("0");
        break;

      case "job_profile":
        setJobprofileId("0");
        break;

      case "updated_on":
        setUpdated_onId("0");
        break;

      case "createdAt":
        setCreatedAtId("0");
        break;

      case "hire":
        setHireId("0");
        break;

      case "date":
        setDateId("0");
        break;

      case "status":
        setStatusId("0");
        break;

      default:
        break;
    }
  };

  const [isClearAll, setIsClearAll] = useState(false);
  // const clearAllFilter = async () => {
  //   setRaFilterId("0");
  //   setRaFilterImg("0");
  //   setRcFilterId("0");
  //   setRcFilterImg("0");
  //   setAmFilterId("0");
  //   setAmFilterImg("0");
  //   setPrFilterId("0");
  //   setPrFilterImg("0");
  //   setCategoryId("0");
  //   setCountryId("0");
  //   setCompanyId("0");
  //   setProjectId("0");
  //   setRoleId("0");
  //   setLanguageId("0");
  //   setStatusId("0");
  //   setDateId("0");
  //   setHireId("0");
  //   setCityId("0");
  //   setJobprofileId("0");
  //   setUpdated_onId("0");
  //   setCreatedAtId("0");
  //   setjobSpecId("0");
  //   setJobSpecFilter("Language");
  //   setjobStatusId("0");
  //   setjobStatusFilter("Status");
  //   setFilterTags([]);
  //   setIsClearAll(true);
  // };

  const restClearAllFilter = async () => {
    setIsClearAll(false);
  };

  return (
    <React.Fragment>
      {commentPopupShow ? (
        <CommentModal
          refTable="livejobs"
          refId={refId}
          count={commentsCount}
          title="Closed Jobs Comments"
          commentHandleClose={commentHandleClose}
          candidateStatus=""
          candidateProgess=""
          candidateStage=""
        />
      ) : null}
      {isProfile ? (
        <DetialViewPoup          
          jobId={jobId}
          show={show}
          onHide={handleClose}
          popupfor="livejobs"
          clientidds={clientidds}
          canjobs={canjobs}
          companyName={companyName}
        />
      ) : null}

      {isEditProfile ? (
        <EditdetialviewPoup
         jobId={jobId}
          show={editShow}
          onHide={handleEditClose}
          popupfor="livejobs"
          clientidds={clientidds}
          companyName={companyName}
        />
      ) : null}
      {isRightFilter ? (
        <RightModalFilter
          list={list}
          selectUserFilter={selectUserFilter}
          selectOtherFilter={selectOtherFilter}
          show={show1}
          onHide={handleClose1}
        />
      ) : null}

      {isUpdatePop ? (
        <UpdatePop
          onHide={hideUpdateValuePop}
          rowId={rowId}
          columnKey={columnKey}
          value={columnValue}
        />
      ) : null}

      <div className="container-fluid dk-cliBoardViewPageCont">
        <div className="row">
          <div className="col-12 col-md-12">
            <div className="dk-qulaifyTopHead">
              <div className="dk-qulaifyLeftTopHead">
                <div className="dk-SrchInputBox mr-2">
                  <div className="input-group">
                    {instanceData !== "0" ? (
                      <GlobalFilter
                        preGlobalFilteredRows={
                          instanceData.preGlobalFilteredRows
                        }
                        globalFilter={instanceData.state.globalFilter}
                        setGlobalFilter={instanceData.setGlobalFilter}
                        isClearAll={isClearAll}
                        restClearAllFilter={restClearAllFilter}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="mr-2 dk-reclivejobsBox">
                  <div className="dk-AccwithNnoDropDwnCon">
                    <AMFilter
                      list={list}
                      id={amFilterId}
                      img={amFilterImg}
                      selectUserFilter={selectUserFilter}
                    />
                    {amFilterId !== "0" ? (
                      <i
                        className="fas fa-times-circle dk-lrftCross"
                        data-type="AMID"
                        onClick={clearUserFilter}
                      ></i>
                    ) : null}
                  </div>
                </div>
                <div className="mr-2 dk-reclivejobsBox">
                  <div className="dk-AccwithNnoDropDwnCon">
                    <RCFilter
                      list={list}
                      id={rcFilterId}
                      img={rcFilterImg}
                      selectUserFilter={selectUserFilter}
                    />
                    {rcFilterId !== "0" ? (
                      <i
                        className="fas fa-times-circle dk-lrftCross"
                        data-type="RCID"
                        onClick={clearUserFilter}
                      ></i>
                    ) : null}
                  </div>
                </div>
                {/* <div className="mr-2 dk-reclivejobsBox">
                  <div className="dk-AccwithNnoDropDwnCon">
                    <CommonRCFilter
                      list={list}
                      id={
                        rcFilterId != "0"
                          ? rcFilterId
                          : r2FilterId != "0"
                          ? r2FilterId
                          : r3FilterId != "0"
                          ? r3FilterId
                          : "0"
                      }
                      img={rcFilterImg}
                      selectUserFilter={selectUserFilter}
                    />
                    {rcFilterId !== "0" ? (
                      <i
                        className="fas fa-times-circle dk-lrftCross"
                        data-type="RCID"
                        onClick={clearUserFilter}
                      ></i>
                    ) : null}
                    {r2FilterId !== "0" ? (
                      <i
                        className="fas fa-times-circle dk-lrftCross"
                        data-type="RAID"
                        onClick={clearUserFilter}
                      ></i>
                    ) : null}                 
                    
                  </div>
                </div> */}
                
                <div className="mr-2 dk-reclivejobsBox">
                  <div className="dk-AccwithNnoDropDwnCon">
                    <RAFilter
                      list={list}
                      id={raFilterId}
                      img={raFilterImg}
                      selectUserFilter={selectUserFilter}
                    />
                    {raFilterId !== "0" ? (
                      <i
                        className="fas fa-times-circle dk-lrftCross"
                        data-type="RAID"
                        onClick={clearUserFilter}
                      ></i>
                    ) : null}
                  </div>
                </div>
                <div className="mr-2 dk-reclivejobsBox">
                  <CreateJobModal />
                </div>
              </div>
              <div className="dk-qulaifyTopHeadTitle">
                <div className="dk-filterTagsCon">
                  {filterTags.length > 0 ? (
                    filterTags.length > 0 &&
                    filterTags.map((item, index) => (
                      <ButtonGroup
                        className="dk-filterbtnGrp mr-2"
                        key={`tagButton${index}`}
                      >
                        <Button className="dk-filterbtntext">
                          {item.type !== "updated_on" &&
                          item.type !== "createdAt" ? (
                            item.value
                          ) : (
                            <CustomDateFormat
                              date={item.value}
                              format="DD MMM, YY"
                            />
                          )}{" "}
                        </Button>
                        <Button
                          className="dk-filterbtnicon"
                          data-type={item.type}
                          onClick={removeTag}
                        >
                          <i
                            className="fas fa-times"
                            data-type={item.type}
                            onClick={removeTag}
                          ></i>
                        </Button>
                      </ButtonGroup>
                    ))
                  ) : (
                    <h3 className="title" style={{ fontSize: "22px" }}>
                      CLOSED JOBS - {data.length} ({showTotalJob})
                    </h3>
                  )}
                </div>
              </div>
              <div className="dk-qulaifyRightTopHead">
                <button className="dk-cricleBtn mr-2" onClick={showrightFilter}>
                  <i className="fa fa-filter"></i>
                </button>
                <div className="dk-qulaifyRightFilter mr-2">
                  <JobStatus
                    list={list}
                    jobStatusFilter={jobStatusFilter}
                    selectOtherFilter={selectOtherFilter}
                    tableType="LiveJobs"
                  />
                  {jobStatusFilter !== "Status" ? (
                    <i
                      className="fas fa-times-circle crossUserFilter statusFilter dk-lrftCross"
                      style={{ right: "250px" }}
                      data-type="status"
                      onClick={removeTag}
                    ></i>
                  ) : null}
                </div>

                <div className="dk-qulaifyRightFilter mr-2">
                  <JobValues
                    list={list}
                    jobStatusFilter={jobValuesFilter}
                    selectOtherFilter={selectOtherFilter}
                    tableType="LiveJobs"
                  />
                  {jobValuesFilter !== "Values" ? (
                    <i
                      className="fas fa-times-circle crossUserFilter statusFilter dk-lrftCross"
                      style={{ right: "124px" }}
                      data-type="values"
                      onClick={removeTag}
                    ></i>
                  ) : null}
                </div>

                {/* <div className="dk-qulaifyRightFilter mr-2">
                  <DropdownButton
                    className="StatusdropdownFilter"
                    id="dropdown-basic-button"
                    title="Values"
                  >
                    <Dropdown.Item href="#"
                      style={{ backgroundColor: "#30887e", color: "#fff" }}
                      data-type="values"
                      data-value="High"
                    >
                      High
                      <span
                        className="dropdown-filter-count"
                        data-type="values"
                        data-value="High"
                      >
                        10
                      </span>
                    </Dropdown.Item>
                    <Dropdown.Item href="#"
                      style={{ backgroundColor: "#d58b5d", color: "#fff" }}
                      data-type="values"
                      data-value="Mid"
                    >
                      Mid
                      <span
                        className="dropdown-filter-count"
                        data-type="values"
                        data-value="Mid"
                      >
                        6
                      </span>
                    </Dropdown.Item>
                    <Dropdown.Item href="#"
                      style={{ backgroundColor: "#a84d4d", color: "#fff" }}
                      data-type="values"
                      data-value="Low"
                    >
                      Low
                      <span
                        className="dropdown-filter-count"
                        data-type="values"
                        data-value="Low"
                      >
                        5
                      </span>
                    </Dropdown.Item>
                  </DropdownButton>
                </div> */}

                <div className="dk-qulaifyRightFilter">
                  <JobSpecLanguge
                    list={list}
                    jobSpecFilter={jobSpecFilter}
                    selectOtherFilter={selectOtherFilter}
                  />
                  {jobSpecFilter !== "Language" ? (
                    <i
                      className="fas fa-times-circle crossUserFilter dk-lrftCross"
                      data-type="language"
                      onClick={removeTag}
                    ></i>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid dk-candinatePipeMain page-margin-top dkg-table-view-mainCon dkg-pipelines-main-tableCon ">
        <div className="row">
          <div className="col-md-12">
            <div className="pipe-newCVTableCon table-responsive">
              <Table
                data={data}
                columns={columns}
                getInstanceCallback={reactTableInstance}
                rmFilterId={rmFilterId}
                raFilterId={raFilterId}
                rc3FilterId={rc3FilterId}
                amFilterId={amFilterId}
                prFilterId={prFilterId}
                localityId={localityId}
                companyId={companyId}
                categoryId={categoryId}
                languageId={languageId}
                projectId={projectId}
                roleId={roleId}
                cityId={cityId}
                hireId={hireId}
                statusId={statusId}
                valuesId={valuesId}
                rcFilterId={rcFilterId}
                dateId={dateId}
                jobSpecId={jobSpecId}
                jobStatusId={jobStatusId}
                loading={isLoading}
                countryId={countryId}
                jobprofileId={jobprofileId}
                updated_onId={updated_onId}
                createdAtId={createdAtId}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className={`batch-actions-menu-wrapper ${
          isActive ? "activePopup" : ""
        }`}
        style={{ width: "216px" }}
      >
        <div className="num-of-actions_wrapper">
          <div className="num-of-actions">{countCheck}</div>
        </div>
        <div className="batch-actions-title-section pl-0">
          <div className="dk-rowSelected">
            <span>Row Selected</span>
          </div>
        </div>
        {/* <div className="batch-actions-item dk-checkAll" onClick={toggleCheckAll}>
          <span><i className="fas fa-check"></i></span>
          {checkBtnTxt}
        </div> */}
        {/* <div className="batch-actions-item closed-checkboxbg" data-moveto="Closed" onClick={moveRecords}>
          <span data-moveto="Closed" onClick={moveRecords}>
            <i className="fa fa-arrow-circle-right" data-moveto="Closed" onClick={moveRecords}></i>
          </span>
          Archived
        </div> */}
        <div
          className="batch-actions-item archived-checkboxbg"
          data-moveto="Archive"
          style={{ backgroundColor: "#6a0707", color: "#fff" }}
          onClick={moveRecords}
        >
          <span data-moveto="Archive" onClick={moveRecords}>
            <i
              className="fa fa-arrow-circle-right"
              data-moveto="Archive"
              onClick={moveRecords}
            ></i>
          </span>
          Delete
        </div>
        <div
          className="batch-actions-delete-item CloseAll d-flex align-items-center justify-content-center"
          onClick={handleToggle}
        >
          <span>
            <i className="fas fa-times"></i>
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ClosedJob;
