import React from "react";
import { Link, useHistory } from "react-router-dom";
import "./module.scss";
import Recruitment from "../../../assets/images/building_icon.png";
import FaqIcon from "../../../assets/images/information_white.png";
// import Ads from "../../../assets/images/ads.png";
import JobSpecIcon from "../../../assets/images/information.png";

// import Resource from "../../../assets/images/login-white.png";
import Projections from "../../../assets/images/excel-file.png";
// import Training from "../../../assets/images/prepare-interview.png";
import ProjectsImg from "../../../assets/images/projects.png";
import SearchCVDatabase from "../../../assets/images/database_icon.png";
import DataTableIcon from "../../../assets/images/database-tables.png";
// import Logins from "../../../assets/images/login.png";
//import Visa from '../../../assets/images/visa.png'
//import DkGlobal from '../../../assets/images/dk_icon_white.png'
// import RecruitmentRule from "../../../assets/images/recruit.png";
import InterviewIcon from "../../../assets/images/interview-icon-white.png";
// import DKGCloud from "../../../assets/images/cloud-data.png";
//import ItTeamImg from '../../../assets/images/itteam.png'
import JobspecImg from "../../../assets/images/job-specs.png";
import { useDispatch, useSelector } from "react-redux";
import { setMyModule } from "../../../slice/modules/moduleSlice";
import Moduleheader from "../../elements/header/index";
// import ModuleNavTabs from "../trainingmodule/navtabs/ModuleNavTabs";

const Module = () => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);

  const history = useHistory();
  const setModule = async (myModule, moduleColor) => {
    await dispatch(setMyModule({ module: myModule, color: moduleColor }));
    switch (myModule) {
      case "Recruitment":
        history.push("/recruitment/company/summary");
        break;

      case "Clients Info":
        history.push("/clientsinfo/policies");
        break;

      case "Marketing":
        history.push("/marketing/summary");
        break;

      case "II Team":
        history.push("/visit-website");
        break;

      case "ADS":
        history.push("/recruitment/ads/summary");
        break;

      case "Accounting":
        history.push("/accounting/summary");
        break;

      case "Knowleadge":
        history.push("/knowleadge/summary");
        break;

      case "Projections":
        history.push("/projections/summary");
        break;

      case "Holidays":
        history.push("/hr/dashboard");
        break;

      case "Payments":
        history.push("/payments/summary");
        break;

      case "Myfiles":
        history.push("/my-files/dashboard");
        break;

      case "projects":
        history.push("/projects/dashboard");
        break;

      case "Jobspecs":
        history.push("/job-spec/dashboard");
        break;

      case "KPI":
        history.push("/kpi/dashboard");
        break;

      case "Database":
        history.push("/database/summary");
        break;

      case "DatabaseTable":
        history.push("/db-table/search-db");
        break;

      case "FullDatabase":
        history.push("/full-database/search-cv");
        break;

      case "Visainfo":
        history.push("/visa-info/summary");
        break;

      // case 'SalesTips':
      //     history.push("/sales-tips/summary");
      //     break;

      case "dkglobal":
        history.push("/dkglobal/information");
        break;

      case "Recruitmenttips":
        history.push("/recruitment-tips/summary");
        break;

      case "DKGCloud":
        history.push("/recruitment/dkg-cloud/summary");
        break;

      case "recruitment-faq":
        history.push("/recruitment/faq/dashboard");
        break;

      default:
        break;
    }
  };
  return (
    <>
      <Moduleheader
        dashboardheaderLink="Dashboard -"
        dashboardLink="/dashboard/plateform"
        linkcolor="#3c4c62"
        mainheaderTitle="Recruitment"
        textcolor="#3c4c62"
      />
      <div className="dk-master-moduleCon">
        <div className="dk-module-blocksContainer dk-crmContainer">
          <h4 className="title">RECRUITMENT DEPARTMENT</h4>
          <div className="dk-portalCols">
            <div className="dk-portalBox dk_portalBox_1">
              {/* <Link to="#" onClick={() => setModule("Recruitment", "#26596d")}> */}
              <Link to="#">
                <div>
                  <div className="dk-Portalicons">
                    <img src={Recruitment} alt="Recruitment" />
                  </div>
                  <div className="dk-PortalTitle">Clients Info</div>
                </div>
              </Link>
            </div>
            <div className="dk-portalBox dk_portalBox_10">
              <Link to="#" onClick={() => setModule("projects", "#26596d")}>
                <div>
                  <div className="dk-Portalicons">
                    <img src={ProjectsImg} className="img-fluid" alt="" />
                  </div>
                  <div className="dk-PortalTitle">Projects</div>
                </div>
              </Link>
            </div>
            <div className="dk-portalBox dk_portalBox_6">
              <Link to="#" onClick={() => setModule("Projections", "#26596d")}>
                <div>
                  <div className="dk-Portalicons">
                    <img src={Projections} className="img-fluid" alt="" />
                  </div>
                  <div className="dk-PortalTitle">Projections</div>
                </div>
              </Link>
            </div>
          </div>
          <div className="dk-portalCols">
            <div className="dk-portalBox dk_portalBox_marketing">
              <Link to="#" onClick={() => setModule("Jobspecs", "#26596d")}>
                <div>
                  <div className="dk-Portalicons">
                    <img src={JobSpecIcon} alt="Job Spec" />
                  </div>
                  <div className="dk-PortalTitle">Job Spec</div>
                </div>
              </Link>
            </div>
            <div className="dk-portalBox dk_recruitmentrules_bg">
              <Link
                to="#"
                onClick={() => setModule("Recruitmenttips", "#26596d")}
              >
                <div>
                  <div className="dk-Portalicons">
                    <img src={InterviewIcon} className="img-fluid" alt="" />
                  </div>
                  <div className="dk-PortalTitle">Interview Spec</div>
                </div>
              </Link>
            </div>
            <div className="dk-portalBox dk_visainfo_bg">
              <Link to="#" onClick={() => setModule("Visainfo", "#26596d")}>
                <div>
                  <div className="dk-Portalicons">
                    <img src={JobspecImg} className="img-fluid" alt="" />
                  </div>
                  <div className="dk-PortalTitle">Job Offer Spec</div>
                </div>
              </Link>
            </div>
          </div>
          <div className="dk-portalCols">
            <div className="dk-portalBox dk_portalBox_2">
              <Link
                to="#"
                onClick={() => setModule("DatabaseTable", "#26596d")}
              >
                <div>
                  <div className="dk-Portalicons">
                    <img src={SearchCVDatabase} className="img-fluid" alt="Search CV Database" />
                  </div>
                  <div className="dk-PortalTitle">Search Database</div>
                </div>
              </Link>
            </div>
            <div className="dk-portalBox dk_portalBox_onboarding">
              <Link to="#" onClick={() => setModule("FullDatabase", "#26596d")}>
                <div>
                  <div className="dk-Portalicons">
                    <img
                      src={DataTableIcon}
                      className="img-fluid"
                      alt="Full Database"
                    />
                  </div>
                  <div className="dk-PortalTitle">Data Tables</div>
                </div>
              </Link>
            </div>
            <div className="dk-portalBox dk_frlancerresource">
              <Link to="#" onClick={() => setModule("recruitment-faq")}>
                <div>
                  <div className="dk-Portalicons">
                    <img src={FaqIcon} className="img-fluid" alt="" />
                  </div>
                  <div className="dk-PortalTitle">FAQ</div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Module;
