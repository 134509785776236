import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";

const InvoicesMenu = () => {
    const pathname = window.location.pathname;
    const segment1 = pathname.split("/")[1];
    const segment3 = pathname.split("/")[3];

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK MasterBox";
    }, [pathname]);

    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-accountingNavbar dk-dkglobalfinanceExpenseMainMenu">
                <Route>
                    <ul>
                        <li><Link to="/dkglobalfinance/invoices/dashboard" className={(segment3 == 'dashboard') ? 'active' : ''}>Dashboard</Link></li>
                        {/* <li><Link to="/dkglobalfinance/invoices/alerts/my-tasks" className={`${(segment3 == 'alerts') ? 'active' : ''}`}><span className="dk-badge">0</span>Alerts</Link></li> */}
                        <li><Link to="/dkglobalfinance/invoices/saved" className={`${(segment3 == 'saved') ? 'active' : ''}`}>Clients</Link></li>
                        <li><Link to="#/dkglobalfinance/invoices/job-boards" className={`${(segment3 == 'job-boards') ? 'active' : ''}`}>Job Boards</Link></li>
                        <li><Link to="#/dkglobalfinance/invoices/it-services" className={`${(segment3 == 'it-services') ? 'active' : ''}`}>IT Services</Link></li>
                        {/* <li><Link to="#/dkglobalfinance/invoices/clients/overview" className={`${(segment3 == 'clients') ? 'grey-active' : ''}`}>Suppliers</Link></li> */}
                        <li><Link to="#/dkglobalfinance/invoices/bus-services" className={`${(segment3 == 'bus-services') ? 'active' : ''}`}>Bus Services</Link></li>

                        <li><Link to="#/dkglobalfinance/invoices/job-board/overview" className={`${(segment3 == 'job-board') ? 'grey-active' : ''}`}>Employees</Link></li>
                        <li><Link to="/dkglobalfinance/invoices/process/overview" className={`${(segment3 == 'process') ? 'grey-active' : ''}`}>Invoice Process</Link></li>
                        {/*
                        <li><Link to="/dkglobalfinance/invoices/it-services/overview" className={`${(segment3 == 'it-services') ? 'grey-active' : ''}`}>IT Services</Link></li>
                        <li><Link to="/dkglobalfinance/invoices/software/overview" className={`${(segment3 == 'software') ? 'grey-active' : ''}`}>Software</Link></li>
                        <li><Link to="/dkglobalfinance/invoices/call-sms/overview" className={`${(segment3 == 'call-sms') ? 'grey-active' : ''}`}>Calls & SMS</Link></li>
                        <li><Link to="/dkglobalfinance/invoices/accountant/overview" className={`${(segment3 == 'accountant') ? 'grey-active' : ''}`}>Accountant</Link></li>
                        <li><Link to="/dkglobalfinance/invoices/dk-staff/overview" className={`${(segment3 == 'dk-staff') ? 'grey-active' : ''}`}>DK Staff</Link></li>
                        <li><Link to="/dkglobalfinance/invoices/dkglobal/overview" className={`${(segment3 == 'dkglobal') ? 'grey-active' : ''}`}>DK Global</Link></li> */}
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default InvoicesMenu;