import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import "./summary.scss";
import {
  statusNumbersCounts,
  localityNumbersCounts,
  rmNumbersCounts,
  raNumbersCounts,
  rc3NumbersCounts,
  rcNumbersCounts,
  amNumbersCounts,
  categoryNumbersCounts,
  companyNumbersCounts,
  locationNumbersCounts,
  languageNumbersCounts,
  projectNumbersCounts,
  roleNumbersCounts,
  totalJobsNumbersCounts,
  jobProfileNumbersCounts,
  countryNumbersCounts,
  updatedNumbersCounts,
  candidateNumbersCounts,
  workpermitCounts
} from "../../../../../src/slice/jobs/summary/summarySlice";

const Summary = () => {
  const dispatch = useDispatch();
  const {
    statusCount,
    localityCount,
    rmCount,
    raCount,
    rcCount,
    rc3Count,
    amCount,
    categoryCount,
    companyCount,
    locationCount,
    languageCount,
    projectCount,
    roleCount,
    totaljobsCount,
    candidateCount,
    jobCountryCount,
    updatedCount,
    workpermitListCounts
  } = useSelector((state) => state.jobsSummray);



  const array1 = rcCount.filter(item => item.count !== 0).map(item => {
    const { count, assignWithjob } = item;
    const userInfo = assignWithjob[0].userInfo;
    return { count, key: 'user_id', ...userInfo };
  });

  const array2 = raCount.map(({ count, recRA }) => ({ count, key: 'rec_ra', ...recRA }));
  const array3 = rc3Count.map(({ count, rec3rc }) => ({ count, key: 'rc3', ...rec3rc }));

  const mergedArray = [...array1, ...array2, ...array3].reduce((acc, curr) => {
    const id = curr.id;
    if (!acc[id]) {
      acc[id] = { ...curr };
    } else {
      acc[id].count += curr.count;
    }
    return acc;
  }, {});

  const finalArray = Object.values(mergedArray);


  const [filterTags, setFilterTags] = useState([]);
  const [tagLength, setTagLength] = useState(0);

  const [filter, setFilter] = useState({});
  const [recruiterFilter, setRecruiterFilter] = useState({});
  const [companyfilter, setCompanyfilter] = useState({});

  const [updatedFilter, setUpdatedFilter] = useState({});

  const [iscategoryFilter, setIsCategoryFilter] = useState(false);
  const [categoryFilterText, setCategoryFilterText] = useState("");

  const [isupdatedFilter, setIsupdatedFilter] = useState(false);
  const [updatedFilterText, setUpdatedFilterText] = useState("");

  const [iscompanyFilter, setIscompanyFilter] = useState(false);
  const [companyFilterText, setCompanyFilterText] = useState("");

  const [iscountryFilter, setIscountryFilter] = useState(false);
  const [countryFilterText, setCountryFilterText] = useState("");

  const [islocationFilter, setIsLocationFilter] = useState(false);
  const [locationFilterText, setLocationFilterText] = useState("");

  const [islanguageFilter, setIslanguageFilter] = useState(false);
  const [languageFilterText, setLanguageFilterText] = useState("");

  const [isprojectFilter, setIsprojectFilter] = useState(false);
  const [projectFilterText, setProjectFilterText] = useState("");

  const [isroleFilter, setIsroleFilter] = useState(false);
  const [roleFilterText, setRoleFilterText] = useState("");

  const [isstatusFilter, setIsstatusFilter] = useState(false);
  const [statusFilterText, setStatusFilterText] = useState("");

  const [isjobProfileFilter, setIsjobProfileFilter] = useState(false);
  const [jobProfileFilterText, setJobProfileFilterText] = useState("");

  const [isworkPermitFilter, setIsWorkPermitFilter] = useState(false);
  const [workPermitFilterText, setWorkPermitFilterText] = useState("");

  const [islocalityFilter, setIslocalityFilter] = useState(false);
  const [localityFilterText, setLocalityFilterText] = useState("");

  const [israFilter, setIsraFilter] = useState(false);
  const [raFilterText, setRaFilterText] = useState("");

  const [isrmFilter, setIsrmFilter] = useState(false);
  const [rmFilterText, setRmFilterText] = useState("");

  const [isrcFilter, setIsrcFilter] = useState(false);
  const [rcFilterText, setRcFilterText] = useState("");

  const [isamFilter, setIsamFilter] = useState(false);
  const [amFilterText, setAmFilterText] = useState("");

  const [removeFilter, setRemoveFilter] = useState(0);

  const getRequestParams = (filter) => {
    let params = {};
    params["filter"] = filter;
    return params;
  };

  const isEmpty = (obj) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const params = getRequestParams(filter);
        dispatch(statusNumbersCounts(params));
        dispatch(localityNumbersCounts(params));
        dispatch(rc3NumbersCounts(params));
        dispatch(raNumbersCounts(params));
        dispatch(rmNumbersCounts(params));//
        dispatch(rcNumbersCounts(params));
        dispatch(amNumbersCounts(params));
        dispatch(categoryNumbersCounts(params));
        dispatch(companyNumbersCounts(params));
        dispatch(locationNumbersCounts(params));
        dispatch(languageNumbersCounts(params));
        dispatch(projectNumbersCounts(params));
        dispatch(roleNumbersCounts(params));
        dispatch(totalJobsNumbersCounts(params));
        dispatch(candidateNumbersCounts(params));
        dispatch(countryNumbersCounts(params));
        dispatch(updatedNumbersCounts(params));
        dispatch(workpermitCounts(params));
      } catch (err) {
        console.log(err);
      }
    }
    if (isEmpty(filter)) {
      fetchData();
    }
  }, [filter]);

  useEffect(() => {
    if (removeFilter > 0 && isEmpty(filter)) {
      try {
        const params = getRequestParams(filter);
        dispatch(statusNumbersCounts(params));
        dispatch(localityNumbersCounts(params));
        dispatch(rmNumbersCounts(params));
        dispatch(rc3NumbersCounts(params));
        dispatch(raNumbersCounts(params));
        dispatch(rcNumbersCounts(params));
        dispatch(amNumbersCounts(params));
        dispatch(categoryNumbersCounts(params));
        dispatch(companyNumbersCounts(params));
        dispatch(locationNumbersCounts(params));
        dispatch(languageNumbersCounts(params));
        dispatch(projectNumbersCounts(params));
        dispatch(roleNumbersCounts(params));
        dispatch(totalJobsNumbersCounts(params));
        dispatch(candidateNumbersCounts(params));
        dispatch(countryNumbersCounts(params));
        dispatch(updatedNumbersCounts(params));
        dispatch(workpermitCounts(params));
      } catch (err) {
        console.log(err);
      }
    }
  }, [removeFilter]);

  const getFilterRequestParams = (
    recruiterFilter,
    updatedFilter,
    companyfilter
  ) => {
    let params = {};
    params["filter"] = {
      recruiterFilter: recruiterFilter,
      updatedFilter: updatedFilter,
      companyfilter: companyfilter,
    };
    return params;
  };

  const selectFilter = async (e) => {
    const type = e.target.dataset.type;
    const value = e.target.dataset.value;
    // console.log(type)
    // console.log(value)

    let i;
    switch (type) {
      case "category":
        setIsCategoryFilter(true);
        setCategoryFilterText(value);
        setCompanyfilter({ ...companyfilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "updated_on":
        setIsupdatedFilter(true);
        setUpdatedFilterText(value);
        setUpdatedFilter({ ...updatedFilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "company":
        setIscompanyFilter(true);
        setCompanyFilterText(value);
        setCompanyfilter({ ...companyfilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "job_country":
        setIscountryFilter(true);
        setCountryFilterText(value);
        setCompanyfilter({ ...companyfilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "location":
        setIsLocationFilter(true);
        setLocationFilterText(value);
        setCompanyfilter({ ...companyfilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "lang":
        setIslanguageFilter(true);
        setLanguageFilterText(value);
        setCompanyfilter({ ...companyfilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "project":
        setIsprojectFilter(true);
        setProjectFilterText(value);
        setCompanyfilter({ ...companyfilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "role":
        setIsroleFilter(true);
        setRoleFilterText(value);
        setCompanyfilter({ ...companyfilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;
      // case "caseStatus":
      //   setIsstatusFilter(true);
      //   setStatusFilterText(value);
      //   setCompanyfilter({ ...companyfilter, [type]: value });
      //   setFilter({ ...filter, [type]: value });
      //   break;
      case "status":
        setIsstatusFilter(true);
        setStatusFilterText(value);
        // console.log(companyfilter)
        // console.log(value)
        // console.log(filter)
        setCompanyfilter({ ...companyfilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "locality":
        setIslocalityFilter(true);
        setLocalityFilterText(value);
        setCompanyfilter({ ...companyfilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "work_permit":
        setIsWorkPermitFilter(true);
        setWorkPermitFilterText(value);
        setCompanyfilter({ ...companyfilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "user_id":
        setIsrcFilter(true); //rec_ra
        //console.log(e.target.dataset.key);
        setRcFilterText(e.target.dataset.text);
        setRecruiterFilter({ ...recruiterFilter, [type]: value });
        setCompanyfilter({ ...recruiterFilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "rc3":
        setIsrcFilter(true); //rec_ra
        setRcFilterText(e.target.dataset.text);
        setCompanyfilter({ ...companyfilter, ["rc3"]: value });
        setRecruiterFilter({ ...recruiterFilter, ["user_id"]: value });
        setFilter({ ...filter, [type]: value });
        break;
      case "rec_ra":
        setIsrcFilter(true);
        setRcFilterText(e.target.dataset.text);
        setCompanyfilter({ ...companyfilter, ["rc3"]: value });
        setRecruiterFilter({ ...recruiterFilter, ['rec_ra']: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "rec_rm":
        setIsrmFilter(true);
        setRmFilterText(e.target.dataset.text);
        setCompanyfilter({ ...companyfilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "rec_am":
        setIsamFilter(true);
        setAmFilterText(e.target.dataset.text);
        setCompanyfilter({ ...companyfilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "job_l1":
        setIsjobProfileFilter(true);
        setJobProfileFilterText(value);
        setCompanyfilter({ ...companyfilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "job_l2":
        setIsjobProfileFilter(true);
        setJobProfileFilterText(value);
        setCompanyfilter({ ...companyfilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "job_so":
        setIsjobProfileFilter(true);
        setJobProfileFilterText(value);
        setCompanyfilter({ ...companyfilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "job_jo":
        setIsjobProfileFilter(true);
        setJobProfileFilterText(value);
        setCompanyfilter({ ...companyfilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "job_jp":
        setIsjobProfileFilter(true);
        setJobProfileFilterText(value);
        setCompanyfilter({ ...companyfilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      default:
        i = filterTags.findIndex(function (x) {
          return x.type === type;
        });
        if (i === -1) {
          filterTags.push({ type, value });
        }
        setRecruiterFilter({ ...recruiterFilter, [type]: value });
        setUpdatedFilter({ ...updatedFilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;
    }
    setTagLength(tagLength + 1);
    setFilterTags(filterTags);
  };

  useEffect(() => {
    if (!isEmpty(filter)) {
      const params = getFilterRequestParams(
        recruiterFilter,
        updatedFilter,
        companyfilter
      );


      dispatch(statusNumbersCounts(params));
      dispatch(localityNumbersCounts(params));
      dispatch(rmNumbersCounts(params));
      dispatch(rc3NumbersCounts(params));
      dispatch(raNumbersCounts(params));
      dispatch(rcNumbersCounts(params));
      dispatch(amNumbersCounts(params));
      dispatch(categoryNumbersCounts(params));
      dispatch(companyNumbersCounts(params));
      dispatch(locationNumbersCounts(params));
      dispatch(languageNumbersCounts(params));
      dispatch(projectNumbersCounts(params));
      dispatch(roleNumbersCounts(params));
      dispatch(totalJobsNumbersCounts(params));
      dispatch(candidateNumbersCounts(params));
      dispatch(countryNumbersCounts(params));
      dispatch(updatedNumbersCounts(params));
      dispatch(workpermitCounts(params));
    }
  }, [filter, removeFilter]);

  const removeTag = async (e) => {
    const type = e.target.dataset.type;
    switch (type) {
      case "category":
        setIsCategoryFilter(false);
        setCategoryFilterText("");
        delete companyfilter[type];
        delete filter[type];
        setCompanyfilter(companyfilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "updated_on":
        setIsupdatedFilter(false);
        setUpdatedFilterText("");
        delete updatedFilter[type];
        delete filter[type];
        setUpdatedFilter(updatedFilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "company":
        setIscompanyFilter(false);
        setCompanyFilterText("");
        delete companyfilter[type];
        delete filter[type];
        setCompanyfilter(companyfilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "job_country":
        setIscountryFilter(false);
        setCountryFilterText("");
        delete companyfilter[type];
        delete filter[type];
        setCompanyfilter(companyfilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "location":
        setIsLocationFilter(false);
        setLocationFilterText("");
        delete companyfilter[type];
        delete filter[type];
        setCompanyfilter(companyfilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "lang":
        setIslanguageFilter(false);
        setLanguageFilterText("");
        delete companyfilter[type];
        delete filter[type];
        setCompanyfilter(companyfilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "project":
        setIsprojectFilter(false);
        setProjectFilterText("");
        delete companyfilter[type];
        delete filter[type];
        setCompanyfilter(companyfilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "role":
        setIsroleFilter(false);
        setRoleFilterText("");
        delete companyfilter[type];
        delete filter[type];
        setCompanyfilter(companyfilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "rec_am":
        setIsamFilter(false);
        setAmFilterText("");
        delete companyfilter[type];
        delete filter[type];
        setCompanyfilter(companyfilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "rec_rm":
        setIsrmFilter(false);
        setRmFilterText("");
        delete companyfilter[type];
        delete filter[type];
        setCompanyfilter(companyfilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "rc3":
        setIsraFilter(false);
        setRaFilterText("");
        delete companyfilter[type];
        delete filter[type];
        setCompanyfilter(companyfilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;
      case "rec_ra":
        setIsraFilter(false);
        setRaFilterText("");
        delete companyfilter[type];
        delete filter[type];
        setCompanyfilter(companyfilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "user_id":
        setIsrcFilter(false);
        setRcFilterText("");
        delete recruiterFilter[type];
        delete filter[type];
        setRecruiterFilter(recruiterFilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "locality":
        setIslocalityFilter(false);
        setLocalityFilterText("");
        delete companyfilter[type];
        delete filter[type];
        setCompanyfilter(companyfilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "work_permit":
        setIsWorkPermitFilter(false);
        setWorkPermitFilterText("");
        delete companyfilter[type];
        delete filter[type];
        setCompanyfilter(companyfilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      // case "caseStatus":
      //   setIsstatusFilter(false);
      //   setStatusFilterText("");
      //   delete companyfilter[type];
      //   delete filter[type];
      //   setCompanyfilter(companyfilter);
      //   setFilter(filter);
      //   setRemoveFilter(removeFilter + 1);
      //   break;
      case "status":
        setIsstatusFilter(false);
        setStatusFilterText("");
        delete companyfilter[type];
        delete filter[type];
        setCompanyfilter(companyfilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "job_profile":
        setIsjobProfileFilter(false);
        setJobProfileFilterText("");
        delete companyfilter[type];
        delete filter[type];
        setCompanyfilter(companyfilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "job_l1":
        setIsjobProfileFilter(false);
        setJobProfileFilterText("");
        delete companyfilter[type];
        delete filter[type];
        setCompanyfilter(companyfilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "job_l2":
        setIsjobProfileFilter(false);
        setJobProfileFilterText("");
        delete companyfilter[type];
        delete filter[type];
        setCompanyfilter(companyfilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "job_so":
        setIsjobProfileFilter(false);
        setJobProfileFilterText("");
        delete companyfilter[type];
        delete filter[type];
        setCompanyfilter(companyfilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "job_jo":
        setIsjobProfileFilter(false);
        setJobProfileFilterText("");
        delete companyfilter[type];
        delete filter[type];
        setCompanyfilter(companyfilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "job_jp":
        setIsjobProfileFilter(false);
        setJobProfileFilterText("");
        delete companyfilter[type];
        delete filter[type];
        setCompanyfilter(companyfilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      default:
        delete recruiterFilter[type];
        delete filter[type];
        setCompanyfilter(recruiterFilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;
    }
    setTagLength(tagLength - 1);
    setFilterTags(filterTags.filter((item) => item.type !== type));
  };

  var allCandidate = candidateCount.map((data, index) => {
    return (
      <>
        <li
          data-type="job_l1"
          data-value={data.job_l1}
          style={{ background: "#808080", color: "#fff" }}
        >
          Level 1
          <span
            className="mlCount"
            data-type="job_l1"
            data-value={data.job_l1}
            style={{ color: "#fff" }}
          >
            {data.job_l1Count}
          </span>
        </li>
        <li
          data-type="job_l1"
          data-value={data.job_l1}
          style={{ background: "#715f8f", color: "#fff" }}
        >
          Level 2
          <span
            className="mlCount"
            data-type="job_l1"
            data-value={data.job_l1}
            style={{ color: "#fff" }}
          >
            {data.job_l1Count}
          </span>
        </li>

        <li
          data-type="job_l2"
          data-value={data.job_l2}
          style={{ background: "#57496d", color: "#fff" }}
        >
          Level 3
          <span
            className="mlCount"
            data-type="job_l2"
            data-value={data.job_l2}
            style={{ color: "#fff" }}
          >
            {data.job_l2Count}
          </span>
        </li>

        <li
          data-type="job_so"
          data-value={data.job_so}
          style={{ background: "#316a67", color: "#fff" }}
        >
          Level 4
          <span
            className="mlCount"
            data-type="job_so"
            data-value={data.job_so}
            style={{ color: "#fff" }}
          >
            {data.job_soCount}
          </span>
        </li>

        <li
          data-type="job_jo"
          data-value={data.job_jo}
          style={{ background: "#629bd3", color: "#fff" }}
        >
          Level 5
          <span
            className="mlCount"
            data-type="job_jo"
            data-value={data.job_jo}
            style={{ color: "#fff" }}
          >
            {data.job_joCount}
          </span>
        </li>

        <li
          data-type="job_jp"
          data-value={data.job_jp}
          style={{ background: "#4768b0", color: "#fff" }}
        >
          Level 6
          <span
            className="mlCount"
            data-type="job_jp"
            data-value={data.job_jp}
            style={{ color: "#fff" }}
          >
            {data.job_jpCount}
          </span>
        </li>
      </>
    );
  });

  var levels = statusCount.map((data, index) => {
    let status = data.status;
    let bgColor = "#fff";
    switch (status) {
      case "Normal":
        bgColor = "#30887e";
        break;
      case "Urgent":
        bgColor = "#dc5539";
        break;
      case "To qualify":
        bgColor = "#D58B5D";
        break;

      case "On Hold":
        bgColor = "#7f5347";
        break;
    }
    return (
      <li
        key={index + 1}
        data-type="status"
        data-value={data.status}
        onClick={selectFilter}
      >
        <div
          className="leftItem"
          data-type="status"
          data-value={data.status}
          onClick={selectFilter}
        >
          <i style={{ color: bgColor }} className="ml-1 fa fa-circle"></i>{" "}
          {data.status}
        </div>
        <span
          className="iTCount"
          data-type="status"
          data-value={data.status}
          onClick={selectFilter}
        >
          {data.count}
        </span>
      </li>
    );
  });

  let today = 0;
  let yesterday = 0;
  let notUpdate = 0;
  updatedCount.map((data, index) => {
    if (moment().format("YYYY-MM-DD") == data.uDate) {
      today += data.count;
    }
    if (moment().subtract(1, "days").format("YYYY-MM-DD") == data.uDate) {
      yesterday += data.count;
    }

    if (
      moment().subtract(1, "days").format("YYYY-MM-DD") != data.uDate &&
      moment().format("YYYY-MM-DD") != data.uDate
    ) {
      notUpdate += data.count;
    }
  });

  return (
    <>
      <div className="container-fluid dk-jobsSummMainPageCon">
        <div
          className="row"
          style={{ marginRight: "0", marginLeft: "0" }}
        >
          <div className="col-md-12 p-0">
            {/* <div className="dk-jobsuMMTopHead">
                            <div className="dk-jobsuMMTopCntre">
                                <h2 className="title">JOBS SUMMARY</h2>
                            </div>
                        </div> */}
            <div className="dk-jobsuMMBoxCon">
              <div className="row">
                <div className="col-md-12">
                  <div className="dk-jobsLeftBoxCon mb-4">
                    <div className="dk-jobsLeftTopBoxCols">
                      <div
                        className={`headerCon ${!isstatusFilter ? "" : "headerCon-filterBg"
                          }`}
                      >
                        {!statusFilterText ? (
                          <div className="title">Status</div>
                        ) : (
                          <div className="dk-dbsuMM-filterHeader">
                            {statusFilterText}
                            <span>
                              <i
                                className="fa fa-times-circle  ml-2"
                                data-type="status"
                                onClick={removeTag}
                              ></i>
                            </span>
                          </div>
                        )}
                        <span className="totalCount">
                          {statusCount.reduce(
                            (total, currentValue) =>
                              (total = total + currentValue.count),
                            0
                          )}
                        </span>
                      </div>
                      <ul className="dk-top-headertitlelist dk-nohoveronlist">
                        {/* {levels} */}
                        {
                          (statusCount.filter(function (val) {
                            return val.status === 'Active';
                          }).length !== 0) ?
                            <li style={{ backgroundColor: "#30887e", color: "#fff", borderBottom: "none" }} data-type="status" data-value="Active" onClick={selectFilter}>
                              <div className="recuriterItem d-flex align-items-center" data-type="status" data-value="Active" onClick={selectFilter}>
                                Active 
                              </div>
                              <span className="caseStatusCounter" data-type="status" data-value="Active" onClick={selectFilter}>
                                {
                                  statusCount.find(item => item.status === "Active").count
                                }
                              </span>
                            </li> : null
                        }
                        
                        {
                          (statusCount.filter(function (val) {
                            return val.status === 'Qualify';
                          }).length !== 0) ? <li style={{ backgroundColor: "#D58B5D", color: "#fff", borderBottom: "none" }} data-type="status" data-value="Qualify" onClick={selectFilter}>
                            <div className="recuriterItem d-flex align-items-center" data-type="status" data-value="Qualify" onClick={selectFilter}>
                              Qualify
                            </div>
                            <span className="caseStatusCounter" data-type="status" data-value="Qualify" onClick={selectFilter}>
                              {
                                statusCount.find(item => item.status === "Qualify").count
                              }
                            </span>
                          </li> : null
                        }
                        {
                          (statusCount.filter(function (val) {
                            return val.status === 'On Hold';
                          }).length !== 0) ? <li style={{ backgroundColor: "#7f5347", color: "#fff", borderBottom: "none" }} data-type="status" data-value="On Hold" onClick={selectFilter}>
                            <div className="recuriterItem d-flex align-items-center" data-type="status" data-value="On Hold" onClick={selectFilter}>
                              On Hold
                            </div>
                            <span className="caseStatusCounter" data-type="status" data-value="On Hold" onClick={selectFilter}>
                              {
                                statusCount.find(item => item.status === "On Hold").count
                              }
                            </span>
                          </li> : null
                        }
                        {
                          (statusCount.filter(function (val) {
                            return val.status === 'Inactive';
                          }).length !== 0) ?
                            <li style={{ backgroundColor: "#B55355", color: "#fff", borderBottom: "none" }} data-type="status" data-value="Inactive" onClick={selectFilter}>
                              <div className="recuriterItem d-flex align-items-center" data-type="status" data-value="Inactive" onClick={selectFilter}>
                                Inactive
                              </div>
                              <span className="caseStatusCounter" data-type="status" data-value="Inactive" onClick={selectFilter}>
                                {
                                  statusCount.find(item => item.status === "Inactive").count
                                }
                              </span>
                            </li> : null
                        }

                        {
                          (statusCount.filter(function (val) {
                            return val.status === 'Closed';
                          }).length !== 0) ?
                            <li style={{ backgroundColor: "#de2222", color: "#fff", borderBottom: "none" }} data-type="status" data-value="Closed" onClick={selectFilter}>
                              <div className="recuriterItem d-flex align-items-center" data-type="status" data-value="Closed" onClick={selectFilter}>
                                Closed 
                              </div>
                              <span className="caseStatusCounter" data-type="status" data-value="Closed" onClick={selectFilter}>
                                {
                                  statusCount.find(item => item.status === "Closed").count
                                }
                              </span>
                            </li> : null
                        }
                        {/* {
                          (statusCount.filter(function (val) {
                            return val.status === 'closed';
                          }).length !== 0) ? <li style={{ backgroundColor: "#e2445c", color: "#fff", borderBottom: "none" }} data-type="status" data-value="closed" onClick={selectFilter}>
                            <div className="recuriterItem d-flex align-items-center" data-type="status" data-value="closed" onClick={selectFilter}>
                              Closed
                            </div>
                            <span className="caseStatusCounter" data-type="status" data-value="closed" onClick={selectFilter}>
                              {
                                statusCount.find(item => item.status === "closed").count
                              }
                            </span>
                          </li> : null
                        } */}
                      </ul>
                    </div>
                    <div className="dk-jobsLeftTopBoxCols">
                      <div
                        className={`headerCon ${!iscategoryFilter ? "" : "headerCon-filterBg"
                          }`}
                      >
                        {!categoryFilterText ? (
                          <div className="title">VALUES</div>
                        ) : (
                          <div className="dk-dbsuMM-filterHeader">
                            {categoryFilterText}
                            <span>
                              <i
                                className="fa fa-times-circle  ml-2"
                                data-type="category"
                                onClick={removeTag}
                              ></i>
                            </span>
                          </div>
                        )}
                        <span className="totalCount">
                          {categoryCount.reduce(
                            (total, currentValue) =>
                              (total = total + currentValue.count),
                            0
                          )}
                        </span>
                      </div>
                      <ul className="dk-top-headertitlelist dk-nohoveronlist">
                        <li data-type="Values" data-value="" style={{backgroundColor: "#30887e" , color: "#fff"}}>
                          <div className="recuriterItem d-flex align-items-center" data-type="values" data-value="High" >
                            High
                          </div>
                          <span className="caseStatusCounter" data-type="values" data-value="High">
                            0
                          </span>
                        </li>
                        <li  data-type="Values" data-value="" style={{backgroundColor: "#d58b5d" , color: "#fff"}}>
                          <div className="recuriterItem d-flex align-items-center" data-type="values" data-value="Mid" >
                            Mid
                          </div>
                          <span className="caseStatusCounter" data-type="values" data-value="Mid">
                            0
                          </span>
                        </li> 
                        <li data-type="Values" data-value="" style={{backgroundColor: "#a84d4d" , color: "#fff"}}>
                          <div className="recuriterItem d-flex align-items-center" data-type="values" data-value="Low" >
                            Low
                          </div>
                          <span className="caseStatusCounter" data-type="values" data-value="Low">
                            0
                          </span>
                        </li> 
                      </ul>  
                    </div>
                    <div className="dk-jobsLeftTopBoxCols">
                      <div
                        className={`headerCon ${!isamFilter ? "" : "headerCon-filterBg"
                          }`}
                      >
                        {!amFilterText ? (
                          <div className="title">AM</div>
                        ) : (
                          <div className="dk-dbsuMM-filterHeader">
                            {amFilterText}
                            <span>
                              <i
                                className="fa fa-times-circle  ml-2"
                                data-type="rec_am"
                                onClick={removeTag}
                              ></i>
                            </span>
                          </div>
                        )}
                        <span className="totalCount">
                          {amCount.reduce(
                            (total, currentValue) =>
                              (total = total + currentValue.count),
                            0
                          )}
                        </span>
                      </div>
                      <ul className="dk-top-headertitlelist">
                        {amCount.map((data, index) => (
                          <li
                            key={index + 1}
                            data-type="rec_am"
                            data-text={
                              data.recAM.firstName + " " + data.recAM.lastName
                            }
                            data-value={data.recAM.id}
                            onClick={selectFilter}
                          >
                            <div
                              className="leftItem"
                              data-type="rec_am"
                              data-text={
                                data.recAM.firstName + " " + data.recAM.lastName
                              }
                              data-value={data.recAM.id}
                              onClick={selectFilter}
                            >
                              <img
                                src={
                                  process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                                  data.recAM.profileImage
                                }
                                className="recImg"
                                data-type="rec_am"
                                data-text={
                                  data.recAM.firstName +
                                  " " +
                                  data.recAM.lastName
                                }
                                data-value={data.recAM.id}
                                onClick={selectFilter}
                              />
                              {data.recAM.firstName != "No User"
                                ? data.recAM.firstName
                                : ""}{" "}
                              {data.recAM.lastName != "Selected"
                                ? data.recAM.lastName
                                : ""}
                            </div>
                            <span
                              className="mlCount"
                              data-type="rec_am"
                              data-text={
                                data.recAM.firstName + " " + data.recAM.lastName
                              }
                              data-value={data.recAM.id}
                              onClick={selectFilter}
                            >
                              {data.count}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div className="dk-jobsLeftTopBoxCols">
                      <div
                        className={`headerCon ${!isrcFilter ? "" : "headerCon-filterBg"
                          }`}
                      >
                        {!rcFilterText ? (
                          <div className="title">RC1 </div>
                        ) : (
                          <div className="dk-dbsuMM-filterHeader">
                            {rcFilterText}
                            <span>
                              <i
                                className="fa fa-times-circle  ml-2"
                                data-type="user_id"
                                onClick={removeTag}
                              ></i>
                            </span>
                          </div>
                        )}
                        <span className="totalCount">
                          {finalArray.reduce(
                            (total, currentValue) =>
                              (total = total + currentValue.count),
                            0
                          )}
                        </span>
                      </div>
                      {/* <ul className="dk-top-headertitlelist">
                        {rcCount.map((data, index) =>
                          data.assignWithjob.length ? (
                            <li
                              key={index + 1}
                              data-type="user_id"
                              data-text={
                                data.assignWithjob[0].userInfo.firstName +
                                " " +
                                data.assignWithjob[0].userInfo.lastName
                              }
                              data-value={data.assignWithjob[0].userInfo.id}
                              onClick={selectFilter}
                            >
                              <div
                                className="leftItem"
                                data-type="user_id"
                                data-text={
                                  data.assignWithjob[0].userInfo.firstName +
                                  " " +
                                  data.assignWithjob[0].userInfo.lastName
                                }
                                data-value={data.assignWithjob[0].userInfo.id}
                                onClick={selectFilter}
                              >
                                <img
                                  src={
                                    process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                                    data.assignWithjob[0].userInfo.profileImage
                                  }
                                  className="recImg"
                                  data-type="user_id"
                                  data-text={
                                    data.assignWithjob[0].userInfo.firstName +
                                    " " +
                                    data.assignWithjob[0].userInfo.lastName
                                  }
                                  data-value={data.assignWithjob[0].userInfo.id}
                                  onClick={selectFilter}
                                />
                                {data.assignWithjob[0].userInfo.firstName !=
                                  "No User"
                                  ? data.assignWithjob[0].userInfo.firstName
                                  : ""}{" "}
                                {data.assignWithjob[0].userInfo.lastName !=
                                  "Selected"
                                  ? data.assignWithjob[0].userInfo.lastName
                                  : ""}
                              </div>
                              <span
                                className="mlCount"
                                data-type="user_id"
                                data-text={
                                  data.assignWithjob[0].userInfo.firstName +
                                  " " +
                                  data.assignWithjob[0].userInfo.lastName
                                }
                                data-value={data.assignWithjob[0].userInfo.id}
                                onClick={selectFilter}
                              >
                                {data.count}
                              </span>
                            </li>
                          ) : (
                            ""
                          )
                        )}
                      </ul> */}
                      <ul className="dk-top-headertitlelist">
                        {finalArray.map((data, index) => (
                          <li
                            key={index + 1}
                            //data-type="user_id"
                            data-type={data.key}
                            data-text={
                              data.firstName + " " + data.lastName
                            }
                            data-value={data.id}
                            onClick={selectFilter}
                          >
                            <div
                              className="leftItem"
                              //data-type="user_id"
                              data-type={data.key}
                              data-text={
                                data.firstName + " " + data.lastName
                              }
                              data-value={data.id}
                              onClick={selectFilter}
                            >
                              <img
                                src={
                                  process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                                  data.profileImage
                                }
                                className="recImg"
                                //data-type="user_id"
                                data-type={data.key}
                                data-text={
                                  data.firstName +
                                  " " +
                                  data.lastName
                                }
                                data-value={data.id}
                                onClick={selectFilter}
                              />
                              {data.firstName != "No User"
                                ? data.firstName
                                : ""}{" "}
                              {data.lastName != "Selected"
                                ? data.lastName
                                : ""}
                            </div>
                            <span
                              className="mlCount"
                              // //data-type="user_id"
                              data-type={data.key}
                              data-text={
                                data.firstName + " " + data.lastName
                              }
                              data-value={data.id}
                              onClick={selectFilter}
                            >
                              {data.count}
                            </span>
                          </li>
                        ))}
                      </ul>

                    </div>
                    <div className="dk-jobsLeftTopBoxCols">
                      <div
                        className={`headerCon ${
                          !israFilter ? "" : "headerCon-filterBg"
                        }`}
                      >
                        {!raFilterText ? (
                          <div className="title">RC 2</div>
                        ) : (
                          <div className="dk-dbsuMM-filterHeader">
                            {raFilterText}
                            <span>
                              <i
                                className="fa fa-times-circle  ml-2"
                                data-type="rec_ra"
                                onClick={removeTag}
                              ></i>
                            </span>
                          </div>
                        )}
                        <span className="totalCount">
                          {raCount.reduce(
                            (total, currentValue) =>
                              (total = total + currentValue.count),
                            0
                          )}
                        </span>
                      </div>
                      <ul className="dk-top-headertitlelist">
                        {raCount.map((data, index) => (
                          <li
                            key={index + 1}
                            data-type="rec_ra"
                            data-text={
                              data.recRA.firstName + " " + data.recRA.lastName
                            }
                            data-value={data.recRA.id}
                            onClick={selectFilter}
                          >
                            <div
                              className="leftItem"
                              data-type="rec_ra"
                              data-text={
                                data.recRA.firstName + " " + data.recRA.lastName
                              }
                              data-value={data.recRA.id}
                              onClick={selectFilter}
                            >
                              <img
                                src={
                                  process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                                  data.recRA.profileImage
                                }
                                className="recImg"
                                data-type="rec_ra"
                                data-text={
                                  data.recRA.firstName +
                                  " " +
                                  data.recRA.lastName
                                }
                                data-value={data.recRA.id}
                                onClick={selectFilter}
                              />
                              {data.recRA.firstName != "No User"
                                ? data.recRA.firstName
                                : ""}{" "}
                              {data.recRA.lastName != "Selected"
                                ? data.recRA.lastName
                                : ""}
                            </div>
                            <span
                              className="mlCount"
                              data-type="rec_ra"
                              data-text={
                                data.recRA.firstName + " " + data.recRA.lastName
                              }
                              data-value={data.recRA.id}
                              onClick={selectFilter}
                            >
                              {data.count}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="dk-jobsLeftTopBoxCols">
                      <div
                        className={`headerCon ${!isupdatedFilter ? "" : "headerCon-filterBg"
                          }`}
                      >
                        {!updatedFilterText ? (
                          <div className="title">DEMAND CAME</div>
                        ) : (
                          <div className="dk-dbsuMM-filterHeader">
                            {updatedFilterText}
                            <span>
                              <i
                                className="fa fa-times-circle  ml-2"
                                data-type="updated_on"
                                onClick={removeTag}
                              ></i>
                            </span>
                          </div>
                        )}
                        <span className="totalCount">
                          {updatedCount.reduce(
                            (total, currentValue) =>
                              (total = total + currentValue.count),
                            0
                          )}
                        </span>
                      </div>
                      <ul className="dk-top-headertitlelist">
                        <li
                          data-type="updated_on"
                          data-value="Not Updated"
                          onClick={selectFilter}
                        >
                          Not Updated
                          <span
                            className="mlCount"
                            data-type="updated_on"
                            data-value="Not Updated"
                            onClick={selectFilter}
                          >
                            {notUpdate}
                          </span>
                        </li>

                        <li
                          data-type="updated_on"
                          data-value="Today"
                          onClick={selectFilter}
                        >
                          Today
                          <span
                            className="mlCount"
                            data-type="updated_on"
                            data-value="Today"
                            onClick={selectFilter}
                          >
                            {today}
                          </span>
                        </li>

                        <li
                          data-type="updated_on"
                          data-value="yesterday"
                          onClick={selectFilter}
                        >
                          Yesterday
                          <span
                            className="mlCount"
                            data-type="updated_on"
                            data-value="yesterday"
                            onClick={selectFilter}
                          >
                            {yesterday}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="dk-jobsLeftTopBoxCols">
                      <div
                        className={`headerCon ${!isupdatedFilter ? "" : "headerCon-filterBg"
                          }`}
                      >
                        {!updatedFilterText ? (
                          <div className="title">JOBS UPDATED</div>
                        ) : (
                          <div className="dk-dbsuMM-filterHeader">
                            {updatedFilterText}
                            <span>
                              <i
                                className="fa fa-times-circle  ml-2"
                                data-type="updated_on"
                                onClick={removeTag}
                              ></i>
                            </span>
                          </div>
                        )}
                        <span className="totalCount">
                          {updatedCount.reduce(
                            (total, currentValue) =>
                              (total = total + currentValue.count),
                            0
                          )}
                        </span>
                      </div>
                      <ul className="dk-top-headertitlelist">
                        <li
                          data-type="updated_on"
                          data-value="Not Updated"
                          onClick={selectFilter}
                        >
                          Not Updated
                          <span
                            className="mlCount"
                            data-type="updated_on"
                            data-value="Not Updated"
                            onClick={selectFilter}
                          >
                            {notUpdate}
                          </span>
                        </li>

                        <li
                          data-type="updated_on"
                          data-value="Today"
                          onClick={selectFilter}
                        >
                          Today
                          <span
                            className="mlCount"
                            data-type="updated_on"
                            data-value="Today"
                            onClick={selectFilter}
                          >
                            {today}
                          </span>
                        </li>

                        <li
                          data-type="updated_on"
                          data-value="yesterday"
                          onClick={selectFilter}
                        >
                          Yesterday
                          <span
                            className="mlCount"
                            data-type="updated_on"
                            data-value="yesterday"
                            onClick={selectFilter}
                          >
                            {yesterday}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="dk-jobsLeftTopBoxCols">
                      <div
                        className={`headerCon ${!isworkPermitFilter ? "" : "headerCon-filterBg"
                          }`}
                      >
                        {!workPermitFilterText ? (
                          <div className="title">JOB CREATED</div>
                        ) : (
                          <div className="dk-dbsuMM-filterHeader">
                            {workPermitFilterText}
                            <span>
                              <i
                                className="fa fa-times-circle  ml-2"
                                data-type="work_permit"
                                onClick={removeTag}
                              ></i>
                            </span>
                          </div>
                        )}
                        <span className="totalCount">
                          {workpermitListCounts.reduce(
                            (total, currentValue) =>
                              (total = total + currentValue.count),
                            0
                          )}
                        </span>

                      </div>
                      <ul className="dk-top-headertitlelist dk-nohoveronlist">

                        {workpermitListCounts.map((data, index) => (
                          <li
                            key={index + 1}
                            data-type="work_permit"
                            data-value={data.work_permit}
                            onClick={selectFilter}
                          >
                            <div
                              className="leftItem"
                              data-type="work_permit"
                              data-value={data.work_permit}
                              onClick={selectFilter}
                            >
                              {data.work_permit}
                            </div>
                            <span
                              className="iTCount"
                              data-type="work_permit"
                              data-value={data.work_permit}
                              onClick={selectFilter}
                            >
                              {data.count}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="dk-jobsLeftTopBoxCols">
                      <div
                        className={`headerCon ${!islocalityFilter ? "" : "headerCon-filterBg"
                          }`}
                      >
                        {!localityFilterText ? (
                          <div className="title">WORK FROM</div>
                        ) : (
                          <div className="dk-dbsuMM-filterHeader">
                            {localityFilterText}
                            <span>
                              <i
                                className="fa fa-times-circle  ml-2"
                                data-type="locality"
                                onClick={removeTag}
                              ></i>
                            </span>
                          </div>
                        )}
                        <span className="totalCount">
                          {localityCount.reduce(
                            (total, currentValue) =>
                              (total = total + currentValue.count),
                            0
                          )}
                        </span>
                        {/* <span className="totalCount">4</span> */}
                        {/* <span className="totalCount">{totaljobsCount.reduce((total, currentValue) => total = total + currentValue.count, 0)}</span> */}
                      </div>
                      <ul className="dk-top-headertitlelist dk-nohoveronlist">
                        {/* <li>
                          <div className="leftItem">
                            <i className="fas fa-briefcase ml-1"></i>Total Jobs
                          </div>
                          <span className="mlCount">
                            {totaljobsCount[0].count}
                          </span>
                        </li> */}
                        {localityCount.map((data, index) => (
                          <li
                            key={index + 1}
                            data-type="locality"
                            data-value={data.locality}
                            onClick={selectFilter}
                          >
                            <div
                              className="leftItem"
                              data-type="locality"
                              data-value={data.locality}
                              onClick={selectFilter}
                            >
                              {data.locality}
                            </div>
                            <span
                              className="iTCount"
                              data-type="locality"
                              data-value={data.locality}
                              onClick={selectFilter}
                            >
                              {data.count}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    {/* <div className="dk-jobsLeftTopBoxCols">
                      <div
                        className={`headerCon ${!isupdatedFilter ? "" : "headerCon-filterBg"
                          }`}
                      >
                        {!updatedFilterText ? (
                          <div className="title">WORK PERMIT</div>
                        ) : (
                          <div className="dk-dbsuMM-filterHeader">
                            {updatedFilterText}
                            <span>
                              <i
                                className="fa fa-times-circle  ml-2"
                                data-type="updated_on"
                                onClick={removeTag}
                              ></i>
                            </span>
                          </div>
                        )}
                        <span className="totalCount">
                            27
                        </span>
                      </div>
                      <ul className="dk-top-headertitlelist">
                        <li
                          data-type="work_permit"
                          data-value="Not Updated"
                        >
                          Not Provided
                          <span
                            className="mlCount"
                            data-type="work_permit"
                            data-value="Not Updated"

                          >
                            {notUpdate}
                          </span>
                        </li>

                        <li
                          data-type="work_permit"
                          data-value="Provided"

                        >
                          Provided
                          <span
                            className="mlCount"
                            data-type="work_permit"
                            data-value="Provided"
                            onClick={selectFilter}
                          >
                            6
                          </span>
                        </li>


                      </ul>
                    </div> */}
                    <div className="dk-jobsLeftTopBoxCols">
                      <div
                        className={`headerCon ${!isworkPermitFilter ? "" : "headerCon-filterBg"
                          }`}
                      >
                        {!workPermitFilterText ? (
                          <div className="title">WORK PERMIT</div>
                        ) : (
                          <div className="dk-dbsuMM-filterHeader">
                            {workPermitFilterText}
                            <span>
                              <i
                                className="fa fa-times-circle  ml-2"
                                data-type="work_permit"
                                onClick={removeTag}
                              ></i>
                            </span>
                          </div>
                        )}
                        <span className="totalCount">
                          {workpermitListCounts.reduce(
                            (total, currentValue) =>
                              (total = total + currentValue.count),
                            0
                          )}
                        </span>

                      </div>
                      <ul className="dk-top-headertitlelist dk-nohoveronlist">

                        {workpermitListCounts.map((data, index) => (
                          <li
                            key={index + 1}
                            data-type="work_permit"
                            data-value={data.work_permit}
                            onClick={selectFilter}
                          >
                            <div
                              className="leftItem"
                              data-type="work_permit"
                              data-value={data.work_permit}
                              onClick={selectFilter}
                            >
                              {data.work_permit}
                            </div>
                            <span
                              className="iTCount"
                              data-type="work_permit"
                              data-value={data.work_permit}
                              onClick={selectFilter}
                            >
                              {data.count}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="dk-jobsLeftBottomBoxCon">
                    {/* <div className='dk-jobsLeftBoxCon dk-jobsLeftbottomBoxCols' style={{ width: "100%" }}>
                                            <div className="dk-jobsLeftTopBoxCols">
                                                <div className={`headerCon ${(!isrcFilter) ? '' : 'headerCon-filterBg'}`}>
                                                    {
                                                        (!rcFilterText) ?
                                                            <div className="title">RECRUITERS</div>
                                                            :
                                                            <div className='dk-dbsuMM-filterHeader'>
                                                                {rcFilterText}
                                                                <span><i className='fa fa-times-circle  ml-2' data-type="user_id" onClick={removeTag}></i></span>
                                                            </div>
                                                    }
                                                    <span className="totalCount">{rcCount.reduce((total, currentValue) => total = total + currentValue.count, 0)}</span>
                                                </div>
                                                <ul className="dk-top-headertitlelist" style={{ maxHeight: "367px", minHeight: "367px" }}>
                                                    {
                                                        rcCount.map((data, index) => (
                                                            data.assignWithjob.length ?
                                                                <li key={index + 1} data-type="user_id" data-text={data.assignWithjob[0].userInfo.firstName + " " + data.assignWithjob[0].userInfo.lastName} data-value={data.assignWithjob[0].userInfo.id} onClick={selectFilter}>
                                                                    <div className="leftItem" data-type="user_id" data-text={data.assignWithjob[0].userInfo.firstName + " " + data.assignWithjob[0].userInfo.lastName} data-value={data.assignWithjob[0].userInfo.id} onClick={selectFilter}>
                                                                        <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + data.assignWithjob[0].userInfo.profileImage} className="recImg" data-type="user_id" data-text={data.assignWithjob[0].userInfo.firstName + " " + data.assignWithjob[0].userInfo.lastName} data-value={data.assignWithjob[0].userInfo.id} onClick={selectFilter} />
                                                                        {data.assignWithjob[0].userInfo.firstName + " " + data.assignWithjob[0].userInfo.lastName}
                                                                    </div>
                                                                    <span className="mlCount" data-type="user_id" data-text={data.assignWithjob[0].userInfo.firstName + " " + data.assignWithjob[0].userInfo.lastName} data-value={data.assignWithjob[0].userInfo.id} onClick={selectFilter}>{data.count}</span>
                                                                </li>
                                                                : ""
                                                        ))
                                                    }

                                                </ul>
                                            </div>
                                        </div> */}
                    <div className="dk-jobsLeftbottomBoxCols">
                      <div
                        className={`headerCon ${!isjobProfileFilter ? "" : "headerCon-filterBg"
                          }`}
                      >
                        {!jobProfileFilterText ? (
                          <div className="title">CANDIDATES</div>
                        ) : (
                          <div className="dk-dbsuMM-filterHeader">
                            {jobProfileFilterText}
                            <span>
                              <i
                                className="fa fa-times-circle  ml-2"
                                data-type="job_profile"
                                onClick={removeTag}
                              ></i>
                            </span>
                          </div>
                        )}
                        <span className="totalCount">
                          {candidateCount.map((data, index) => {
                            return (
                              Number(data.job_l1Count) +
                              Number(data.job_l1Count) +
                              Number(data.job_l2Count) +
                              Number(data.job_soCount) +
                              Number(data.job_joCount) +
                              Number(data.job_jpCount)
                            );
                          })}
                        </span>
                      </div>
                      <div className="listsContainer">
                        <ul>{allCandidate}</ul>
                      </div>
                    </div>
                    <div className="dk-jobsLeftbottomBoxCols">
                      <div
                        className={`headerCon ${!iscompanyFilter ? "" : "headerCon-filterBg"
                          }`}
                      >
                        {!companyFilterText ? (
                          <div className="title">COMPANY</div>
                        ) : (
                          <div className="dk-dbsuMM-filterHeader">
                            {companyFilterText}
                            <span>
                              <i
                                className="fa fa-times-circle  ml-2"
                                data-type="company"
                                onClick={removeTag}
                              ></i>
                            </span>
                          </div>
                        )}
                        <span className="totalCount">
                          {companyCount.reduce(
                            (total, currentValue) =>
                              (total = total + currentValue.count),
                            0
                          )}
                        </span>
                      </div>
                      <div className="listsContainer">
                        <ul>
                          {companyCount.map((data, index) => (
                            <li
                              key={index + 1}
                              data-type="company"
                              data-value={data.company}
                              onClick={selectFilter}
                            >
                              {data.company}
                              <span
                                className="mlCount"
                                data-type="company"
                                data-value={data.company}
                                onClick={selectFilter}
                              >
                                {data.count}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="dk-jobsLeftbottomBoxCols">
                      <div
                        className={`headerCon ${!iscategoryFilter ? "" : "headerCon-filterBg"
                          }`}
                      >
                        {!categoryFilterText ? (
                          <div className="title">CATEGORIES</div>
                        ) : (
                          <div className="dk-dbsuMM-filterHeader">
                            {categoryFilterText}
                            <span>
                              <i
                                className="fa fa-times-circle  ml-2"
                                data-type="category"
                                onClick={removeTag}
                              ></i>
                            </span>
                          </div>
                        )}
                        <span className="totalCount">
                          {categoryCount.reduce(
                            (total, currentValue) =>
                              (total = total + currentValue.count),
                            0
                          )}
                        </span>
                      </div>
                      <div className="listsContainer">
                        <ul>
                        {categoryCount.map((data, index) => (
                          <li
                            key={index + 1}
                            data-type="category"
                            data-value={data.category}
                            onClick={selectFilter}
                          >
                            {data.category}
                            <span
                              className="mlCount"
                              data-type="category"
                              data-value={data.category}
                              onClick={selectFilter}
                            >
                              {data.count}
                            </span>
                          </li>
                        ))}
                      </ul>
                      </div>
                    </div>
                    <div className="dk-jobsLeftbottomBoxCols">
                      <div
                        className={`headerCon ${!iscountryFilter ? "" : "headerCon-filterBg"
                          }`}
                      >
                        {!countryFilterText ? (
                          <div className="title">COUNTRY</div>
                        ) : (
                          <div className="dk-dbsuMM-filterHeader">
                            {countryFilterText}
                            <span>
                              <i
                                className="fa fa-times-circle  ml-2"
                                data-type="job_country"
                                onClick={removeTag}
                              ></i>
                            </span>
                          </div>
                        )}
                        <span className="totalCount">
                          {jobCountryCount.reduce(
                            (total, currentValue) =>
                              (total = total + currentValue.count),
                            0
                          )}
                        </span>
                      </div>
                      <div className="listsContainer">
                        <ul>
                          {jobCountryCount.map((data, index) =>
                            !data.job_country == "" ? (
                              <li
                                key={index + 1}
                                data-type="job_country"
                                data-value={data.job_country}
                                onClick={selectFilter}
                              >
                                {data.job_country}
                                <span
                                  className="mlCount"
                                  data-type="job_country"
                                  data-value={data.job_country}
                                  onClick={selectFilter}
                                >
                                  {data.count}
                                </span>
                              </li>
                            ) : (
                              ""
                            )
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className="dk-jobsLeftbottomBoxCols">
                      <div
                        className={`headerCon ${!islocationFilter ? "" : "headerCon-filterBg"
                          }`}
                      >
                        {!locationFilterText ? (
                          <div className="title">CITY/TOWN</div>
                        ) : (
                          <div className="dk-dbsuMM-filterHeader">
                            {locationFilterText}
                            <span>
                              <i
                                className="fa fa-times-circle  ml-2"
                                data-type="location"
                                onClick={removeTag}
                              ></i>
                            </span>
                          </div>
                        )}
                        <span className="totalCount">
                          {locationCount.reduce(
                            (total, currentValue) =>
                              (total = total + currentValue.count),
                            0
                          )}
                        </span>
                      </div>
                      <div className="listsContainer">
                        <ul>
                          {locationCount.map((data, index) =>
                            !data.location == "" ? (
                              <li
                                key={index + 1}
                                data-type="location"
                                data-value={data.location}
                                onClick={selectFilter}
                              >
                                {data.location}
                                <span
                                  className="mlCount"
                                  data-type="location"
                                  data-value={data.location}
                                  onClick={selectFilter}
                                >
                                  {data.count}
                                </span>
                              </li>
                            ) : (
                              ""
                            )
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className="dk-jobsLeftbottomBoxCols">
                      <div
                        className={`headerCon ${!islanguageFilter ? "" : "headerCon-filterBg"
                          }`}
                      >
                        {!languageFilterText ? (
                          <div className="title">LANGUAGE</div>
                        ) : (
                          <div className="dk-dbsuMM-filterHeader">
                            {languageFilterText}
                            <span>
                              <i
                                className="fa fa-times-circle  ml-2"
                                data-type="lang"
                                onClick={removeTag}
                              ></i>
                            </span>
                          </div>
                        )}
                        <span className="totalCount">
                          {languageCount.reduce(
                            (total, currentValue) =>
                              (total = total + currentValue.count),
                            0
                          )}
                        </span>
                      </div>
                      <div className="listsContainer">
                        <ul>
                          {languageCount.map((data, index) => (
                            <li
                              key={index + 1}
                              data-type="lang"
                              data-value={data.lang}
                              onClick={selectFilter}
                            >
                              {data.lang}
                              <span
                                className="mlCount"
                                data-type="lang"
                                data-value={data.lang}
                                onClick={selectFilter}
                              >
                                {data.count}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="dk-jobsLeftbottomBoxCols">
                      <div
                        className={`headerCon ${!isprojectFilter ? "" : "headerCon-filterBg"
                          }`}
                      >
                        {!projectFilterText ? (
                          <div className="title">Project</div>
                        ) : (
                          <div className="dk-dbsuMM-filterHeader">
                            {projectFilterText}
                            <span>
                              <i
                                className="fa fa-times-circle  ml-2"
                                data-type="project"
                                onClick={removeTag}
                              ></i>
                            </span>
                          </div>
                        )}
                        <span className="totalCount">
                          {projectCount.reduce(
                            (total, currentValue) =>
                              (total = total + currentValue.count),
                            0
                          )}
                        </span>
                      </div>
                      <div className="listsContainer">
                        <ul>
                          {projectCount.map((data, index) => (
                            <li
                              key={index + 1}
                              data-type="project"
                              data-value={data.project}
                              onClick={selectFilter}
                            >
                              {data.project}
                              <span
                                className="mlCount"
                                data-type="project"
                                data-value={data.project}
                                onClick={selectFilter}
                              >
                                {data.count}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="dk-jobsLeftbottomBoxCols">
                      <div
                        className={`headerCon ${!isroleFilter ? "" : "headerCon-filterBg"
                          }`}
                      >
                        {!roleFilterText ? (
                          <div className="title">Role Type</div>
                        ) : (
                          <div className="dk-dbsuMM-filterHeader">
                            {roleFilterText}
                            <span>
                              <i
                                className="fa fa-times-circle  ml-2"
                                data-type="role"
                                onClick={removeTag}
                              ></i>
                            </span>
                          </div>
                        )}
                        <span className="totalCount">
                          {roleCount.reduce(
                            (total, currentValue) =>
                              (total = total + currentValue.count),
                            0
                          )}
                        </span>
                      </div>
                      <div className="listsContainer">
                        <ul>
                          {roleCount.map((data, index) => (
                            <li
                              key={index + 1}
                              data-type="role"
                              data-value={data.role}
                              onClick={selectFilter}
                            >
                              {data.role}
                              <span
                                className="mlCount"
                                data-type="role"
                                data-value={data.role}
                                onClick={selectFilter}
                              >
                                {data.count}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    
                    <div className="dk-jobsLeftbottomBoxCols">
                      <div
                        className={`headerCon ${!isroleFilter ? "" : "headerCon-filterBg"
                          }`}
                      >
                        {!roleFilterText ? (
                          <div className="title">START DATE 1</div>
                        ) : (
                          <div className="dk-dbsuMM-filterHeader">
                            {roleFilterText}
                            <span>
                              <i
                                className="fa fa-times-circle  ml-2"
                                data-type="role"
                                onClick={removeTag}
                              ></i>
                            </span>
                          </div>
                        )}
                        <span className="totalCount">
                          {roleCount.reduce(
                            (total, currentValue) =>
                              (total = total + currentValue.count),
                            0
                          )}
                        </span>
                      </div>
                      <div className="listsContainer">
                        <ul>
                          {roleCount.map((data, index) => (
                            <li
                              key={index + 1}
                              data-type="role"
                              data-value={data.role}
                              onClick={selectFilter}
                            >
                              {data.role}
                              <span
                                className="mlCount"
                                data-type="role"
                                data-value={data.role}
                                onClick={selectFilter}
                              >
                                {data.count}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="dk-jobsLeftbottomBoxCols">
                      <div
                        className={`headerCon ${!isroleFilter ? "" : "headerCon-filterBg"
                          }`}
                      >
                        {!roleFilterText ? (
                          <div className="title">START DATE 2</div>
                        ) : (
                          <div className="dk-dbsuMM-filterHeader">
                            {roleFilterText}
                            <span>
                              <i
                                className="fa fa-times-circle  ml-2"
                                data-type="role"
                                onClick={removeTag}
                              ></i>
                            </span>
                          </div>
                        )}
                        <span className="totalCount">
                          {roleCount.reduce(
                            (total, currentValue) =>
                              (total = total + currentValue.count),
                            0
                          )}
                        </span>
                      </div>
                      <div className="listsContainer">
                        <ul>
                          {roleCount.map((data, index) => (
                            <li
                              key={index + 1}
                              data-type="role"
                              data-value={data.role}
                              onClick={selectFilter}
                            >
                              {data.role}
                              <span
                                className="mlCount"
                                data-type="role"
                                data-value={data.role}
                                onClick={selectFilter}
                              >
                                {data.count}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Summary;
