import React, { useState } from "react"
import Dropdown from 'react-bootstrap/Dropdown';
import { FaUserAlt } from "react-icons/fa";

const ProfileIndicatorOptions = ({ id, columnId, updateMyData }) => {
    const selectValue = (value, color) => {
        // const setValue = e.target.dataset.value;
        updateMyData(id, columnId, value, color);
    }
    return (
        <React.Fragment>
            <Dropdown.Item href="#" data-value="1" data-column="motivationStatus" onClick={() => selectValue(1, 'green')}>
                <i className="fa fa-reply-all" aria-hidden="true"  style={{ color: "green" }} ></i>
                <span className="dkg-prof-ind-counter">High Response</span>
            </Dropdown.Item>
            <Dropdown.Item href="#" data-value="2" data-column="motivationStatus" onClick={() => selectValue(2, '#d58b5d')}>
                <i className="fa fa-reply-all" aria-hidden="true"  style={{ color: "#d58b5d" }} ></i>
                <span className="dkg-prof-ind-counter">Good Response</span>
            </Dropdown.Item>
            <Dropdown.Item href="#" data-value="3" data-column="motivationStatus" onClick={() => selectValue(3, 'red')}>
                <i className="fa fa-reply-all" aria-hidden="true"  style={{ color: "red" }} ></i>
                <span className="dkg-prof-ind-counter">Low Response</span>
            </Dropdown.Item>
            <Dropdown.Item href="#" data-value="0" data-column="motivationStatus" onClick={() => selectValue(0, '#d3d3d3')}>
                <i className="fa fa-reply-all" aria-hidden="true"  style={{ color: "#d3d3d3" }} ></i>
                <span className="dkg-prof-ind-counter">Blank </span>
            </Dropdown.Item>
        </React.Fragment>
    )
}
export default ProfileIndicatorOptions;