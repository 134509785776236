import React, { useState, useEffect, useRef } from "react";
import "./addnewjob.scss";
import { useDispatch, useSelector } from "react-redux";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import SearchDropDownFilter from "./SearchFilter";
import CompanyDropdown from "./CompanyDropdown";
import { CKEditor } from "@ckeditor/ckeditor5-react";
//import DatePicker from './DatePicker'

import { addNewJob, clearState } from "../../../../slice/jobs/jobsSlice";
import {
  showSuccess,
  showError,
  clearMessage,
} from "../../../../slice/utils/message/messageSlice";

import {
  companyData,
  companyProjectData,
  companyRoleData,
} from "../../../../slice/companies/clients/clientSlice";

const AddNew = () => {
  const dispatch = useDispatch();
  const { isLoading, companyList, companyProjtList, companyRolList } =
    useSelector((state) => state.companies);

  const {
    jobsCategoryList,
    jobsCompanyList,
    jobsProjectList,
    jobsRoleTypeList,
    jobsLanguageList,
    jobsLocalityList,
    jobsNoofJobsList,
    jobsCountryList,
    jobsCityList,
    jobsJobProfileList,
    jobsWorkPermitList,
    jobsLevelIdList,
    jobsIndustryIdList,
    jobCompList,
  } = useSelector((state) => state.common);
  const { message, isSuccess } = useSelector((state) => state.jobs);
  //  console.log(jobsIndustryIdList)
  const [isRefresh, setIsRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [title, setTitle] = useState("");
  const [startDate1, setStartDate1] = useState("");
  const [startDate2, setStartDate2] = useState("");
  const [erf, setErf] = useState("");
  const [categoryList, setCategoryList] = useState("");
  const [companyesList, setCompanyList] = useState("");
  const [projectList, setProjectList] = useState("");
  const [roleTypeList, setRoleTypeList] = useState("");
  const [languageList, setLanguageList] = useState("");
  const [level1List, setLevel1List] = useState("");
  const [level2List, setLevel2List] = useState("");
  const [language2List, setLanguage2List] = useState("");
  const [localityList, setLocalityList] = useState("");
  const [noofJobsList, setNoofJobsList] = useState("");
  const [countryList, setCountryList] = useState("");
  const [cityList, setCityList] = useState("");
  const [jobProfileList, setJobProfileList] = useState("");
  const [jobIndustry, setJobIndustryList] = useState("");
  const [jobWorkPermitList, setWorkPermitList] = useState("");
  const [companydata, setCompanydata] = useState(
    companyList.filter((item) => item.inStatus === 1)
  );
  //console.log(companyList)

  useEffect(() => {
    dispatch(clearState());
    const fetchList = async () => {
      try {
        await dispatch(companyData());
      } catch (err) {
        console.log(err);
      }
    };
    fetchList();
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setLoading(false);
      setSuccess(true);
      setTitle("");
      setStartDate1("");
      setStartDate2("");
      setCategoryList("");
      setCompanyList("");
      setProjectList("");
      setRoleTypeList("");
      setLanguageList("");
      setLevel1List("");
      setLevel2List("");
      setLanguage2List("");
      setLocalityList("");
      setNoofJobsList("");
      setCountryList("");
      setCityList("");
      setJobProfileList("");
      setJobIndustryList("");
      setWorkPermitList("");
      setErf("");
      formRefresh();
      dispatch(showSuccess({ msg: message }));
      dispatch(clearState());
    }
  }, [isSuccess]);

  const formRefresh = () => {
    setIsRefresh(true);
  };

  const handleButtonClick = async (e) => {
    e.preventDefault();
    if (
      !categoryList ||
      !companyesList ||
      !projectList ||
      !roleTypeList ||
      !languageList ||
      !noofJobsList ||
      !countryList ||
      !cityList ||
      !title ||
      !level1List ||
      !level2List ||
      !startDate1 ||
      !startDate2 ||
      !jobIndustry
    ) {
      dispatch(showError({ msg: "All fields are required." }));
    } else {
      setSuccess(false);
      setLoading(true);
      dispatch(
        addNewJob({
          com_id: 1,
          category: categoryList,
          company: companyesList,
          project: projectList,
          role: roleTypeList,
          lang: languageList,
          lang2: language2List,
          no_of_jobs: noofJobsList,
          locality: localityList,
          job_country: countryList,
          location: cityList,
          job_profile: jobProfileList,
          industry: jobIndustry,
          work_permit: jobWorkPermitList,
          name: title,
          erf: erf,
          lang_lvl: level1List,
          lang2_lvl: level2List,
          start_date1: startDate1,
          start_date2: startDate2,
        })
      );
      setIsRefresh(false);
    }
  };

  const getCatValue = (data) => {
    setCategoryList(data);
  };
  const getCompanyValue = (data) => {
    setCompanyList(data);
    setProjectList("");
    setRoleTypeList("");
    // console.log("Pdtaa")
    // console.log(data)
    dispatch(companyProjectData({ name: data }));
    dispatch(companyRoleData({ name: data }));
  };
  const getProjectValue = (data) => {
    setProjectList(data);
  };
  const getRoleTypetValue = (data) => {
    setRoleTypeList(data);
  };
  const getLanguagetValue = (data) => {
    setLanguageList(data);
  };
  const getLevel1Value = (data) => {
    setLevel1List(data);
  };
  const getLevel2Value = (data) => {
    setLevel2List(data);
  };
  const getLanguage2tValue = (data) => {
    setLanguage2List(data);
  };
  const getLocalitytValue = (data) => {
    setLocalityList(data);
  };
  const getNoofJobstValue = (data) => {
    setNoofJobsList(data);
  };
  const getCountrytValue = (data) => {
    setCountryList(data);
  };
  const getCitytValue = (data) => {
    setCityList(data);
  };
  const getJobProfiletValue = (data) => {
    setJobProfileList(data);
  };
  const getJobIndustryValue = (data) => {
    setJobIndustryList(data);
  };

  return (
    <>
      <div className="container-fluid dk-jobsCreatePageCon">
        {/* <div className="row">
                    <div className="col-md-5">
                        <h2 className="dk-todayadsMainPTitle w-100 text-center">
                            SELECT PATH
                        </h2>
                    </div>
                    <div className="col-md-7">
                        <div className="dk-jobCreateRightHead">
                            <h2 className="dk-todayadsMainPTitle w-100 text-center">
                                JOB DETAILS
                            </h2>
                        </div>
                    </div>
                </div> */}
        <div className="dkcreate-FromSection">
          <div className="row">
            <div className="col-md-7 col-sm-12 m-auto">
              <div className="dk-leftTopformCon">
                <form>
                  <div className="row">
                    <div className="col-md-12 col-12">
                      <div className="dk-formInputCon dk-jobtitleCon">
                        <label>
                          Job ID<span>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control text-left"
                          // value={}
                          // onChange={(e) => setTitle(e.target.value)}
                          defaultValue=""
                        />
                      </div>
                      <div className="dk-formInputCon dk-jobtitleCon">
                        <label>
                          Job Title<span>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control text-left"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          defaultValue=""
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="dk-formInputCon">
                        <label>
                          Category<span>*</span>
                        </label>
                        <div className="dk-createjobsDrpoDwn">
                          <SearchDropDownFilter
                            categoryList={jobsCategoryList}
                            catList={getCatValue}
                            isRefresh={isRefresh}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="dk-formInputCon">
                        <label>
                          No.of Jobs<span>*</span>
                        </label>
                        <div className="dk-createjobsDrpoDwn">
                          <SearchDropDownFilter
                            categoryList={jobsNoofJobsList}
                            catList={getNoofJobstValue}
                            isRefresh={isRefresh}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="dk-formInputCon">
                        <label>
                          Industry<span>*</span>
                        </label>
                        <div className="dk-createjobsDrpoDwn">
                          <SearchDropDownFilter
                            categoryList={jobsIndustryIdList}
                            catList={getJobIndustryValue}
                            isRefresh={isRefresh}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="dk-formInputCon">
                        <label>
                          Company<span>*</span>
                        </label>
                        <div className="dk-createjobsDrpoDwn">
                          {/* <SearchDropDownFilter
                            categoryList={jobsCompanyList}
                            catList={getCompanyValue}
                            isRefresh={isRefresh}
                          /> */}
                          <CompanyDropdown
                            categoryList={jobCompList}
                            catList={getCompanyValue}
                            isRefresh={isRefresh}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="dk-formInputCon">
                        <label>
                          Country<span>*</span>
                        </label>
                        <div className="dk-createjobsDrpoDwn">
                          <SearchDropDownFilter
                            categoryList={jobsCountryList}
                            catList={getCountrytValue}
                            isRefresh={isRefresh}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="dk-formInputCon">
                        <label>
                          City/Town<span>*</span>
                        </label>
                        <div className="dk-createjobsDrpoDwn">
                          <SearchDropDownFilter
                            categoryList={jobsCityList}
                            catList={getCitytValue}
                            isRefresh={isRefresh}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="dk-formInputCon mb-0">
                        <label>
                          Project<span>*</span>
                        </label>
                        <div className="dk-createjobsDrpoDwn">
                          <SearchDropDownFilter
                            categoryList={jobsProjectList}
                            catList={getProjectValue}
                            isRefresh={isRefresh}
                          />
                          {/* <CompanyDropdown
                            categoryList={companyProjtList}
                            catList={getProjectValue}
                            isRefresh={isRefresh}
                          /> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="dk-formInputCon mb-0">
                        <label>
                          Role Type<span>*</span>
                        </label>
                        <div className="dk-createjobsDrpoDwn">
                          <SearchDropDownFilter
                            categoryList={jobsRoleTypeList}
                            catList={getRoleTypetValue}
                            isRefresh={isRefresh}
                          />
                          {/* <CompanyDropdown
                            categoryList={companyRolList}
                            catList={getRoleTypetValue}
                            isRefresh={isRefresh}
                          /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="dk-leftbottomformCon">
                <form>
                  <div className="row">
                    <div className="col-12">
                      <div
                        className="dk-formInputCon"
                        style={{ width: "50%", float: "left" }}
                      >
                        <label>
                          Language 1<span>*</span>
                        </label>
                        <div className="dk-createjobsDrpoDwn">
                          <SearchDropDownFilter
                            categoryList={jobsLanguageList}
                            catList={getLanguagetValue}
                            isRefresh={isRefresh}
                          />
                        </div>
                      </div>
                      <div
                        className="dk-formInputCon"
                        style={{ width: "50%", float: "left" }}
                      >
                        <label className="text-center">
                          Level<span>*</span>
                        </label>
                        <div className="dk-createjobsDrpoDwn">
                          <SearchDropDownFilter
                            categoryList={jobsLevelIdList}
                            catList={getLevel1Value}
                            isRefresh={isRefresh}
                          />
                        </div>
                      </div>

                      <div
                        className="dk-formInputCon"
                        style={{ width: "50%", float: "left" }}
                      >
                        <label>
                          Language 2<span>*</span>
                        </label>
                        <div className="dk-createjobsDrpoDwn">
                          <SearchDropDownFilter
                            categoryList={jobsLanguageList}
                            catList={getLanguage2tValue}
                            isRefresh={isRefresh}
                          />
                        </div>
                      </div>

                      <div
                        className="dk-formInputCon"
                        style={{ width: "50%", float: "left" }}
                      >
                        <label className="text-center">
                          Level<span>*</span>
                        </label>
                        <div className="dk-createjobsDrpoDwn">
                          <SearchDropDownFilter
                            categoryList={jobsLevelIdList}
                            catList={getLevel2Value}
                            isRefresh={isRefresh}
                          />
                        </div>
                      </div>
                      {/*
                      <div
                        className="dk-formInputCon"
                        style={{ width: "100%" }}
                      >
                        <label>
                          Job Profile<span>*</span>
                        </label>
                        <div className="dk-createjobsDrpoDwn">
                          <SearchDropDownFilter
                            categoryList={jobsJobProfileList}
                            catList={getJobProfiletValue}
                            isRefresh={isRefresh}
                          />
                        </div>
                      </div> */}

                      <div
                        className="dk-formInputCon"
                        style={{ width: "50%", float: "left" }}
                      >
                        <label className="text-left">
                          Start Date 1<span>*</span>
                        </label>
                        <div className="dk-createjobsDrpoDwn dkg-createjob-datepicker">
                          {/* <DatePicker /> */}
                          <input
                            type="text"
                            className="form-control text-left"
                            value={startDate1}
                            onChange={(e) => setStartDate1(e.target.value)}
                            defaultValue=""
                          />
                        </div>
                      </div>
                      {/* <div className="dk-formInputCon" style={{ width: "50%", float: "left" }}>
                        <label className="text-center">
                          Locality<span>*</span>
                        </label>
                        <div className="dk-createjobsDrpoDwn">
                          <SearchDropDownFilter
                            categoryList={jobsLocalityList}
                            catList={getLocalitytValue}
                            isRefresh={isRefresh}
                          />
                        </div>
                      </div> */}
                      <div
                        className="dk-formInputCon mb-0"
                        style={{ width: "50%", float: "left" }}
                      >
                        <label>
                          Start Date 2<span>*</span>
                        </label>
                        <div className="dk-createjobsDrpoDwn dkg-createjob-datepicker">
                          {/* <DatePicker /> */}
                          <input
                            type="text"
                            className="form-control text-left"
                            value={startDate2}
                            onChange={(e) => setStartDate2(e.target.value)}
                            defaultValue=""
                          />
                        </div>
                      </div>
                      {/* <div className="dk-formInputCon" style={{ width: "50%", float: "left" }}>
                        <label className="text-center">
                          <span></span>
                        </label>
                        <div className="dk-createjobsDrpoDwn dkg-createjob-datepicker d-none">
                          <DatePicker />
                        </div>
                      </div> */}
                    </div>
                  </div>
                </form>
              </div>
              <div className="dk-saveresetbtnCon">
                <button className="btn dk-resetjobBtn">RESET FORM</button>
                <button
                  type="submit"
                  loading={loading}
                  success={success}
                  onClick={handleButtonClick}
                  className="btn dk-createjobBtn"
                >
                  Create Job
                </button>
              </div>
            </div>
            <div className="col-md-7 col-sm-12 d-none">
              <div className="dk-rightTopCon">
                <div className="dk-rightInputCon">
                  <div className="row">
                    <div className="col-md-12"></div>
                  </div>
                  <div className="dk-createhtmEditor">
                    <CKEditor
                      editor={ClassicEditor}
                      onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        console.log("Editor is ready to use!", editor);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        console.log({ event, editor, data });
                      }}
                      onBlur={(event, editor) => {
                        console.log("Blur.", editor);
                      }}
                      onFocus={(event, editor) => {
                        console.log("Focus.", editor);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNew;
