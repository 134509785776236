import React, { useState, useEffect, useRef, } from 'react';
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
//import ManagerImg from "../../../../../assets/images/deepak.png";
import './traget-summary.scss';
import { companyListTable } from "../../../../../slice/clientKpi/clientKpiSlice";
import { accountManagerListTable } from "../../../../../slice/kpis/teamKpiSlice";
import { recrutersListTable } from "../../../../../slice/kpis/sendoutKpiSlice";
import { performanceFun } from "../../../../../slice/kpis/performanceBonusSlice";
import { sendoutFun } from "../../../../../slice/kpis/sendoutBonusSlice";
import { placementsFun } from "../../../../../slice/kpis/placementsBonusSlice";
import TableLoader from "../../../../ui/tableLoader";
import { useDispatch, useSelector } from "react-redux";

const Index = () => {
    const dispatch = useDispatch();
    const { companyDataList, isLoading } = useSelector(state => state.clientKpi);
    const { AMDataList, isAMLoading } = useSelector(state => state.kpiTeam)
    const { RCDataList, isRCLoading } = useSelector(state => state.sendoutKpi)
    const { performanceDataList, isPrLoading } = useSelector(state => state.perfornanceBonus)
    const { sendoutDataList, isSendoutLoading } = useSelector(state => state.sendoutBonus)
    const { placementsDataList, isPlacemetsLoading } = useSelector(state => state.placementsBonus)

    var today = new Date();
    const [yearValue, setYearValue] = useState(today.getFullYear())
    const [monthValue, setMonthValue] = useState(today.getMonth() + 1)


    const retrieveData = () => {
        dispatch(companyListTable({ year: yearValue, month: monthValue }))
        dispatch(accountManagerListTable({ year: yearValue, month: monthValue }))
        dispatch(recrutersListTable({ year: yearValue, month: monthValue }))
        dispatch(performanceFun({ year: yearValue, month: monthValue }))
        dispatch(sendoutFun({ year: yearValue, month: monthValue }))
        dispatch(placementsFun({ year: yearValue, month: monthValue }))
    }
    useEffect(retrieveData, [yearValue, monthValue]);

    return (
        <>
            <div className='container-fluid dkg-target-summMain'>
                <div className='row dkg-target-summCon'>
                    <div className="dk-kpiCaseHead">
                        <div className="dk-kpiCase-yearsDropdown">
                            <div className="d-flex align-items-center">
                                <Dropdown>
                                    <Dropdown.Toggle variant="" id="dropdown-basic">
                                        {yearValue}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        
                                        <Dropdown.Item onClick={() => setYearValue('2019')} >2019</Dropdown.Item>
                                        <Dropdown.Item onClick={() => setYearValue('2020')} >2020</Dropdown.Item>
                                        <Dropdown.Item onClick={() => setYearValue('2021')} >2021</Dropdown.Item>
                                        <Dropdown.Item onClick={() => setYearValue('2022')} >2022</Dropdown.Item>
                                        <Dropdown.Item onClick={() => setYearValue('2023')} >2023</Dropdown.Item>
                                        <Dropdown.Item onClick={() => setYearValue('2024')} >2024</Dropdown.Item>
                                        <Dropdown.Item onClick={() => setYearValue('2025')} >2025</Dropdown.Item>
                                        <Dropdown.Item onClick={() => setYearValue('2026')} >2026</Dropdown.Item>
                                        <Dropdown.Item onClick={() => setYearValue('2027')} >2027</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                                {/* <div className="dk-clientsAddMonth">
                                        <button className="addMonthBtn"><i className="fas fa-plus"></i></button>
                                    </div> */}
                            </div>
                        </div>
                        <div className="dk-kpiCase-months">
                            <ul>
                                <li onClick={() => setMonthValue(1)}><Link to="#" className={monthValue == 1 ? 'active' : ''}  >Jan</Link></li>
                                <li onClick={() => setMonthValue(2)}><Link to="#" className={monthValue == 2 ? 'active' : ''}  >Feb</Link></li>
                                <li onClick={() => setMonthValue(3)}><Link to="#" className={monthValue == 3 ? 'active' : ''}  >Mar</Link></li>
                                <li onClick={() => setMonthValue(4)}><Link to="#" className={monthValue == 4 ? 'active' : ''}  >Apr</Link></li>
                                <li onClick={() => setMonthValue(5)}><Link to="#" className={monthValue == 5 ? 'active' : ''}  >May</Link></li>
                                <li onClick={() => setMonthValue(6)}><Link to="#" className={monthValue == 6 ? 'active' : ''} >Jun</Link></li>
                                <li onClick={() => setMonthValue(7)}><Link to="#" className={monthValue == 7 ? 'active' : ''}  >Jul</Link></li>
                                <li onClick={() => setMonthValue(8)}><Link to="#" className={monthValue == 8 ? 'active' : ''}  >Aug</Link></li>
                                <li onClick={() => setMonthValue(9)}><Link to="#" className={monthValue == 9 ? 'active' : ''} >Sep</Link></li>
                                <li onClick={() => setMonthValue(10)}><Link to="#" className={monthValue == 10 ? 'active' : ''} >Oct</Link></li>
                                <li onClick={() => setMonthValue(11)}><Link to="#" className={monthValue == 11 ? 'active' : ''}>Nov</Link></li>
                                <li onClick={() => setMonthValue(12)}><Link to="#" className={monthValue == 12 ? 'active' : ''} >Dec</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className='w-100 dkg-target-summ-pageCon'>
                        <div className='dkg-trsumm-moduleCon'>
                            {/* <div className='dkg-target-boxcols w-dk'>
                                <div className='dkg-target-summ-box'>
                                    <div className='dkg-target-summ-boxHeader'>
                                        <h2 className='dkg-target-summ-Headertitle'>Client Sales Target</h2>
                                    </div>
                                    <div className='dkg-target-summ-boxBody'>
                                        <table className="table dkg-target-summ-boxtable dkg-clientsales-cols">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Target</th>
                                                    <th>Achieved</th>
                                                    <th>Pending</th>
                                                </tr>
                                            </thead>
                                            {
                                                (!isLoading) ?
                                                    <tbody>
                                                        {

                                                            companyDataList.map((item, index) =>
                                                                <tr key={index + 1}>
                                                                    <td>{item.companies}</td>
                                                                    <td>{item.target}</td>
                                                                    <td>{item.jobOffers - item.refused}</td>
                                                                    <td>{item.target - (item.jobOffers - item.refused)}</td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                    :
                                                    <TableLoader colSpan="4" />
                                            }
                                        </table>
                                    </div>
                                </div>
                            </div> */}
                            <div className='dkg-target-boxcols'>
                                <div className='dkg-target-summ-box'>
                                    <div className='dkg-target-summ-boxHeader'>
                                        <h2 className='dkg-target-summ-Headertitle'>CLIENTS TARGET</h2>
                                    </div>
                                    <div className='dkg-target-summ-boxBody'>
                                        <table className="table dkg-target-summ-boxtable dkg-clientsales-cols">
                                            <thead>
                                                <tr>
                                                    <th>Target</th>
                                                    <th>Job Offer</th>
                                                    <th>Refused</th>
                                                    <th>Net Sale</th>
                                                    <th>Pending</th>
                                                </tr>
                                            </thead>
                                            {
                                                (!isLoading) ?
                                                    <tbody>
                                                        {

                                                            companyDataList.map((item, index) =>
                                                                <tr key={index + 1}>
                                                                    <td className='dkg-targets-black-color'>{item.target} <span className='ml-1'>€</span></td>
                                                                    <td>{item.jobOffers} <span className='ml-1'>€</span></td>
                                                                    <td className='dkg-targets-refused'>{item.refused} <span className='ml-1'>€</span></td>
                                                                    <td className='dkg-targets-netsale'>{(item.jobOffers) - (item.refused)} <span className='ml-1'>€</span></td>
                                                                    <td className='dkg-targets-refused'>{item.target - ((item.jobOffers) - (item.refused))} <span className='ml-1'>€</span></td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                    :
                                                    <TableLoader colSpan="5" />
                                            }
                                        </table>
                                    </div>
                                </div>
                                <div className='dkg-target-summ-box'>
                                    <div className='dkg-target-summ-boxHeader'>
                                        <h2 className='dkg-target-summ-Headertitle'>AM  SALES</h2>
                                    </div>
                                    <div className='dkg-target-summ-boxBody'>
                                        <table className="table dkg-target-summ-boxtable dkg-users-table-cols">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Timeline</th>
                                                    <th>Target</th>
                                                    <th>Achieved</th>
                                                    <th>Pending</th>
                                                </tr>
                                            </thead>
                                            {
                                                (!isAMLoading) ?
                                                    <tbody>
                                                        {
                                                            AMDataList.map((item, index) =>
                                                                <tr key={index + 1}>
                                                                    <td>
                                                                        <div className="dk-ManagerImg">
                                                                            <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + item.recInfo.profileImage} alt="" />
                                                                        </div>
                                                                    </td>
                                                                    <td className='dkg-targets-black-color'>Monthly</td>
                                                                    <td>{item.salesTarget}<span className='ml-1'>€</span></td>
                                                                    <td className='dkg-targets-netsale'>{item.netSale} <span className='ml-1'>€</span></td>
                                                                    <td className=''>
                                                                    {
                                                                    (item.salesTarget > item.netSale) ? <span style={{ color: "#ff0000"}} className='addRedColur'>{(item.salesTarget - item.netSale)} <span className='ml-1'>€</span></span> : <span style={{ color: "#3a9469"}} className='addGreenClour'>{(item.netSale - item.salesTarget)} <span className='ml-1'>€</span></span>
                                                                    }
                                                                         
                                                                  </td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                    :
                                                    <TableLoader colSpan="4" />
                                            }
                                        </table>
                                    </div>
                                </div>
                                <div className='dkg-target-summ-box'>
                                    <div className='dkg-target-summ-boxHeader'>
                                        <h2 className='dkg-target-summ-Headertitle'>AM SENDOUTS</h2>
                                    </div>
                                    <div className='dkg-target-summ-boxBody'>
                                        <table className="table dkg-target-summ-boxtable dkg-users-table-cols">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Timeline</th>
                                                    <th>Target</th>
                                                    <th>Achieved</th>
                                                    <th>Pending</th>
                                                </tr>
                                            </thead>
                                            {
                                                (!isRCLoading) ?
                                                    <tbody>
                                                        {
                                                            RCDataList.map((item, index) =>
                                                                <tr key={index + 1}>
                                                                    <td>
                                                                        <div className="dk-ManagerImg">
                                                                            <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + item.recInfo.profileImage} alt="" />
                                                                        </div>
                                                                    </td>
                                                                    <td className='dkg-targets-black-color'>Monthly</td>
                                                                    <td>{item.sendoutTarget}</td>
                                                                    <td className='dkg-targets-netsale'>{item.sendoutMade}</td>
                                                                    <td className='dkg-targets-refused'>{item.sendoutTarget - item.sendoutMade}</td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                    :
                                                    <TableLoader colSpan="4" />
                                            }
                                        </table>
                                    </div>
                                </div>
                                <div className='dkg-target-summ-box'>
                                    <div className='dkg-target-summ-boxHeader'>
                                        <h2 className='dkg-target-summ-Headertitle'>SALES TARGET</h2>
                                    </div>
                                    <div className='dkg-target-summ-boxBody'>
                                        <table className="table dkg-target-summ-boxtable dkg-users-table-cols">
                                            {/* <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Timeline</th>
                                                    <th>Rating</th>
                                                    <th>Bonus</th>
                                                </tr>
                                            </thead>
                                            {
                                                (!isPrLoading) ?
                                                    <tbody>
                                                        {
                                                            performanceDataList.map((item, index) =>
                                                                <tr key={index + 1}>
                                                                    <td>
                                                                        <div className="dk-ManagerImg">
                                                                            <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + item.recInfo.profileImage} alt="" />
                                                                        </div>
                                                                    </td>
                                                                    <td className='dkg-target-summ-text dkg-targets-black-color'>Monthly</td>
                                                                    <td>{item.rating}</td>
                                                                    <td>{item.bonus} <span className='ml-1'>€</span></td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                    :
                                                    <TableLoader colSpan="4" />
                                            } */}
                                            <thead>
                                                <tr>
                                                    <th>Target</th>
                                                    <th>Job Offer</th>
                                                    <th>Refused</th>
                                                    <th>Net Sale</th>
                                                    <th>Pending</th>
                                                </tr>
                                            </thead>
                                            {
                                                (!isPrLoading) ?
                                                    <tbody>
                                                        {

                                                                performanceDataList.map((item, index) =>
                                                                <tr key={index + 1}>
                                                                    <td className='dkg-targets-black-color'>{item.target} <span className='ml-1'>€</span></td>
                                                                    <td>{item.jobOffers} <span className='ml-1'>€</span></td>
                                                                    <td className='dkg-targets-refused'>{item.refused} <span className='ml-1'>€</span></td>
                                                                    <td className='dkg-targets-netsale'>{(item.jobOffers) - (item.refused)} <span className='ml-1'>€</span></td>
                                                                    <td className='dkg-targets-refused'>{item.target - ((item.jobOffers) - (item.refused))} <span className='ml-1'>€</span></td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                    :
                                                    <TableLoader colSpan="5" />
                                            }
                                        </table>
                                    </div>
                                </div>
                                <div className='dkg-target-summ-box'>
                                    <div className='dkg-target-summ-boxHeader'>
                                        <h2 className='dkg-target-summ-Headertitle'>RC SALES</h2>
                                    </div>
                                    <div className='dkg-target-summ-boxBody'>
                                        <table className="table dkg-target-summ-boxtable dkg-users-table-cols">
                                            {/* <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Timeline</th>
                                                    <th>Placements</th>
                                                    <th>Bonus</th>
                                                </tr>
                                            </thead>
                                            {
                                                (!isPlacemetsLoading) ?
                                                    <tbody>
                                                        {
                                                            placementsDataList.map((item, index) =>
                                                                <tr key={index + 1}>
                                                                    <td>
                                                                        <div className="dk-ManagerImg">
                                                                            <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + item.recInfo.profileImage} alt="" />
                                                                        </div>
                                                                    </td>
                                                                    <td className='dkg-target-summ-text dkg-targets-black-color'>Monthly</td>
                                                                    <td>{item.totalSendout}</td>
                                                                    <td>{item.bonus}<span className='ml-1'>€</span></td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                    :
                                                    <TableLoader colSpan="4" />
                                            } */}
                                             <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Timeline</th>
                                                    <th>Target</th>
                                                    <th>Achieved</th>
                                                    <th>Pending</th>
                                                </tr>
                                            </thead>
                                            {
                                                (!isPlacemetsLoading) ?
                                                    <tbody>
                                                        {
                                                            placementsDataList.map((item, index) =>
                                                                <tr key={index + 1}>
                                                                    <td>
                                                                        <div className="dk-ManagerImg">
                                                                            <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + item.recInfo.profileImage} alt="" />
                                                                        </div>
                                                                    </td>
                                                                    <td className='dkg-targets-black-color'>Monthly</td>
                                                                    <td>{item.salesTarget}<span className='ml-1'>€</span></td>
                                                                    <td className='dkg-targets-netsale'>{item.netSale} <span className='ml-1'>€</span></td>
                                                                    <td className=''>
                                                                    {
                                                                    (item.salesTarget > item.netSale) ? <span style={{ color: "#ff0000"}} className='addRedColur'>{(item.salesTarget - item.netSale)} <span className='ml-1'>€</span></span> : <span style={{ color: "#3a9469"}} className='addGreenClour'>{(item.netSale - item.salesTarget)} <span className='ml-1'>€</span></span>
                                                                    }
                                                                         
                                                                  </td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                    :
                                                    <TableLoader colSpan="4" />
                                            }
                                        </table>
                                    </div>
                                </div>
                                <div className='dkg-target-summ-box'>
                                    <div className='dkg-target-summ-boxHeader'>
                                        <h2 className='dkg-target-summ-Headertitle'>RC SENDOUTS</h2>
                                    </div>
                                    <div className='dkg-target-summ-boxBody'>
                                        <table className="table dkg-target-summ-boxtable dkg-users-table-cols">
                                            {/* <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Timeline</th>
                                                    <th>Sendouts</th>
                                                    <th>Bonus</th>
                                                </tr>
                                            </thead>
                                            {
                                                (!isSendoutLoading) ?
                                                    <tbody>
                                                        {
                                                            sendoutDataList.map((item, index) =>
                                                                <tr key={index + 1}>
                                                                    <td>
                                                                        <div className="dk-ManagerImg">
                                                                            <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + item.recInfo.profileImage} alt="" />
                                                                        </div>
                                                                    </td>
                                                                    <td className='dkg-target-summ-text dkg-targets-black-color'>{item.timeline}</td>
                                                                    <td>{item.totalSendout}</td>
                                                                    <td>{item.bonus} <span className='ml-1'>€</span></td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                    :
                                                    <TableLoader colSpan="4" />
                                            } */}
                                             <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Timeline</th>
                                                    <th>Target</th>
                                                    <th>Achieved</th>
                                                    <th>Pending</th>
                                                </tr>
                                            </thead>
                                            {
                                                (!isSendoutLoading) ?
                                                    <tbody>
                                                        {
                                                            sendoutDataList.map((item, index) =>
                                                                <tr key={index + 1}>
                                                                    <td>
                                                                        <div className="dk-ManagerImg">
                                                                            <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + item.recInfo.profileImage} alt="" />
                                                                        </div>
                                                                    </td>
                                                                    <td className='dkg-targets-black-color'>Monthly</td>
                                                                    <td>{item.sendoutTarget}</td>
                                                                    <td className='dkg-targets-netsale'>{item.sendoutMade}</td>
                                                                    <td className='dkg-targets-refused'>{item.sendoutTarget - item.sendoutMade}</td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                    :
                                                    <TableLoader colSpan="4" />
                                            }
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Index