import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import './visitsites.scss';
// import DkGlobalLogoRecImg from "../../../assets/images/dk-global-rec-logo.png";
// import DkGlobalAdmin from "../../../assets/images/dkglobaladmin.png";
// import DkGlobalFinance from "../../../assets/images/dkfinance.png";
import Recruitment from '../../../assets/images/recruitment.png';
import Marketing from '../../../assets/images/ad-campaign.png';
import Finance from '../../../assets/images/finance.png';
import ItTeamImg from '../../../assets/images/software-dev.png';
import Training from '../../../assets/images/training.png';
import DkgIcon from '../../../assets/images/dk_icon_white.png';
import BusinessDevelopment from '../../../assets/images/company-white.png';
//import Management from '../../../assets/images/management-team.png';
import HrImg from '../../../assets/images/hr_white.png';
import FaqImg from '../../../assets/images/information_white.png';
import LoginsImg from '../../../assets/images/login.png';
import ResourceImg from '../../../assets/images/recruitment.png';
import Header from '../../elements/header';
import { useDispatch, useSelector } from "react-redux";
import { setMyModule } from "../../../slice/modules/moduleSlice";
import { showSuccess, showError } from "../../../components/utils/messages/messageSlice";


const Visitsite = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { userInfo } = useSelector(state => state.auth);

    const setModule = async (myModule, moduleColor) => {

        await dispatch(setMyModule({ module: myModule, color: moduleColor }))

        if (userInfo.role === "User" || userInfo.role === "Admin") {

            if (myModule === "Logins" || myModule === "dkg-global" || myModule === "main-faq") {
                switch (myModule) {
                    case 'Logins':
                        history.push("/logins/summary");
                        break;
                    case 'dkg-global':
                        history.push("/departs/dkg-global/dashboard");
                        break;

                    case 'main-faq':
                        history.push("/maindepartment/main-faq/dashboard");
                    default:
                        break;
                }
            } else {
                dispatch(showError({ msg: 'You are not authorized to view' }))

            }

        } else {


            switch (myModule) {

                case 'it-teams':
                    history.push("/dkglobaladmin/it-teams/module");
                    break;

                case 'trainingmodule':
                    history.push("/dkglobaladmin/trainingmodule/dashboard");
                    break;

                case 'dkglobal':
                    history.push("/dkglobaladmin/dkglobal/information");
                    break;

                case 'clients':
                    history.push("/dashboard/clients");
                    break;

                case 'hr':
                    history.push("/dkglobaladmin/hr/dashboard");
                    break;

                case 'manage-ment':
                    history.push("/dashboard/manage-ment");
                    break;

                // case 'auth-account':
                //     history.push("/dashboard/manage-ment");
                //     break;

                case 'supplier':
                    history.push("/dkglobaladmin/supplier/dashboard");
                    break;

                case 'Logins':
                    history.push("/logins/summary");
                    break;

                case 'legalmodule':
                    history.push("/legalmodule/legal-module");
                    break;

                case 'main-faq':
                    history.push("/maindepartment/main-faq/dashboard");
                    break;

                case 'dkg-global':
                    history.push("/departs/dkg-global/dashboard");
                    break;

                case 'dkglobaladmin':
                    history.push("/dkglobaladmin/hr/dashboard");
                    break;


                default:
                    break;
            }
        }
    }
    return (
        <>
            <Header mainheaderTitle="" textcolor="#564848" />
            <div className="dk-visitWebsiteMain">
                <div className="w-100" style={{ paddingTop: '130px' }}>
                    {/* <h3 className="title" style={{ marginRight: '280px' }}>DK GLOBAL RECRUITMENT DEPARTMENTS</h3> */}
                    <div className="dk-visitWebsite-cols">
                        <div className="dk-visitWebsite-box dkg-busines-obox dkgcrm-acc-finanancebox" >
                            <Link to="#" onClick={() => setModule('manage-ment')} style={{ background: '#884c5e' }}>
                                <div className="dk-visitLogo">
                                    <span>
                                        <img src={DkgIcon} alt="" />
                                        {/* <img src={Management} alt="" /> */}
                                    </span>
                                    BUSINESS OVERVIEW
                                </div>
                            </Link>
                        </div>
                        <div className="dk-visitWebsite-box dkgcrm-dashboardbox">
                            <Link to="#" onClick={() => setModule('clients')}>
                                {/* <Link to="/dkglobaladmin/clients/dashboard" > */}
                                <div className="dk-visitLogo">
                                    <span>
                                        <img src={BusinessDevelopment} alt="" />
                                    </span>
                                    CLIENTS
                                </div>
                            </Link>
                        </div>
                        <div className="dk-visitWebsite-box dkgcrm-recquirementdbox pr-5">
                            <Link to="/dashboard/recruitment" style={{ background: '#26596d' }}>
                                <div className="dk-visitLogo">
                                    <span>
                                        <img src={Recruitment} alt="" />
                                    </span>
                                    RECRUITMENT
                                </div>
                            </Link>
                        </div>
                        <div className="dk-visitWebsite-box dkgcrm-acc-finanancebox pl-2">
                            <Link to="#" onClick={() => setModule('dkg-global')} style={{ background: '#3c97b6' }}>
                                {/* <Link to="/dkg-global/dashboard" style={{ background: '#3c4c62' }}> */}
                                <div className="dk-visitLogo">
                                    <span>
                                        <img src={DkgIcon} alt="" />
                                    </span>
                                    DK Global
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="dk-visitWebsite-cols">
                        <div className="dk-visitWebsite-box dkgcrm-marketingdbox">
                            <Link to="/dashboard/resource">
                                <div className="dk-visitLogo">
                                    <span>
                                        <img src={Marketing} alt="" />
                                    </span>
                                    ADVERTISEMENTS
                                </div>
                            </Link>
                        </div>
                        {/* <div className="dk-visitWebsite-box dkgcrm-marketingdbox">
                            <Link to="#" style={{ background: '#144773' }}>
                                <div className="dk-visitLogo">
                                    <span>
                                        <img src={Marketing} alt="" />
                                    </span>
                                    ADVERTISEMENT
                                </div>
                            </Link>
                        </div> */}
                        <div className="dk-visitWebsite-box dkgcrm-supplierbox">
                            {/* <Link to="#" onClick={() => setModule('supplier')}> */}
                            <Link to="/dashboard/suppliers">
                                <div className="dk-visitLogo">
                                    <span>
                                        <img src={ResourceImg} alt="" />
                                    </span>
                                    SUPPLIERS
                                </div>
                            </Link>
                        </div>
                        {
                            userInfo.role === "Super Admin" || userInfo.role === "Admin" || userInfo.role === "User" ?                            
                                <div className="dk-visitWebsite-box dkgcrm-acc-finanancebox pr-5">
                                    <Link to="/dashboard/accounting">
                                        <div className="dk-visitLogo">
                                            <span>
                                                <img src={Finance} alt="" />
                                            </span>
                                            ACCOUNTING
                                        </div>
                                    </Link>
                                </div>
                                :
                                <>
                                    <div className="dk-visitWebsite-box dkgcrm-acc-finanancebox pr-5">
                                        <Link to="#" onClick={() => setModule('auth-account')} style={{ background: '#884c5e' }}>
                                            <div className="dk-visitLogo">
                                                <span>
                                                    <img src={Finance} alt="" />
                                                </span>
                                                ACCOUNTING
                                            </div>
                                        </Link>
                                    </div>
                                </>
                        }

                        {/* <div className="dk-visitWebsite-box dkgcrm-lastbox pr-5">
                            <Link to="#" onClick={() => setModule('hr')}>
                                <div className="dk-visitLogo">
                                    <span>
                                        <img src={Operation} alt="" />
                                    </span>
                                    HR
                                </div>
                            </Link>
                        </div> */}
                        <div className="dk-visitWebsite-box dkgcrm-acc-finanancebox pl-2">
                            <Link to="#" onClick={() => setModule('Logins')} style={{ background: '#5c737d' }}>
                                {/* <Link to="/logins/summary" style={{ background: '#5c737d' }}> */}
                                <div className="dk-visitLogo">
                                    <span>
                                        <img src={LoginsImg} alt="" />
                                    </span>
                                    Logins
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="dk-visitWebsite-cols">

                        <div className="dk-visitWebsite-box dkgcrm-marketingdbox">
                            {/* <Link to="#" onClick={() => setModule('dkglobaladmin')} style={{ background: '#d58b5d' }}> */}
                            <Link to="/dashboard/hr" style={{ background: '#d58b5d' }}>
                                <div className="dk-visitLogo">
                                    <span>
                                        <img src={HrImg} alt="" />
                                    </span>
                                    HR
                                </div>
                            </Link>
                        </div>
                        <div className="dk-visitWebsite-box trainingtabs">
                            <Link to="/dashboard/training" >
                                {/* <Link to="#" onClick={() => setModule('trainingmodule')}> */}
                                <div className="dk-visitLogo">
                                    <span>
                                        <img src={Training} alt="" />
                                    </span>
                                    Training
                                </div>
                            </Link>
                        </div>
                        {/* <div className="dk-visitWebsite-box dkgcrm-myaccountsbox">
                            <Link to="#" onClick={() => setModule('legalmodule')} style={{ background: '#535255' }}>
                                <div className="dk-visitLogo">
                                    <span>
                                        <img src={LegalImg} alt="" />
                                    </span>
                                    Legal
                                </div>
                            </Link>
                        </div> */}
                        <div className="dk-visitWebsite-box itteamtabs pr-5">
                            {/* <Link to="#" onClick={() => setModule('it-teams')}> */}
                            <Link to="/dashboard/it-teams" >
                                <div className="dk-visitLogo">
                                    <span>
                                        <img src={ItTeamImg} alt="" />
                                    </span>
                                    IT Team
                                </div>
                            </Link>
                        </div>
                        <div className="dk-visitWebsite-box dkgcrm-acc-finanancebox pl-2">
                            {/* <Link to="/dashboard/main-faq" style={{ background: '#3c4c62' }}> */}
                            <Link to="#" onClick={() => setModule('main-faq')} style={{ background: '#3c4c62' }}>
                                <div className="dk-visitLogo">
                                    <span>
                                        <img src={FaqImg} alt="" />
                                    </span>
                                    FAQ
                                </div>
                            </Link>
                        </div>
                        {/* <div className="dk-visitWebsite-box dkglobaltabs">
                            <Link to="#" onClick={() => setModule('dkglobal')}>
                                <div className="dk-visitLogo">
                                    <span>
                                        <img src={DkGlobal} alt="" />
                                    </span>
                                    DK Global
                                </div>
                            </Link>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Visitsite;
